import { FC, memo } from 'react'
import type { Props } from '../../types'

const ArrowRight: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.24108 10.1677L0.5 9.48029L4.17337 5.16772L0.5 0.855162L1.24108 0.167725L5.5 5.16772L1.24108 10.1677Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowRight)
