import styled from 'styled-components'

export const Link = styled.span`
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colours.blue[50]};
    :hover {
      text-decoration: underline;
    }
  }
`

export const A = styled.a``
