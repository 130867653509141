import { TOPICS_SET, TOPICS_CLEAR } from '../actions/topicsActions'

const initialState = {
  filter: undefined,
  row: { id: 1, data: {}, loading: true },
}

export default function topics(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case TOPICS_SET:
      return { ...state, filter: payload.filter, row: payload.row }

    case TOPICS_CLEAR:
      return { ...initialState }

    default:
      return state
  }
}
