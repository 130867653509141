import { FC, memo } from 'react'
import type { Props } from '../../types'

const Facebook: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.35385 0.837891C0.744114 0.837891 0.25 1.332 0.25 1.94174V19.734C0.25 20.3436 0.744114 20.8379 1.35385 20.8379H10.9326V13.0928H8.32627V10.0744H10.9326V7.84847C10.9326 5.2652 12.5104 3.85862 14.8148 3.85862C15.9186 3.85862 16.8673 3.94078 17.1438 3.97752V6.67714L15.5456 6.67787C14.2923 6.67787 14.0497 7.27341 14.0497 8.14732V10.0744H17.0386L16.6494 13.0928H14.0497V20.8379H19.1462C19.7557 20.8379 20.25 20.3436 20.25 19.734V1.94174C20.25 1.332 19.7557 0.837891 19.1462 0.837891H1.35385Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Facebook)
