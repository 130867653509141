import styled from 'styled-components'
import Button from 'components/Buttons/Button/Button'

export const TooltipChildren = styled.span``

export const SpecialButton = styled(Button)<{ actived: boolean }>`
  && {
    line-height: 0;
    height: 20px;
    min-width: 24px;
    padding: 0;
    position: relative;
    opacity: ${({ actived }) => (actived ? 1 : 0.5)};
  }
`
