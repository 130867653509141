import { set, flow, keyBy, map, get, flatMap } from 'lodash/fp'

import {
  SESSION_DATA_RES,
  SESSION_DATA_NEEDS_LOGIN,
  SET_USER_TIMEZONE,
  SESSION_DATA_LOGOUT,
  SESSION_DATA_LOGIN,
  UPDATE_USER_ONBOARDING,
} from '../actions/sessionDataActions'

/**
 * Normalize the brandsAndProducts response in the reducer state
 */
function setBrandsAndProducts(payload) {
  const setBrandProductIds = brand =>
    set('products', map(get('id'), brand.products), brand)

  const setProductFeeds = product =>
    set('feeds', map(get('id'), product.feeds), product)

  // 1- Flatten the array of brands into an array of products, assigning the brandId to each
  // 2- Replace the array of feeds with an array of feed ids
  // 3- Transform the array into an object that keyd by the product id
  const getProductsFromBrands = flow(
    flatMap(brand => map(set('brandId', brand.id), brand.products)),
    map(setProductFeeds),
    keyBy(get('id')),
  )

  // 1- Flatten the array of brands into an array of products
  // 2- Flatten the array of products into an array of feeds, assigning the productId to each
  // 3- Transform the array into an object that keyd by the feed id
  const getFeedsFromBrands = flow(
    flatMap(get('products')),
    flatMap(product => map(set('productId', product.id), product.feeds)),
    keyBy(get('id')),
  )

  return flow(
    set('brandsAndProducts', payload),
    set('brands', map(setBrandProductIds, payload)),
    set('products', getProductsFromBrands(payload)),
    set('feeds', getFeedsFromBrands(payload)),
  )
}

/**
 * Reducer
 */

const initialState = {
  user: {
    client: {
      brands: [],
    },
  },
  client: {},
  perms: [],
  brandsAndProducts: [],
  brands: [],
  products: {},
  feeds: {},

  meta: {
    isFetching: true,
    error: null,
  },
}

export default function sessionData(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SESSION_DATA_RES: {
      if (action.error) {
        return flow(
          set('meta.isFetching', false),
          set('meta.error', action.error),
        )(state)
      }

      return flow(
        setBrandsAndProducts(payload.brands),
        set('client', payload.client),
        set('user', payload.user),
        set('meta.isFetching', false),
        set('meta.isLoggedIn', true),
      )(state)
    }

    case SESSION_DATA_NEEDS_LOGIN: {
      return flow(
        set('meta.isLoggedIn', false),
        set('meta.isFetching', false),
      )(state)
    }

    case SET_USER_TIMEZONE:
      return set('user.timezone', payload.timezone, state)

    case SESSION_DATA_LOGOUT: {
      // Note that this is returning the intialState instead of updateing the
      // current state
      return set('meta.isLoggedIn', false, initialState)
    }

    case SESSION_DATA_LOGIN: {
      // Note that this is returning the intialState instead of updateing the
      // current state
      return set('meta.isLoggedIn', true, initialState)
    }

    case UPDATE_USER_ONBOARDING:
      return set(
        'user.onboarding',
        {
          ...state.user.onboarding,
          ...payload,
        },
        state,
      )

    default:
      return state
  }
}
