import { FC, memo } from 'react'
import type { Props } from '../types'

const InfoInactive: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y="0.5" width="18" height="18" rx="9" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7.875H10V13.875H8V7.875Z"
      fill="white"
    />
    <rect x="8" y="4.5" width="2" height="2" rx="1" fill="white" />
  </svg>
)

export default memo(InfoInactive)
