import { FC, memo } from 'react'
import type { Props } from '../../../types'

const EyeClosed: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.3416 5.5C16.426 6.81605 17 8 17 8C17 8 14.0909 14 9 14C8.29027 14 7.62295 13.8834 7 13.6827M12.1254 2.78938C11.2021 2.31013 10.1574 2 9 2C3.90909 2 1 8 1 8C1 8 1.8732 9.80098 3.5 11.4295"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.58208 5.82641C8.41816 5.51454 7.21389 6.23475 6.89227 7.43505C6.78282 7.84353 6.78969 8.25496 6.89227 8.63232"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="16"
      y1="1.70711"
      x2="2.70711"
      y2="15"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default memo(EyeClosed)
