import { FC, memo } from 'react'
import type { Props } from '../types'

const Evolution: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 11 11"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M5.26 0l5.262 11L5.26 8.624 0 11 5.26 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default memo(Evolution)
