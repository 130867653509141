/* Thanks Intercom! */
/* eslint-disable new-cap */
import mixpanel from 'mixpanel-browser'
import Raven from 'raven-js'

/**
 * Get the user email
 *
 * @private
 * @return {string} email
 */
function getEmail() {
  return window.localStorage && window.localStorage.getItem('userEmail')
}

/**
 * Returns true when the email reprsents an awesome users (when a user
 * is part of the Séntisis team)
 *
 * @private
 * @param {string} email
 * @return {boolean}
 */
function isAwesome(email) {
  // Undefined as a string comes since we save it to localStorage
  if (!email) return true
  return /@sentisis/.test(email)
}

/**
 * Set the user data for Intercom and Mixpanel
 *
 * @param {Object} user - Userdata initial response
 * @param {Object} client - Client of the user
 */
export function setUserData(user, client) {
  const email = getEmail()

  // Set raven user
  Raven.setUserContext({ email: user.email, id: user.id })

  // Identify the users via his id for all the events
  mixpanel.identify(user._id)

  // Update user informations
  mixpanel.people.set({
    $name: user.name,
    $email: user.email,
    $last_login: new Date(),
  })

  mixpanel.people.set_once('First Login Date', new Date())

  // Differentiate internal use
  if (isAwesome(email)) {
    mixpanel.people.set({
      'User Type': 'Internal',
    })
  } else {
    mixpanel.people.set({
      'User Type': 'Customer',
    })
  }

  // Do not track sentitis users on Intercom
  if (isAwesome(email)) return false

  window.Intercom('boot', {
    app_id: 'fc3sonpl',
    email: user.email,
    name: user.name,
    user_id: user.id,
    new_session: true,
    company: {
      id: client.id,
      name: client.name,
    },
    user_hash: user.intercomHash,
  })

  return true
}

/**
 * Send events to all the analytics paltform. Delay the sending when idlin
 * for better performances.
 *
 * @param {string} name    - Name of the event
 * @param {Object} payload - Data to send with the event
 */

const eventsToSend = []
let isRequestIdleCallbackScheduled = false

function processPendingEvents(deadline) {
  // Reset the flag so future events can be sent
  isRequestIdleCallbackScheduled = false

  // Go for as long as there is time remaning and work to do
  while (deadline.timeRemaining() > 0 && eventsToSend.length > 0) {
    // eslint-disable-next-line
    let { name, payload } = eventsToSend.pop()
    const email = getEmail()

    if (!email) return

    // Send all the events to mixpanel, including internal use
    mixpanel.track(name, payload)

    // Google Tag Manager
    // TODO: Check if we can get the funnels properly with this data
    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Navigation',
      eventAction: name,
      eventLabel: payload.section,
      eventValue: payload.section,
      brand: payload.brand,
      product: payload.product,
      section: payload.section,
      subsection: payload.subsection,
      data: payload,
      email,
    })

    if (!isAwesome(email)) {
      // Concatenate the section name since Intercom doesn't show the payload
      // when creating a funnel or automatic alert
      if (name === 'User enters a section') {
        name = `${name}: ${payload.section}`
      }

      window.Intercom('trackEvent', name, payload)
    }
  }

  // Check if there are more events to send
  // eslint-disable-next-line no-use-before-define
  if (eventsToSend.length > 0) schedulePendingEvents()
}

function schedulePendingEvents() {
  if (isRequestIdleCallbackScheduled) return

  isRequestIdleCallbackScheduled = true

  window.requestIdleCallback =
    window.requestIdleCallback ||
    function setTime(handler) {
      const startTime = Date.now()
      return setTimeout(() => {
        handler({
          didTimeout: false,
          timeRemaining() {
            return Math.max(0, 50.0 - (Date.now() - startTime))
          },
        })
      }, 1)
    }

  // Wait at most 2 seconds before sending the event
  window.requestIdleCallback(processPendingEvents, { timeout: 2000 })
}

export function trackEvent(name, payload = {}) {
  eventsToSend.push({ name, payload })

  schedulePendingEvents()
}

/**
 * Removes privacy from the companies
 *
 * @param {Object} client - Company response
 */
export function setCompanyData(client) {
  const email = getEmail()

  mixpanel.people.set({
    'Company ID': client.id,
    'Company Name': client.name,
    'Company Country': client.country,
  })

  if (isAwesome(email)) return false

  const settings = Object.assign(window.intercomSettings || {}, {
    new_session: true,
    company: {
      id: client.id,
      name: client.name,
    },
  })

  window.intercomSettings = settings
  window.Intercom('update', window.intercomSettings)
  return true
}

/**
 * Set the purchashed products for the user. Used on Intercom
 *
 * @param {Object} res - Product data response
 */
export function setProductData(res) {
  const email = getEmail()

  if (isAwesome(email)) return false

  const settings = window.intercomSettings || {}
  res.forEach(brand =>
    brand.products.forEach(product => {
      if (product.status === 'PURCHASED') {
        settings[product.type] = true
        settings[`${product.type}_start_date_at`] = product.start_date
      }
    }),
  )

  window.intercomSettings = settings
  window.Intercom('update')
  return true
}
