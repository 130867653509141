import { combineReducers } from 'redux'

import accountMentions from './accountMentions'
import accounts from './accounts'
import appConfiguration from './appConfiguration'
import communityLocation from './communityLocation'
import data from './data'
import entities from './entities'
import filters from './filters'
import globalFilters from './globalFilters'
import magicSearch from './magicSearch.reducer'
import mentions from './mentions'
import mentionsFilters from './mentionsFilters'
import onboarding from './onboarding'
import sessionData from './sessionData'
import toast from './toast'
import topics from './topics'
import update from './update'

export default combineReducers({
  accountMentions,
  accounts,
  appConfiguration,
  communityLocation,
  data,
  entities,
  filters,
  globalFilters,
  magicSearch,
  mentions,
  mentionsFilters,
  onboarding,
  sessionData,
  toast,
  topics,
  update,
})
