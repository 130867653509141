import React from 'react'
import './Loading.css'

type Props = {
  solid: boolean
  width: string
  height: string
}

export default class Loading extends React.PureComponent<Props, {}> {
  static defaultProps: Props = {
    width: '100%',
    solid: false,
    height: 'auto',
  }
  renderSpinner() {
    return (
      <div className="Spinner">
        <div className="Spinner-line Spinner-line--1">
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--left" />
          </div>
          <div className="Spinner-line-ticker">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--center" />
          </div>
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--right" />
          </div>
        </div>
        <div className="Spinner-line Spinner-line--2">
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--left" />
          </div>
          <div className="Spinner-line-ticker">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--center" />
          </div>
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--right" />
          </div>
        </div>
        <div className="Spinner-line Spinner-line--3">
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--left" />
          </div>
          <div className="Spinner-line-ticker">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--center" />
          </div>
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--right" />
          </div>
        </div>
        <div className="Spinner-line Spinner-line--4">
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--left" />
          </div>
          <div className="Spinner-line-ticker">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--center" />
          </div>
          <div className="Spinner-line-cog">
            <div className="Spinner-line-cog-inner Spinner-line-cog-inner--right" />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { solid, width, height } = this.props

    if (!solid) return this.renderSpinner()

    return (
      <div className="Spinner__container" style={{ height, width }}>
        {this.renderSpinner()}
      </div>
    )
  }
}
