import { FC, memo } from 'react'
import type { Props } from '../../types'

const LearningCenter: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50391 2.08509C1.81041 2.08509 1.24822 2.64888 1.24822 3.34435V12.3599C1.61497 12.1384 2.0446 12.011 2.50391 12.011H11.8846V2.08509H10.1037C10.1091 2.13373 10.1119 2.18316 10.1119 2.23324V8.15916C10.1119 8.38114 9.98816 8.5845 9.79134 8.68608C9.59453 8.78765 9.35761 8.7704 9.17749 8.64137L6.93572 7.03555L4.69396 8.64137C4.51384 8.7704 4.27692 8.78765 4.08011 8.68608C3.88329 8.5845 3.75959 8.38114 3.75959 8.15916V2.23324C3.75959 2.18316 3.76234 2.13373 3.7677 2.08509H2.50391ZM2.50391 0.899902C1.15771 0.899902 0.0664062 1.99432 0.0664062 3.34435V14.4555C0.0664062 15.8055 1.15771 16.8999 2.50391 16.8999H12.4755C12.8018 16.8999 13.0664 16.6346 13.0664 16.3073V1.49249C13.0664 1.16522 12.8018 0.899902 12.4755 0.899902H2.50391ZM11.8846 13.1962H2.50391C1.81041 13.1962 1.24822 13.76 1.24822 14.4555C1.24822 15.1509 1.81041 15.7147 2.50391 15.7147H11.8846V13.1962ZM5.08913 2.08509C5.00755 2.08509 4.94141 2.15142 4.94141 2.23324V7.00764L6.59227 5.8251C6.79772 5.67792 7.07373 5.67792 7.27918 5.8251L8.93004 7.00764V2.23324C8.93004 2.15142 8.8639 2.08509 8.78232 2.08509H5.08913Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(LearningCenter)
