import { FC, memo } from 'react'
import type { Props } from '../../types'

const Categories: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3.8C13.718 3.8 14.3 3.21797 14.3 2.5C14.3 1.78203 13.718 1.2 13 1.2C12.282 1.2 11.7 1.78203 11.7 2.5C11.7 3.21797 12.282 3.8 13 3.8ZM13 5C14.3807 5 15.5 3.88071 15.5 2.5C15.5 1.11929 14.3807 0 13 0C11.6193 0 10.5 1.11929 10.5 2.5C10.5 3.88071 11.6193 5 13 5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 12.8C7.59868 12.8 9.3 11.0987 9.3 9C9.3 6.90132 7.59868 5.2 5.5 5.2C3.40132 5.2 1.7 6.90132 1.7 9C1.7 11.0987 3.40132 12.8 5.5 12.8ZM5.5 14C8.26142 14 10.5 11.7614 10.5 9C10.5 6.23858 8.26142 4 5.5 4C2.73858 4 0.5 6.23858 0.5 9C0.5 11.7614 2.73858 14 5.5 14Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Categories)
