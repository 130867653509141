import { magicSearchActions } from '../actions/magicSearchActions'
import { createReducer } from '@reduxjs/toolkit'
import {
  cond,
  filter,
  flow,
  get,
  includes,
  isEmpty,
  isBoolean,
  isEqual,
  isNil,
  isNumber,
  isString,
  mapValues,
  omit,
  remove,
  set,
  T,
  toNumber,
  hasIn,
  isObject,
  isArray,
  omitBy,
} from 'lodash/fp'
import { MentionsFilters } from '@views/Config/View/View.types'
import { WritableDraft } from 'immer/dist/types/types-external'
import { discardNonPrimitiveEmptyValues } from '@utils/JSUtils'

export interface IRemoveMsItem {
  key: [string, string]
  value: any
}
export interface IMagicSearchState {
  // filters from MagicSearch
  filters: MentionsFilters
  // Combination of filters in view and MagicSearch filters
  activeFilters: MentionsFilters
  // View Filters
  viewFilters: MentionsFilters
  //Whether user reloaded the page without save changes
  changesNotSaved?: boolean
}

const initialState: IMagicSearchState = {
  filters: {},
  activeFilters: {},
  viewFilters: {},
}
const clearStateKey =
  (key: string) => (state: WritableDraft<IMagicSearchState>) =>
    set(key, {}, state) as unknown as IMagicSearchState

/** Remove Items utility */
const removeFn = (source, target) => {
  return remove(isEqual(target), source)
}

/**
 * Loop over object values and discard entries that match with ( null, undefined, [], { })
 * return a new copy of the clean structure
 */
export const cleanEmptyStructures = flow(
  mapValues(flow(discardNonPrimitiveEmptyValues)),
  discardNonPrimitiveEmptyValues, // { null / undefined / [] / { } }
)

const cleanupOperator = val => {
  if (!isObject(val)) return val

  const cleaned = omitBy(v => {
    // Keep whole entities (like numbers and strings)
    if (!isObject(v) && !isArray(v)) return false
    const result = omitBy(v => {
      return isNil(v) || isEmpty(v)
    }, v)

    return result
  }, val)

  return isEmpty(cleaned) ? undefined : cleaned
}

const removeTag = (payload: IRemoveMsItem, where) => {
  const { key: cleanKey, value } = payload
  const key = filter(x => !isNil(x), cleanKey)
  const [property, operator] = key
  const canRemove =
    ((key.length === 2 || key.length === 3) &&
      !includes(operator, ['match'])) ||
    key.length === 1
  const target = isString(value)
    ? value
    : isFinite(value)
    ? toNumber(value)
    : value

  if (canRemove) {
    let source: unknown
    // Remove the complex filters
    if (!isEmpty(operator) && operator.split('.').length > 1) {
      const hasProperty = hasIn(property, where)
      source = hasProperty
        ? get([property, ...operator.split('.')], where)
        : get([...operator.split('.')], where)

      return flow(
        cond([
          [() => isNumber(target), set(key, undefined)],
          [T, set([...operator.split('.')], removeFn(source, target))],
        ]),
        cleanEmptyStructures,
        mapValues(cleanupOperator),
        omitBy(isNil),
      )(where)
      // we are in a key:value where value is a boolean case
    } else if (isNil(operator) && isBoolean(value)) {
      // {
      //   text: { or: ['1231'] },
      //   isDark: true
      // }
      // after
      // {
      //   text: { or: ['1231'] },
      // }
      return omit(key, where)
    } else {
      // Where there are two keys, it means we're setting an operator
      // (category.or) so we treat it as an array.
      // The `match` filter  is ignored because it's not an array.
      source = get([...key], where)
      // if the target is not an array and it is a number then remove the key from the object otherwise removeFn

      return flow(
        cond([
          [() => isNumber(target), set(key, undefined)],
          [T, set(key, removeFn(source, target))],
        ]),
        cleanEmptyStructures,
      )(where)
    }
  }
  return where
}

const {
  storeMagicSearch,
  clearMagicSearch,
  clearActiveFilters,
  removeItem,
  restore,
  restoreNullView,
  clearAll,
  changesNotSaved,
} = magicSearchActions

const magicSearchReducer = createReducer(initialState, builder => {
  builder
    .addCase(
      storeMagicSearch,
      (state, action) =>
        flow(
          set('filters', action.payload),
          set('activeFilters', action.payload),
        )(state) as IMagicSearchState,
    )
    .addCase(removeItem, (state, { payload }) => {
      const result: IMagicSearchState = {
        ...state,
        filters: removeTag(payload, state.filters),
        activeFilters: removeTag(payload, state.activeFilters),
      }

      return result
    })
    .addCase(
      clearMagicSearch,
      state =>
        ({ ...state, filters: {}, activeFilters: {} } as IMagicSearchState),
    )
    .addCase(clearActiveFilters, clearStateKey('activeFilters'))
    .addCase(restore, (state, { payload }) => {
      const result: IMagicSearchState = {
        filters: {},
        activeFilters: payload,
        viewFilters: payload,
        changesNotSaved: !!state?.changesNotSaved,
      }
      return result
    })
    .addCase(restoreNullView, (state, { payload }) => {
      const result: IMagicSearchState = {
        filters: payload,
        activeFilters: payload,
        viewFilters: null,
        changesNotSaved: !!state?.changesNotSaved,
      }
      return result
    })
    .addCase(clearAll, () => initialState)
    .addCase(changesNotSaved, (state, { payload }) => ({
      ...state,
      changesNotSaved: payload,
    }))
    .addDefaultCase(state => state)
})

export default magicSearchReducer
