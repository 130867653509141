import Promise from 'bluebird'

import requestSocket from '../../requestSocket'

export const INIT_ENTITY = 'INIT_ENTITY'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const REQ_ENTITY = 'REQ_ENTITY'
export const RES_ENTITY = 'RES_ENTITY'

export function initEntity({ eventName, id }) {
  return {
    type: INIT_ENTITY,
    payload: { eventName, id },
  }
}

export function removeEntity({ eventName, id }) {
  return {
    type: REMOVE_ENTITY,
    payload: { eventName, id },
  }
}

function entityRes({ eventName, id, res, error }) {
  return {
    type: RES_ENTITY,
    error,
    payload: res,
    meta: { eventName, id },
  }
}

function entityReq({ eventName, id }) {
  return {
    type: REQ_ENTITY,
    payload: { eventName, id },
  }
}

export function fetchEntity({ eventName, id, query, prevQuery }) {
  return dispatch => {
    dispatch(entityReq({ eventName, id }))

    let req
    if (!prevQuery) {
      req = requestSocket(eventName, query)
    } else {
      req = Promise.props({
        current: requestSocket(eventName, query),
        previous: requestSocket(eventName, prevQuery),
      })
    }

    return req
      .then(res => dispatch(entityRes({ eventName, id, res })))
      .catch(error => dispatch(entityRes({ eventName, id, error })))
  }
}
