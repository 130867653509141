import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { closeToast } from '../../store/actions/toastActions'
import Toast from './Toast/Toast'

const propTypes = {
  handleClickClose: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    isVisible: PropTypes.bool,
    options: PropTypes.object.isRequired,
  }).isRequired,
}

function ToastContainer(props) {
  const { toast, handleClickClose } = props

  if (!toast.isVisible) {
    // stateless components can't return null, so instead we return an invisible span
    return <span style={{ display: 'none' }} />
  }

  return <Toast onClickClose={handleClickClose} {...toast.options} />
}

ToastContainer.propTypes = propTypes

/**
 * Connect to state
 */

const mapStateToProps = state => ({
  toast: state.toast,
})

const mapDispatchToProps = dispatch => ({
  handleClickClose() {
    return dispatch(closeToast())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer)
