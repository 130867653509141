import { FC, memo } from 'react'
import type { Props } from '../types'

const Clipboard: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M13.7497 0.833313H3.74967C2.83301 0.833313 2.08301 1.58331 2.08301 2.49998V14.1666H3.74967V2.49998H13.7497V0.833313ZM16.2497 4.16665H7.08301C6.16634 4.16665 5.41634 4.91665 5.41634 5.83331V17.5C5.41634 18.4166 6.16634 19.1666 7.08301 19.1666H16.2497C17.1663 19.1666 17.9163 18.4166 17.9163 17.5V5.83331C17.9163 4.91665 17.1663 4.16665 16.2497 4.16665ZM16.2497 17.5H7.08301V5.83331H16.2497V17.5Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Clipboard)
