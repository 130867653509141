import { get, getOr, flatMap, flow, every, isEmpty } from 'lodash/fp'
import { loader } from 'graphql.macro'

import { setMentionsPage } from './mentionsActions'
import { sortStringsValues } from '@utils/shared'
import {
  GET_REVIEW_PRODUCT_LOCATIONS,
  GET_REVIEW_PRODUCTS,
} from 'apollo/queries/product'

const PRODUCT_CATEGORIES_QUERY = loader(
  '../../queries/ProductCategoriesQuery.graphql',
)
const VIEW_CATEGORIES_QUERY = loader(
  '../../queries/ViewCategoriesQuery.graphql',
)
const PRODUCT_REVIEWS_QUERY = loader(
  '../../queries/ProductReviewPlatformsQuery.graphql',
)

export const SET_MENTIONS_FILTERS = 'SET_MENTIONS_FILTERS'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const CLEAR_MENTIONS_FILTERS = 'CLEAR_MENTIONS_FILTERS'

export const REQ_META_MENTIONS_FILTERS = 'REQ_META_MENTIONS_FILTERS'
export const RES_META_MENTIONS_FILTERS = 'RES_META_MENTIONS_FILTERS'

export const REQ_META_LOCATIONS_FILTERS = 'REQ_META_LOCATIONS_FILTERS'
export const RES_META_LOCATIONS_FILTERS = 'RES_META_LOCATIONS_FILTERS'

export const REQ_META_REVIEW_PRODUCTS_FILTERS =
  'REQ_META_REVIEW_PRODUCTS_FILTERS'
export const RES_META_REVIEW_PRODUCTS_FILTERS =
  'RES_META_REVIEW_PRODUCTS_FILTERS'

export const REQ_META_REVIEW_PLATFORMS_FILTERS =
  'REQ_META_REVIEW_PLATFORMS_FILTERS'
export const RES_META_REVIEW_PLATFORMS_FILTERS =
  'RES_META_REVIEW_PLATFORMS_FILTERS'

/**
 * Replaces the mentions state with the passed filters
 *
 * @param {Object} filters - New filters state
 */
export function setMentionsFilters(filters) {
  // Ensure that the values that must be arrays are arrays
  const nextFilters = Object.assign({}, filters)

  // FIXME: Fix validation
  // const expectArray = [
  //   'gender', 'categories', 'feeds', 'sentiment', 'tags', 'themes',
  //   'ages', 'maritals', 'interests', 'devices', 'occupations',
  // ];

  // expectArray.forEach((key) => {
  //   const val = filters[key];
  //   // Avoid adding null/undefined values in the state
  //   if (val) nextFilters[key] = [].concat(val);
  // });
  //
  // // Ensure the sentiment is valid
  // if (!isEmpty(nextFilters.sentiment)) {
  //   nextFilters.sentiment = flow(
  //     map(toLower),
  //     filter(includes(__, ['positive', 'negative', 'objective', 'unknown'])),
  //   )(nextFilters.sentiment);
  // }

  return dispatch => {
    dispatch(setMentionsPage(0))
    dispatch({ type: SET_MENTIONS_FILTERS, payload: nextFilters })
  }
}

/**
 * Updates the available mentions categories
 */
export function addCategory(category) {
  return dispatch => {
    dispatch({ type: ADD_CATEGORY, payload: category })
  }
}

/**
 * Removes all the filters form the state
 */
export function clearMentionsFilters() {
  return { type: CLEAR_MENTIONS_FILTERS }
}

/**
 * Removes passed filters from the current filters
 *
 * @param {string} key    - Key of the filter to remove
 * @param {*}      [val]  - When key references an array `val` will be removed from the arrat,
 *                          when not set the whole array will be removed
 */
export function removeMentionsFilter(name, val, operator) {
  return (dispatch, getState) => {
    const {
      mentionsFilters: { filters },
    } = getState()
    const nextFilters = filters

    // When the key references an array and we specified a value, the next filters is an array
    // without the pased `value`
    if (val && name && operator) {
      const nextValue = filters[name][operator].filter(v => v !== val)
      nextFilters[name][operator] = nextValue
      return dispatch(setMentionsFilters(nextFilters))
    }

    // The filter is boolean
    nextFilters[name] = !val

    return dispatch(setMentionsFilters(nextFilters))
  }
}

/**
 * Fetch the categories for the filters
 */

function reqMetadata() {
  return { type: REQ_META_MENTIONS_FILTERS }
}

export function resMetadata({ categories, themes }) {
  return { type: RES_META_MENTIONS_FILTERS, payload: { categories, themes } }
}

export function fetchCategories(productId, viewId, apolloClient) {
  const productCategoriesQuery = {
    query: PRODUCT_CATEGORIES_QUERY,
    variables: {
      productId,
    },
  }
  const viewCategoriesQuery = {
    query: VIEW_CATEGORIES_QUERY,
    variables: {
      productId,
      viewId,
    },
  }
  return async dispatch => {
    dispatch(reqMetadata())

    let categories = []
    let themes = []

    if (viewId) {
      const { data } = await apolloClient.query(viewCategoriesQuery)
      categories = get('user.product.categories', data)
      themes = get('user.product.view.themes', data)

      // Allow Views without themes to have all categories by default
      if (every(isEmpty, [categories, themes])) {
        const { data: retryData } = await apolloClient.query(
          productCategoriesQuery,
        )
        categories = get('user.product.categories', retryData)
      }
    } else {
      const { data } = await apolloClient.query(productCategoriesQuery)
      categories = get('user.product.categories', data)
    }

    const sortedCategories = sortStringsValues([...categories], 'name')

    return dispatch(resMetadata({ categories: sortedCategories, themes }))
  }
}

export function fetchLocations(productId, apolloClient) {
  return async dispatch => {
    dispatch({ type: REQ_META_LOCATIONS_FILTERS })
    const { data } = await apolloClient.query({
      query: GET_REVIEW_PRODUCT_LOCATIONS,
      variables: { productId },
    })

    return dispatch({
      type: RES_META_LOCATIONS_FILTERS,
      payload: { locations: (data && data.getReviewProductLocations) || [] },
    })
  }
}

export function fetchReviewProducts(productId, apolloClient) {
  return async dispatch => {
    dispatch({ type: REQ_META_REVIEW_PRODUCTS_FILTERS })
    const { data } = await apolloClient.query({
      query: GET_REVIEW_PRODUCTS,
      variables: { productId },
    })

    return dispatch({
      type: RES_META_REVIEW_PRODUCTS_FILTERS,
      payload: { reviewProducts: (data && data.getReviewProducts) || [] },
    })
  }
}

export function fetchReviewPlatforms(productId, apolloClient) {
  return async dispatch => {
    dispatch({ type: REQ_META_REVIEW_PLATFORMS_FILTERS })
    const { data } = await apolloClient.query({
      query: PRODUCT_REVIEWS_QUERY,
      variables: { productId },
    })

    const reviewPlatforms = flow(
      getOr([], 'user.product.feeds'),
      flatMap('reviewPlatforms'),
    )(data)

    return dispatch({
      type: RES_META_REVIEW_PLATFORMS_FILTERS,
      payload: { reviewPlatforms },
    })
  }
}
