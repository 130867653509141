import { gql } from '@apollo/client'
import { CATEGORY_FRAGMENT } from 'apollo/fragments/Category'

export const THEME_FRAGMENT = gql`
  fragment ThemeData on Theme {
    id
    name
    categories {
      ...CategoryData
    }
  }
  ${CATEGORY_FRAGMENT}
`
