import { FC, memo } from 'react'
import type { Props } from '../../types'

const OfficialAccounts: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90909 1.35211C1.66299 1.35211 1.27273 1.65536 1.27273 2.3036V7.18623C1.27273 9.20154 1.89979 11.1147 2.98971 12.5084C4.0768 13.8986 5.52314 14.6479 7 14.6479C10.0577 14.6479 12.7273 11.4299 12.7273 7.18623V2.3036C12.7273 1.65536 12.337 1.35211 12.0909 1.35211H1.90909ZM0 2.3036C0 1.15411 0.749375 0 1.90909 0H12.0909C13.2506 0 14 1.1541 14 2.3036V7.18623C14 11.9312 10.9713 16 7 16C5.10138 16 3.31049 15.0346 2.01074 13.3725C0.713842 11.714 0 9.48743 0 7.18623V2.3036Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6075 4.68059C10.8688 4.93037 10.8899 5.35783 10.6548 5.63536L6.83664 10.1424C6.61406 10.4051 6.23852 10.4414 5.97503 10.2255L3.42958 8.14016C3.15126 7.91215 3.09963 7.48762 3.31425 7.19195C3.52887 6.89628 3.92847 6.84142 4.20678 7.06943L6.28518 8.77215L9.70881 4.73084C9.94392 4.45331 10.3463 4.43082 10.6075 4.68059Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(OfficialAccounts)
