import { FC, memo } from 'react'
import type { Props } from '../types'

const Share: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M12.2072 11.4236C11.6904 11.4236 11.2279 11.633 10.8755 11.9632L6.02235 9.04766C6.05738 8.88608 6.08448 8.72333 6.08448 8.55532C6.08448 8.38732 6.05681 8.22515 6.02235 8.06299L10.8207 5.17489C11.1866 5.5249 11.6707 5.74363 12.2072 5.74363C13.3384 5.74363 14.25 4.80388 14.25 3.63548C14.25 2.46942 13.3379 1.52734 12.2072 1.52734C11.0782 1.52734 10.1661 2.46942 10.1661 3.63492C10.1661 3.80524 10.1938 3.96509 10.2282 4.12725L5.42989 7.01766C5.06335 6.66593 4.57992 6.44775 4.04279 6.44775C2.91156 6.44775 2 7.38926 2 8.55532C2 9.72138 2.91156 10.6635 4.04279 10.6635C4.57932 10.6635 5.06335 10.4447 5.42932 10.0947L10.274 13.0167C10.2406 13.1654 10.2197 13.3206 10.2197 13.4752C10.2197 14.6068 11.1126 15.5273 12.2066 15.5273C13.3017 15.5273 14.1952 14.6074 14.1952 13.4752C14.1952 12.3429 13.3023 11.4236 12.2072 11.4236Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Share)
