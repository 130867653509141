import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'

import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Languages } from './i18n.types'

const loadPath = () => '/locales/{{lng}}/{{ns}}.json'

// Increment this version to regenerate cached translations
const translateVersion = 'v2.330'

// Translations namespaces to be used by i18n
const nameSpaces = [
  'actions',
  'alerts',
  'analysis',
  'categoriesMenu',
  'columns',
  'common',
  'config',
  'configViews',
  'connect',
  'countries',
  'datepicker',
  'dates',
  'emotionsWidget',
  'errors',
  'evolutionChart',
  'feeds',
  'fileInput',
  'fuzzySelect',
  'info',
  'learning',
  'locations',
  'magicSearch',
  'mentions',
  'message',
  'modals',
  'navSidebar',
  'newProjectSetup',
  'openAi',
  'profile',
  'projectNewListening',
  'public',
  'reports',
  'recovers',
  'sentiments',
  'summary',
  'socialLogin',
  'sources',
  'sourcesConfig',
  'toasts',
  'tooltips',
  'twitterConfig',
  'views',
  'viewsFilter',
]

// TODO: remove the myDetector language detector when we are ready to show translations in production
const myDetector = {
  name: 'MyDetector',
  lookup() {
    return Languages.spanish
  },
}

const languagedetector = new LanguageDetector(null, { autoDetect: true })
languagedetector.addDetector(myDetector)

i18n
  .use(HttpBackend)
  .use(languagedetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage'],
    },
    fallbackLng: Languages.spanish,
    debug: false,
    ns: nameSpaces,
    defaultNS: 'common',
    returnObjects: true,
    joinArrays: true,
    cleanCode: true,
    interpolation: {
      escapeValue: false,
      format(value, format) {
        if (value instanceof Date) return moment(value).format(format)
        return value
      },
    },
    backend: {
      loadPath,
      queryStringParams: { v: translateVersion },
    },
  })

export default i18n
