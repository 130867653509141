import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'

const useLogic = () => {
  const { disconnectIntegrationPayload, modalType, onCloseModal } = useModal()

  const { desc, submit: handleSubmit } = disconnectIntegrationPayload || {}

  return {
    desc,
    isOpen: modalType === ModalType.DISCONNECT_INTEGRATION,
    handleSubmit,
    onCloseModal,
  }
}

export default useLogic
