import { FC, memo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useLogic from './logic'
import * as S from './styles'
import { Props } from './types'

const Link: FC<Props> = ({ children, external, to, className, onClick }) => {
  const { handleOnClick } = useLogic({ onClick })

  return (
    <S.Link onClick={handleOnClick} className={className}>
      {external ? (
        <S.A href={to} target="_blank" rel="noopener noreferrer">
          {children}
        </S.A>
      ) : (
        <RouterLink to={to}>{children}</RouterLink>
      )}
    </S.Link>
  )
}

export default memo(Link)
