import { gql } from '@apollo/client'
import { BASE_PRODUCT_FRAGMENT } from 'apollo/fragments/Product'

export const GET_PRESET_VIEWS = gql`
  query GetPresetViews {
    presetViews {
      markets {
        id
        name
      }
      topics {
        name
      }
    }
  }
`

export const GET_PRODUCT_LAST_MENTION_DATE = gql`
  query GetProductLastMentionDate($productId: ID!) {
    lastMentionDate(productId: $productId)
  }
`

export const GET_PRODUCT = gql`
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      ...BaseProductData
    }
  }
  ${BASE_PRODUCT_FRAGMENT}
`

export const GET_PRODUCT_CATEGORIES = gql`
  query GetProductCategories(
    $productId: ID!
    $withThemes: Boolean = false
    $withCategories: Boolean = true
    $viewId: ID = ""
  ) {
    user {
      id
      product(id: $productId) {
        id
        sentiment_rounding
        ... on Product {
          categories @include(if: $withCategories) {
            id
            name
            description
            isExtended
            isMarket
            keywords {
              words
              operator
            }
            riskLevel
            originalCategoryName
            source
          }
        }
        view(id: $viewId) @include(if: $withThemes) {
          id
          name
          default
          themes {
            id
            name
            categories {
              id
              name
            }
          }
          ... on View {
            filters {
              ages {
                or
              }
              aspects {
                or
                not
                and
              }
              authors {
                or
                not
              }
              categories {
                or
                or_additional
                not
                and
              }
              devices {
                or
              }
              feeds {
                or
                not
              }
              followers {
                min
                max
              }
              genders {
                or
              }
              geoLocations {
                country {
                  or
                  not
                }
                county {
                  or
                  not
                }
                region {
                  or
                  not
                }
                locality {
                  or
                  not
                }
              }
              hashtags {
                or
                not
                and
              }
              interests {
                or
              }
              isDark
              locations {
                or
                not
              }
              maritalStatuses {
                or
              }
              mentions {
                or
                not
                and
              }
              occupations {
                or
              }
              ratings {
                or
              }
              sentiments {
                or
              }
              stores {
                or
                not
              }
              texts {
                ands
                ors
                nots
              }
              themes {
                or
              }
              types {
                or
              }
            }
          }
        }
      }
    }
  }
`

export const GET_REVIEW_PRODUCT_LOCATIONS = gql`
  query GetReviewProductLocations($productId: ID!, $feedsIds: [String!]) {
    getReviewProductLocations(productId: $productId, feedsIds: $feedsIds) {
      name
      locationName
      address {
        regionCode
        postalCode
        administrativeArea
        locality
        addressLines
      }
    }
  }
`

export const GET_REVIEW_PRODUCTS = gql`
  query GetReviewProducts($productId: ID!, $feedsIds: [String!]) {
    getReviewProducts(productId: $productId, feedsIds: $feedsIds) {
      name
      id
    }
  }
`
