import { FC, memo } from 'react'
import Icon from 'components/IconV2'
import * as S from './styles'
import { Props } from './types'

const Card: FC<Props> = ({
  title,
  desc,
  img,
  action,
  actionIcon,
  disabled,
  onClick,
}) => {
  return (
    <S.Container $disabled={disabled} onClick={disabled ? null : onClick}>
      <S.Img src={img} />
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Desc>{desc}</S.Desc>
        <S.Action>
          <Icon name={actionIcon} size={12} />
          <S.ActionText>{action}</S.ActionText>
        </S.Action>
      </S.Content>
    </S.Container>
  )
}

export default memo(Card)
