import invariant from 'invariant'
import { get, find } from 'lodash/fp'

import { setOnboardingComplete } from './sessionDataActions'

// Step copies and cofig {{{

export const FOCUS_NAV_SUMMARY = 'FOCUS_NAV_SUMMARY'
export const FOCUS_SOURCE_DATE_FILTERS = 'FOCUS_SOURCE_DATE_FILTERS'
export const FOCUS_VIEW_FILTER = 'FOCUS_VIEW_FILTER'
export const FOCUS_NAV_CONTENT_TO_INFLUENCE = 'FOCUS_CONTENT_TO_INFLUENCE'
export const FOCUS_NAV_ACCOUNTS = 'FOCUS_NAV_ACCOUNTS'
export const FOCUS_NAV_ALERTS = 'FOCUS_NAV_ALERTS'
export const FOCUS_NAV_REPORTS = 'FOCUS_NAV_REPORTS'
export const FOCUS_NAV_LEARNING_CENTER = 'FOCUS_NAV_LEARNING_CENTER'
export const FOCUS_NAV_KEYWORDS = 'FOCUS_NAV_KEYWORDS'
export const FOCUS_NAV_USERS = 'FOCUS_NAV_USERS'
export const FOCUS_NAV_TVPANEL = 'FOCUS_NAV_TVPANEL'

const getStep = (feeds, productType, step) => {
  let steps = get(productType, {
    drive: [
      {
        title: '¡Bienvenido al dashboard de Séntisis Drive!',
        body: 'Hemos hecho este breve recorrido para que puedas familiarizarte rápidamente con la interfaz y ayudarte en los primeros pasos.',
      },
      {
        title: 'En Drive encontrarás toda la conversación sobre tu marca',
        body: 'La sección de Resumen es el punto de partida, y te permite entender de un vistazo lo que está pasando en redes sociales: evolución, temas clave, sentimiento, etc.',
        focusedElement: FOCUS_NAV_SUMMARY,
      },
      {
        title: 'Elige la red social y el periodo de tiempo',
        body: 'Aquí puedes seleccionar los datos que se muestran en el dashboard y analizar desde 1 minuto hasta 1 año',
        focusedElement: FOCUS_SOURCE_DATE_FILTERS,
      },
      {
        title:
          'Adapta el dashboard a tus objetivos de negocio gracias a las Vistas',
        body: 'Las vistas te permiten tener varios dashboards en uno, visualizando separadamente distintos tipos de conversación. Selecciona cualquiera de nuestras Vistas adaptadas a tu industria o crea una nueva según tus intereses.',
        focusedElement: FOCUS_VIEW_FILTER,
      },
      {
        title: 'Analiza la conversación en detalle',
        body: 'Visita las secciones de Resumen, Categorías y Menciones para extraer datos accionables del análisis de la conversación. Además complementa tu análisis con otras secciones como Conceptos clave, Influencia o Cuentas oficiales.',
        focusedElement: FOCUS_NAV_CONTENT_TO_INFLUENCE,
      },
      {
        title:
          'Analiza y comparte los análisis de redes sociales con tu equipo',
        body: 'En la sección de Informes automáticos podrás crear y descargar en PDF todos los datos listos para presentar.',
        focusedElement: FOCUS_NAV_REPORTS,
      },
      {
        title: 'Visualiza la última información de tu marca en tiempo real',
        body: 'Visualiza la última información de tu marca en tiempo real.',
        focusedElement: FOCUS_NAV_TVPANEL,
      },
      {
        title: '¿Quiéres crear alertas que te lleguen al email?',
        body: 'Aquí podrás personalizar los avisos que recibirás en tu correo electrónico para enterarte en tiempo real de cualquier cambio en la conversación.',
        focusedElement: FOCUS_NAV_ALERTS,
      },
      {
        title: '¿Tienes alguna duda? Utiliza nuestro centro de ayuda',
        body: 'En el Learning Center encontrarás artículos sobre todas las secciones y consejos para extraer todo el valor a Séntisis. Además, podrás volver a reproducir este tour siempre que lo necesites. ¡Empezamos!',
        focusedElement: FOCUS_NAV_LEARNING_CENTER,
      },
    ],
    race: [
      {
        title: '¡Bienvenido al dashboard de Séntisis Race!',
        body: 'Este breve recorrido te ayudará a familiarizarte rápidamente con la interfaz y empezar a sacar el máximo partido a Séntisis.',
      },
      {
        title: 'En Race encontrarás toda la conversación sobre tu sector',
        body: 'La sección de Resumen es el punto de partida, y te permite entender de un vistazo lo que está pasando en redes sociales: comparación de competidores, evolución, temas clave, sentimiento, etc.',
        focusedElement: FOCUS_NAV_SUMMARY,
      },
      {
        title: 'Elige la red social y el periodo de tiempo',
        body: 'Aquí puedes seleccionar los datos que se muestran en el dashboard y analizar desde 1 minuto hasta 1 año.',
        focusedElement: FOCUS_SOURCE_DATE_FILTERS,
      },
      {
        title:
          'Adapta el dashboard a tus objetivos de negocio gracias a las Vistas',
        body: 'Las vistas te permiten tener varios dashboards en uno, visualizando separadamente distintos tipos de conversación. Selecciona cualquiera de nuestras Vistas adaptadas a tu industria o crea una nueva según tus intereses.',
        focusedElement: FOCUS_VIEW_FILTER,
      },
      {
        title: 'Analiza la conversación en detalle',
        body: 'Visita las secciones de Resumen, Categorías y Menciones para extraer datos accionables del análisis de la conversación. Además complementa tu análisis con otras secciones como Conceptos clave, Influencia o Cuentas oficiales.',
        focusedElement: FOCUS_NAV_CONTENT_TO_INFLUENCE,
      },
      {
        title:
          'Analiza y comparte los análisis de redes sociales con tu equipo',
        body: 'En la sección de Informe automáticos podrás crear y descargar en PDF todos los datos listos para presentar.',
        focusedElement: FOCUS_NAV_REPORTS,
      },
      {
        title: '¿Quiéres crear alertas que te lleguen al email?',
        body: 'Aquí podrás personalizar los avisos que recibirás en tu correo electrónico para enterarte en tiempo real de cualquier cambio en la conversación.',
        focusedElement: FOCUS_NAV_ALERTS,
      },
      {
        title: '¿Tienes alguna duda? Utiliza nuestro centro de ayuda',
        body: 'En el Learning Center encontrarás artículos sobre todas las secciones y consejos para extraer todo el valor a Séntisis. Además, podrás volver a reproducir este tour siempre que lo necesites. ¡Empezamos!',
        focusedElement: FOCUS_NAV_LEARNING_CENTER,
      },
    ],
    explore: [
      {
        title: '¡Bienvenido al dashboard de Séntisis Explore!',
        body: 'Hemos hecho este breve recorrido para que puedas familiarizarte rápidamente con la interfaz y ayudarte en los primeros pasos.',
      },
      {
        title:
          'Explore te permite analizar la conversación espontánea relevante y descubrir las tendencias',
        body: 'Este primer bloque agrupa la conversación de las tendencias monitorizadas y te permite tener una visión global de lo que sucede en la conversación mediante sus tres secciones.',
        focusedElement: FOCUS_NAV_SUMMARY,
      },
      {
        title: 'Elige la red social y el periodo de tiempo',
        body: 'Aquí puedes seleccionar los datos que se muestran en el dashboard y analizar desde 1 minuto hasta 1 año.',
        focusedElement: FOCUS_SOURCE_DATE_FILTERS,
      },
      {
        title:
          'Adapta el dashboard a tus objetivos de negocio gracias a las Vistas',
        body: 'Las vistas te permiten tener varios dashboards en uno, visualizando separadamente distintos tipos de conversación. Selecciona cualquiera de nuestras Vistas adaptadas a tu industria o crea una nueva según tus intereses.',
        focusedElement: FOCUS_VIEW_FILTER,
      },
      {
        title: 'Tendencias: extrae insights de la conversación orgánica',
        body: 'Visita las secciones disponibles para analizar en detalle la conversación.',
        focusedElement: FOCUS_NAV_KEYWORDS,
      },
      {
        title: 'Descubre de qué hablan los grupos de interés',
        body: 'Utiliza las subsecciones de usuarios para analizar en detalle a cada comunidad.',
        focusedElement: FOCUS_NAV_USERS,
      },
      {
        title: '¿Quiéres crear alertas que te lleguen al email?',
        body: 'Configura avisos que recibirás en tu correo electrónico para controlar lo que sucede en la conversación aunque no estés dentro de Séntisis.',
        focusedElement: FOCUS_NAV_ALERTS,
      },
      {
        title: '¿Tienes alguna duda? Utiliza nuestro centro de ayuda',
        body: 'En el Learning Center encontrarás artículos sobre todas las secciones y consejos para extraer todo el valor a Séntisis. Además, podrás volver a reproducir este tour siempre que lo necesites. ¡Empezamos!',
        focusedElement: FOCUS_NAV_LEARNING_CENTER,
      },
    ],
  })

  invariant(steps, 'Uknown onboarding steps for product %s', productType)

  if (productType === 'explore') {
    const hasUsers = Boolean(find({ type: 'USERS' }, feeds))
    const hasKeywords = Boolean(find({ type: 'KEYWORDS' }, feeds))

    steps = steps.filter(s => {
      if (s.focusedElement === FOCUS_NAV_KEYWORDS && !hasKeywords) {
        return false
      }

      if (s.focusedElement === FOCUS_NAV_USERS && !hasUsers) {
        return false
      }

      return true
    })
  }

  if (typeof step !== 'number') return steps

  return steps[step]
}

const getFeeds = (productId, sessionData) => {
  const feeds = sessionData.products[productId].feeds || []
  return feeds.map(fid => sessionData.feeds[fid])
}

// }}}

export const ONBOARDING_INIT = 'ONBOARDING_INIT'
export const ONBOARDING_END = 'ONBOARDING_END'
export const ONBOARDING_SET_STEP = 'ONBOARDING_SET_STEP'

function onboardingSetStep({ step, title, body, focusedElement }) {
  return {
    type: ONBOARDING_SET_STEP,
    payload: {
      step,
      title,
      body,
      focusedElement,
    },
  }
}

function onboardingInit({ total, title, body, focusedElement }) {
  return {
    type: ONBOARDING_INIT,
    payload: {
      total,
      title,
      body,
      focusedElement,
    },
  }
}

function onboardingEnd() {
  return { type: ONBOARDING_END }
}

export function onboardingGoTo(product, step) {
  return (dispatch, getState) => {
    const { sessionData } = getState()
    const feeds = getFeeds(product.id, sessionData)
    const stepDetails = getStep(feeds, product.type, step)

    return dispatch(onboardingSetStep({ step, ...stepDetails }))
  }
}

export function onboardingStart(product) {
  return (dispatch, getState) => {
    const { sessionData } = getState()
    const feeds = getFeeds(product.id, sessionData)
    const steps = getStep(feeds, product.type)

    dispatch(onboardingInit({ total: steps.length, ...steps[0] }))
  }
}

export function onboardingClose(product) {
  return dispatch => {
    dispatch(onboardingEnd())
    return dispatch(setOnboardingComplete(product.type))
  }
}
