import { FC, memo } from 'react'
import type { Props } from '../../types'

const Mentions: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.400024 2.13862C0.400024 1.06536 1.42833 0.400024 2.41561 0.400024H12.3839C13.3711 0.400024 14.3994 1.06534 14.3995 2.13857L14.4 8.99939C14.4 10.0726 13.3717 10.738 12.3844 10.738H4.26321L1.44972 14.1827C1.29149 14.3764 1.02851 14.4496 0.792901 14.3656C0.557294 14.2816 0.399993 14.0832 0.400024 13.8331L0.400024 2.13862ZM1.58332 12.1488L3.70196 9.55493H12.3844C12.97 9.55493 13.2169 9.1932 13.2169 8.99946L13.2164 2.13866C13.2164 1.94493 12.9695 1.58312 12.3839 1.58312H2.41561C1.82997 1.58312 1.58314 1.94485 1.58312 2.13859L1.58332 12.1488Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Mentions)
