import Promise from 'bluebird'
import moment from 'moment'
import { uniqueId } from 'lodash/fp'

import SocketError from './utils/SocketError'
import getSocketConnection from './getSocketConnection'

// This script is also used in the web worker
let isWorker = false
try {
  // This is a nice trick to detect if we are running in a worker: trying to
  // access the document in a worker throws an error.
  Boolean(document)
} catch (e) {
  isWorker = true
}

// We configure the Bluebird promise here too
Promise.config({
  cancellation: true,
  // In a worker or production environment don't log long stack traces and errors
  longStackTraces: isWorker
    ? false
    : process.env.REACT_APP_ENV !== 'production',
  warnings: isWorker ? false : process.env.REACT_APP_ENV !== 'production',
})

/* eslint-disable no-console */
function logResponse({ reqName, resName }, request, response) {
  //console-log-checker-ignore
  console.groupCollapsed(
    `${isWorker ? 'Worker: ' : ''}${moment().format('HH:mm:ss')}: ${reqName}`,
  )
  //console-log-checker-ignore
  console.log('%cRequest:', 'font-weight: bold;')
  //console-log-checker-ignore
  console.log(reqName)
  //console-log-checker-ignore
  console.log(request)
  //console-log-checker-ignore
  console.log(
    '%c- - - - - - - - - - - - - - - - - -',
    'font-size: 10px; color: gray',
  )
  //console-log-checker-ignore
  console.log('%cResponse:', 'font-weight: bold;')
  //console-log-checker-ignore
  console.log(resName)
  //console-log-checker-ignore
  console.log(response)
  //console-log-checker-ignore
  console.groupEnd()
}
/* eslint-enable */

/**
 * Holds the registered listeners
 */
const listeners = {}

/**
 * Transforms the socket event into a promise.
 * Returns a cancelleable promise.
 */
export default function requestSocket(eventName, payload) {
  const socket = getSocketConnection()

  /**
   * Call the listener on a specific request id.
   */
  socket.on('*', packet => {
    if (!Array.isArray(packet.data)) return

    const [event, res] = packet.data

    if (!res || !res.request_id) return

    const listener = listeners[res.request_id]
    if (!listener) return

    listener(event, res)

    delete listeners[res.request_id]
  })

  return new Promise((resolve, reject, onCancel) => {
    const reqId = uniqueId(`${eventName}:`)

    // Socket handler
    const handleSocketRes = (resEvent, data) => {
      const eventInfo = {
        id: reqId,
        reqName: eventName,
        resName: resEvent,
        metadata: data.metadata,
      }

      logResponse(eventInfo, payload, data)

      if (!data) return reject(new SocketError('No data received', eventInfo))
      if (data.error) return reject(new SocketError(data.error, eventInfo))

      return resolve(data.payload)
    }

    listeners[reqId] = handleSocketRes

    // Remove the listener when the promise is canceled
    onCancel(() => {
      delete listeners[reqId]
    })

    return socket.emit(eventName, { payload, request_id: reqId })
  })
}
