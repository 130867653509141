import { FC, memo } from 'react'
import type { Props } from '../../types'

const LinkedinInverted: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0.64888 3.25729H2.51676V9.26621H0.64888V3.25729ZM1.58145 0.266602C2.17736 0.266602 2.66171 0.751802 2.66171 1.34876C2.66171 1.94572 2.17736 2.4321 1.58184 2.4321C1.29518 2.43147 1.02043 2.31715 0.817689 2.11413C0.614951 1.91111 0.500726 1.63592 0.5 1.34876C0.5 1.20655 0.527981 1.06573 0.582343 0.934363C0.636705 0.802992 0.716382 0.683644 0.816819 0.583141C0.917256 0.482638 1.03648 0.402951 1.16768 0.348637C1.29888 0.294323 1.43948 0.266447 1.58145 0.266602ZM3.68306 3.25729H5.47119V4.07855H5.49594C5.74499 3.60633 6.35308 3.10815 7.26365 3.10815C9.15156 3.10815 9.5 4.35282 9.5 5.97055V9.2666H7.63683V6.3436C7.63683 5.64708 7.62426 4.74988 6.66813 4.74988C5.69785 4.74988 5.54858 5.50935 5.54858 6.29244V9.26503H3.68581V3.25611L3.68306 3.25729Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(LinkedinInverted)
