export const SELECT_MENTION = 'SELECT_MENTION'
export const SELECT_ALL_MENTIONS = 'SELECT_ALL_MENTIONS'
export const CLEAR_SELECTED_MENTIONS = 'CLEAR_SELECTED_MENTIONS'
export const REFRESH_MENTIONS = 'REFRESH_MENTIONS'
export const SET_TOTAL_MENTIONS = 'SET_TOTAL_MENTIONS'
export const SET_MENTIONS_PAGE = 'SET_MENTIONS_PAGE'
export const SET_MENTIONS_ORDER = 'SET_MENTIONS_ORDER'
export const SET_PENDING_MENTIONS = 'SET_PENDING_MENTIONS'
export const SET_RESULTS_PER_PAGE = 'SET_RESULTS_PER_PAGE'
export const MENTIONS_REHYDRATE = 'persist/REHYDRATE'

export function selectMention(mention) {
  return { type: SELECT_MENTION, payload: { mention } }
}

export function selectAllMentions() {
  return { type: SELECT_ALL_MENTIONS }
}

export function clearSelectedMentions() {
  return { type: CLEAR_SELECTED_MENTIONS }
}

export function setTotalMentions(total) {
  return { type: SET_TOTAL_MENTIONS, payload: { total } }
}

export function setMentionsPage(page) {
  return { type: SET_MENTIONS_PAGE, payload: { page } }
}

export function setMentionsOrder(order, sort) {
  return { type: SET_MENTIONS_ORDER, payload: { order, sort } }
}

export function refreshMentions() {
  return { type: REFRESH_MENTIONS, payload: {} }
}

export const setPendingMentions = pending => ({
  type: SET_PENDING_MENTIONS,
  payload: { pending },
})
export function setResultsPerPage(value) {
  return { type: SET_RESULTS_PER_PAGE, payload: value }
}
