import { FC, memo } from 'react'
import type { Props } from '../../types'

const Twitter: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.9047 8.68247L19.3513 0.212891H17.5873L11.1187 7.56541L5.956 0.212891H0L7.80867 11.3323L0 20.2128H1.764L8.59067 12.4467L14.044 20.2128H20M2.40067 1.51447H5.11067L17.586 18.9752H14.8753"
      fill="currentColor"
    />
  </svg>
)

export default memo(Twitter)
