import { FC, memo } from 'react'
import type { Props } from '../types'

const Close: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M14.4707 1.41L13.0607 0L7.4707 5.59L1.8807 0L0.470703 1.41L6.0607 7L0.470703 12.59L1.8807 14L7.4707 8.41L13.0607 14L14.4707 12.59L8.8807 7L14.4707 1.41Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Close)
