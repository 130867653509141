import { FC, memo } from 'react'
import type { Props } from '../types'

const ClipboardFilled: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1 5.38013H9C9.55228 5.38013 10 5.82784 10 6.38013V14.3801C10 14.9324 9.55228 15.3801 9 15.3801H1C0.447715 15.3801 0 14.9324 0 14.3801V6.38013C0 5.82784 0.447715 5.38013 1 5.38013Z"
      fill="currentColor"
    />
    <path
      d="M14 1.04688H6C5.44772 1.04688 5 1.49459 5 2.04688V4.38022H9C10.1046 4.38022 11 5.27565 11 6.38022V11.0469H14C14.5523 11.0469 15 10.5992 15 10.0469V2.04688C15 1.49459 14.5523 1.04688 14 1.04688Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ClipboardFilled)
