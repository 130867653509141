import { gql } from '@apollo/client'
import { THEME_FRAGMENT } from 'apollo/fragments/Theme'
import { CATEGORY_FRAGMENT } from 'apollo/fragments/Category'

export const VIEW_FRAGMENT = gql`
  fragment ViewData on View {
    id
    name
    default
    themes {
      ...ThemeData
    }
    categories {
      ...CategoryData
    }
  }
  ${THEME_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`
