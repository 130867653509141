import { FC, memo } from 'react'
import type { Props } from '../../types'

const ArrowLeft: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76417 0.167725L5.50525 0.855162L1.83188 5.16773L5.50525 9.48029L4.76417 10.1677L0.505249 5.16773L4.76417 0.167725Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowLeft)
