/**
 * SocketError is used to handle Socket errors.
 * Extends error.
 */
function SocketError(err, { reqName, resName, id, metadata } = {}) {
  const key = `[${reqName || ''}(${id || ''})->${resName || ''}]:`

  this.message = `SocketError: ${key} ${err ? err.message || err : err}`
  this.metadata = metadata
  this.originalMessage = err ? err.message || err : err
  this.name = 'SocketError'

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, SocketError)
  }
}

SocketError.prototype = Object.create(Error.prototype)
SocketError.prototype.constructor = SocketError

export default SocketError
