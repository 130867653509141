import request from '../../request'

export const SESSION_DATA_RES = 'SESSION_DATA_RES'
export const SESSION_DATA_NEEDS_LOGIN = 'SESSION_DATA_NEEDS_LOGIN'
export const SESSION_DATA_LOGOUT = 'SESSION_DATA_LOGOUT'
export const SESSION_DATA_LOGIN = 'SESSION_DATA_LOGIN'
export const SET_USER_TIMEZONE = 'SET_USER_TIMEZONE'
export const UPDATE_USER_ONBOARDING = 'UPDATE_USER_ONBOARDING'

export function sessionDataRes({ user, client, brands, error }) {
  return {
    error,
    type: SESSION_DATA_RES,
    payload: { user, client, brands },
  }
}

export function needsLogin() {
  return { type: SESSION_DATA_NEEDS_LOGIN }
}

export function setTimezone(timezone) {
  return { type: SET_USER_TIMEZONE, payload: { timezone } }
}

export function logout() {
  return { type: SESSION_DATA_LOGOUT, payload: {} }
}

export function login() {
  return { type: SESSION_DATA_LOGIN, payload: {} }
}

function updateUserOnboarding({ productType }) {
  return { type: UPDATE_USER_ONBOARDING, payload: { [productType]: true } }
}

export function setOnboardingComplete(productType) {
  return dispatch => {
    dispatch(updateUserOnboarding({ productType }))

    return request.put('/users/me', {
      onboarding: {
        [productType]: true,
      },
    })
  }
}
