import { FC, memo } from 'react'
import type { Props } from '../../types'

const Drive: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width="36" height="36" rx="18" fill="#0052A3" />
    <g clipPath="url(#clip0_1100_37560)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9999 19.9733C17.0024 19.9733 16.1943 19.0901 16.1943 18C16.1943 16.9098 17.0024 16.0266 17.9999 16.0266C18.9974 16.0266 19.8056 16.9098 19.8056 18C19.8056 19.0901 18.9974 19.9733 17.9999 19.9733ZM24.9961 10.015L20.2526 12.2251L15.0221 14.6614C14.9869 14.6784 14.9606 14.7072 14.945 14.7456L12.7157 20.4619L10.6934 25.6458C10.6231 25.8272 10.8378 26.0618 11.0038 25.985L15.7473 23.7749L20.9778 21.3386C21.013 21.3216 21.0393 21.2928 21.0549 21.2544L23.2842 15.5381L25.3065 10.3542C25.3768 10.1728 25.1621 9.93817 24.9961 10.015Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1100_37560">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Drive)
