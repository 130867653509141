import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createAction,
} from '@reduxjs/toolkit'
import { View } from 'models/View'
import type { ViewFiltersInput } from 'apollo/generated/globalTypes'

const PREFIX = 'APPCONFIG_'

export interface IConfigActions {
  /** Holds filters in view */
  storeViewFilters: ActionCreatorWithPayload<ViewFiltersInput, string>
  /** Cleans saved state for magic search in config */
  clearViewFilters: ActionCreatorWithoutPayload<string>
  /** Sets current view */
  setCurrentView: ActionCreatorWithPayload<View>
  /** Update current view */
  updateCurrentView: ActionCreatorWithPayload<View>
  /** Update sentiment Rounding flag */
  setSentimentRounding: ActionCreatorWithPayload<boolean>
}

export const appConfigurationActions: IConfigActions = {
  storeViewFilters: createAction<ViewFiltersInput>(
    `${PREFIX}STORE_VIEW_FILTERS`,
  ),
  clearViewFilters: createAction<undefined>(`${PREFIX}CLEAR_VIEW_FILTERS`),
  setCurrentView: createAction<View>(`${PREFIX}SET_CURRENT_VIEW`),
  updateCurrentView: createAction<View>(`${PREFIX}UPDATE_CURRENT_VIEW`),
  setSentimentRounding: createAction<boolean>(
    `${PREFIX}UPDATE_SENTIMENT_ROUNDING`,
  ),
}
