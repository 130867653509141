import { set } from 'lodash/fp'

import {
  SET_ACCOUNT_MENTIONS_SORT,
  SET_ACCOUNT_MENTIONS_ORDER,
  SET_ACCOUNT_MENTIONS_DEFAULT,
  SET_ACCOUNT_MENTIONS_TOTAL,
  SET_ACCOUNT_MENTIONS_PAGE,
} from '../actions/accountMentionsActions'

const initialState = {
  order: 'desc',
  sort: 'time',
  total: 0,
  page: 0,
}

export default function communityLocation(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_ACCOUNT_MENTIONS_SORT:
      return set('sort', payload.sort, state)

    case SET_ACCOUNT_MENTIONS_ORDER:
      return set('order', payload.order, state)

    case SET_ACCOUNT_MENTIONS_DEFAULT:
      return initialState

    case SET_ACCOUNT_MENTIONS_TOTAL:
      return set('total', payload.total, state)

    case SET_ACCOUNT_MENTIONS_PAGE:
      return set('page', payload.page, state)

    default:
      return state
  }
}
