import { useCallback, useState } from 'react'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'

const useLogic = () => {
  const { removeDialogPayload, modalType, onCloseModal } = useModal()
  const [canSubmit, setCanSubmit] = useState(false)

  const handleOnCloseModal = useCallback(() => {
    if (removeDialogPayload?.onClose) {
      removeDialogPayload.onClose()
    }

    onCloseModal()
  }, [removeDialogPayload, onCloseModal])

  const handleOnRemove = useCallback(() => {
    if (removeDialogPayload?.onRemove) {
      removeDialogPayload.onRemove()
    }

    handleOnCloseModal()
  }, [removeDialogPayload, handleOnCloseModal])

  const checkIsValid = useCallback(
    (name: string) => {
      if (removeDialogPayload?.hasToConfirm) {
        return setCanSubmit(
          removeDialogPayload?.hasToConfirm?.confirmName === name,
        )
      }

      setCanSubmit(true)
    },
    [removeDialogPayload],
  )

  return {
    handleOnCloseModal,
    handleOnRemove,
    isOpen: modalType === ModalType.REMOVE_DIALOG,
    removeDialogPayload,
    confirmData: removeDialogPayload?.hasToConfirm,
    checkIsValid,
    canSubmit,
  }
}

export default useLogic
