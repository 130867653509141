import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'

const Banner = styled.div`
  order: -1;
  background-color: ${({ theme }) => theme.colors.sentiment.objective};
  position: relative;
  z-index: 100;
  padding: 15px 30px;
  text-align: center;
  color: white;
  line-height: 1.5;
`

const Button = styled.button`
  text-decoration: underline;
  appearance: none;
  color: white;
  padding: 0;
  border: 0;
  outline: none;
  font-weight: 600;
  background-color: transparent;
  margin: 0 3px 0 5px;
`

class UpdateNotificationContainer extends React.PureComponent {
  static propTypes = {
    updateAvailable: PropTypes.bool.isRequired,
  }

  handleClickReload() {
    window.location.reload()
  }

  render() {
    const { updateAvailable } = this.props

    if (!updateAvailable) return null

    return (
      <Banner>
        Hay una nueva versión de Séntisis disponible.
        <Button onClick={this.handleClickReload}>Recarga ahora</Button>
        para conseguir las últimas novedades.
      </Banner>
    )
  }
}

const mapStateToProps = state => ({
  updateAvailable: state.update.update,
})

export default connect(mapStateToProps)(UpdateNotificationContainer)
