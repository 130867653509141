import { set } from 'lodash/fp'

import { DATA_REQ, DATA_RES } from '../actions/dataActions'

const defaultNodeState = {
  loading: true,
  err: undefined,
  res: undefined,
}

export default function data(state = {}, action) {
  const { type, payload, error, meta } = action

  switch (type) {
    case DATA_REQ:
      return set(meta.path, defaultNodeState, state)

    case DATA_RES:
      return set(
        meta.path,
        {
          loading: false,
          err: error,
          res: payload,
        },
        state,
      )

    default:
      return state
  }
}
