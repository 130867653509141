import { FC } from 'react'
import Link from 'components/Link/Link'
import * as S from './styles'
import { Props } from './types'

const IllustratedMessage: FC<Props> = ({
  illustration,
  title,
  children,
  linkUrl,
  linkText,
}) => {
  const hasLink = linkUrl && linkText

  return (
    <S.Container>
      {illustration}
      <S.Title>{title}</S.Title>
      <S.Message>{children}</S.Message>
      {hasLink && <Link to={linkUrl}>{linkText}</Link>}
    </S.Container>
  )
}

export default IllustratedMessage
