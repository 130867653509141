import { useCallback } from 'react'
import { useRouter } from '@hooks/useRouter'
import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'
import { ProductType, Steps } from '@views/Product/NewListening/types'

const useLogic = () => {
  const { createReviewProductOptionsPayload, modalType, onCloseModal } =
    useModal()
  const { history } = useRouter()
  const { brandId } = createReviewProductOptionsPayload || {}

  const handleCreateReview = useCallback(() => {
    onCloseModal()

    history.push(
      `/projects/${ProductType.REVIEW}/${brandId}/new/${Steps.LISTENING_CONFIG}`,
    )
  }, [brandId, history, onCloseModal])

  const handleCreateReviewBenchmark = useCallback(() => {
    onCloseModal()

    history.push(
      `/projects/${ProductType.REVIEW_BENCHMARK}/${brandId}/new/${Steps.LISTENING_CONFIG}`,
    )
  }, [brandId, history, onCloseModal])

  return {
    isOpen: modalType === ModalType.CREATE_REVIEW_PRODUCT_OPTIONS,
    onCloseModal,
    handleCreateReview,
    handleCreateReviewBenchmark,
  }
}

export default useLogic
