import styled from 'styled-components'
import TagsInputDefault from 'components/Inputs/TagsInput/TagsInput'

export const TagsInput = styled(TagsInputDefault)`
  .c-TagsInput__copy-btn {
    color: ${({ theme }) => theme.colours.gray[55]};
    font-weight: 400;
    flex-grow: 10;
    text-align: right;
    font-size: 12px;
  }

  .c-TagsInput__text {
    color: ${({ theme }) => theme.colours.gray[55]};
  }
  .c-TagsInput__error {
    color: var(--color-sentiment_negative);
    font-size: 12px;
    line-height: 1.5;
  }
  .c-TagsInput__input {
    border: 1px solid ${({ theme }) => theme.colours.gray[140]};

    &.is-focused {
      border: 1px solid ${({ theme }) => theme.colours.gray[5]};
    }
    &.is-error {
      border: 1px solid ${({ theme }) => theme.colours.red[30]};
    }
    &:not(.is-error):not(.is-focused):hover {
      border: 1px solid ${({ theme }) => theme.colours.gray[5]};
    }
  }
`
