import { useState } from 'react'
import { modalConstants } from './constants'

const useLogic = ({ disableBackdropClick, disableEscapeKeyDown, onClose }) => {
  const [container] = useState(
    document.getElementById(modalConstants.CONTAINER_ID),
  )

  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false
    }

    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return {
    container,
    handleClose,
  }
}

export default useLogic
