import { FC, memo } from 'react'
import type { Props } from '../../types'

const Alerts: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.89401 0C5.18897 0 3.03389 2.27646 3.03389 5.03699C3.03389 7.10415 2.52546 8.68427 2.02393 9.74143C1.77287 10.2706 1.52334 10.6691 1.33988 10.9316C1.24819 11.0628 1.17315 11.1598 1.12289 11.222C1.09777 11.2531 1.07886 11.2755 1.06719 11.289L0.997475 11.3879C0.993442 11.3946 0.987971 11.4026 0.981616 11.4118C0.929963 11.4869 0.819927 11.6468 0.949353 11.9421C1.04371 12.1573 1.25601 12.2962 1.49048 12.2962H14.2976C14.539 12.2962 14.7562 12.149 14.8461 11.9243C14.936 11.6998 14.8807 11.4432 14.7066 11.2759L14.7076 11.2768L14.6974 11.2665C14.6869 11.2556 14.6691 11.2369 14.6451 11.2102C14.5971 11.1568 14.5243 11.0715 14.4347 10.9533C14.2555 10.7168 14.0094 10.349 13.7609 9.84167C13.265 8.8296 12.7541 7.25192 12.7541 5.03699C12.7541 2.27646 10.5991 0 7.89401 0ZM2.63175 11.1111H13.1114C12.9789 10.8971 12.839 10.6484 12.6997 10.3641C12.1284 9.19789 11.5719 7.44223 11.5719 5.03699C11.5719 2.88831 9.9044 1.18508 7.89401 1.18508C5.88363 1.18508 4.21609 2.88831 4.21609 5.03699C4.21609 7.30474 3.65726 9.05795 3.09154 10.2504C2.93483 10.5807 2.77765 10.8679 2.63175 11.1111Z"
      fill="currentColor"
    />
    <path
      d="M7.18052 14.2803C7.02133 13.9946 6.66124 13.8923 6.37623 14.0519C6.09122 14.2115 5.98922 14.5725 6.14842 14.8582C6.50367 15.4957 7.16623 15.8999 7.89537 15.8999C8.62451 15.8999 9.28707 15.4957 9.64233 14.8582C9.80152 14.5725 9.69952 14.2115 9.41452 14.0519C9.12951 13.8923 8.76941 13.9946 8.61022 14.2803C8.45633 14.5565 8.18093 14.7148 7.89537 14.7148C7.60981 14.7148 7.33442 14.5565 7.18052 14.2803Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Alerts)
