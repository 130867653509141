import { FC, memo } from 'react'
import type { Props } from '../../types'

const Reports: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80017 8.85305L4.80017 11.11L4.09181 10.4988C3.84092 10.2823 3.46204 10.3102 3.24558 10.5611C3.02911 10.812 3.05702 11.1909 3.30792 11.4074L6.55325 14.2074C6.77562 14.3992 7.10419 14.4019 7.32967 14.2137L10.6843 11.4137C10.9387 11.2014 10.9728 10.823 10.7605 10.5686C10.5482 10.3142 10.1698 10.2801 9.91539 10.4925L9.20017 11.0894L9.20017 8.85305C9.20017 8.52168 8.93154 8.25305 8.60017 8.25305H5.40017C5.0688 8.25305 4.80017 8.52168 4.80017 8.85305ZM8.00017 9.45305V12.091L6.95163 12.9662L6.00017 12.1453L6.00017 9.45305H8.00017Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.899902 2.9999C0.899902 1.89032 1.73606 0.899902 2.8749 0.899902H8.48211C8.65054 0.899902 8.81121 0.970697 8.92488 1.09499L12.9427 5.48845C13.0438 5.59905 13.0999 5.74349 13.0999 5.89336V14.9999C13.0999 16.1095 12.2637 17.0999 11.1249 17.0999H2.8749C1.73606 17.0999 0.899902 16.1095 0.899902 14.9999V2.9999ZM2.8749 2.0999C2.49496 2.0999 2.0999 2.45263 2.0999 2.9999V14.9999C2.0999 15.5472 2.49496 15.8999 2.8749 15.8999H11.1249C11.5048 15.8999 11.8999 15.5472 11.8999 14.9999V6.51519H8.46035C8.12898 6.51519 7.86035 6.24656 7.86035 5.91519V2.0999H2.8749ZM9.06035 3.0213L11.1581 5.31519H9.06035V3.0213Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Reports)
