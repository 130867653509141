import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

// Hook to load the icon
export function useLazyIcon() {
  const [SVGIcon, setSVGIcon] = React.useState()
  const loadIcon = name => {
    import(
      /* webpackChunkName: "SmIcons" */
      /* webpackPreload: true */
      `./constants`
    ).then(iconsModule => {
      const iconNames = iconsModule.default
      const SvgContent = () => iconNames[name]

      setSVGIcon(SvgContent)
    })
  }

  return { SVGIcon, loadIcon }
}

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  active: PropTypes.bool,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
}

const defaultProps = {
  className: '',
  color: undefined,
}
const Icon = props => {
  const { SVGIcon, loadIcon } = useLazyIcon()
  const {
    name,
    className,
    color,
    width = 'inherit',
    height = 'inherit',
    active = false,
    activeColor,
    hoverColor,
  } = props

  React.useEffect(() => {
    loadIcon(name)
  }, [name, loadIcon])

  return (
    <IconWrapper
      className={className}
      name={name}
      width={width}
      height={height}
      color={color}
      active={active}
      hoverColor={hoverColor}
      activeColor={activeColor}
    >
      {SVGIcon && <SVGIcon />}
    </IconWrapper>
  )
}

Icon.propTypes = propTypes
Icon.defaultProps = defaultProps

const IconWrapper = styled.span`
  &:hover {
    & > svg {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
  & > svg {
    width: ${props => props.width} !important;
    height: ${props => props.height};
    color: ${({ active, activeColor, color }) =>
      active ? activeColor : color};
  }
`
export default Icon
