import { FC, memo } from 'react'
import type { Props } from '../../types'

const Dots: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.5 8.25C3.5 8.94036 2.94036 9.5 2.25 9.5C1.55964 9.5 1 8.94036 1 8.25C1 7.55964 1.55964 7 2.25 7C2.94036 7 3.5 7.55964 3.5 8.25Z"
      fill="currentColor"
    />
    <path
      d="M9 8.25C9 8.94036 8.44036 9.5 7.75 9.5C7.05964 9.5 6.5 8.94036 6.5 8.25C6.5 7.55964 7.05964 7 7.75 7C8.44036 7 9 7.55964 9 8.25Z"
      fill="currentColor"
    />
    <path
      d="M14.5 8.25C14.5 8.94036 13.9404 9.5 13.25 9.5C12.5596 9.5 12 8.94036 12 8.25C12 7.55964 12.5596 7 13.25 7C13.9404 7 14.5 7.55964 14.5 8.25Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Dots)
