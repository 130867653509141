import queryString from 'query-string'

import { getAPIUrl } from './utils/url'

const requestFactory = method => (uri, b, q) => {
  let query = q
  let headers
  let body

  // For get requests, the body becomes the query and we don't have a body
  if (method === 'GET') {
    query = b
  } else {
    headers = { 'Content-Type': 'application/json' }
    body = JSON.stringify(b)
  }

  let url = getAPIUrl(uri)
  if (query) {
    url += `?${queryString.stringify(query)}`
  }

  return fetch(url, {
    method,
    headers,
    body,
    credentials: 'include',
  })
}

const operations = {
  get: requestFactory('GET'),
  post: requestFactory('POST'),
  put: requestFactory('PUT'),
  delete: requestFactory('DELETE'),
}

export default operations
