export const SET_ACCOUNT_EVOLUTON_TYPE = 'SET_ACCOUNT_EVOLUTON_TYPE'
export const CLEAR_ACCOUNT_EVOLUTION_TYPE = 'CLEAR_ACCOUNT_EVOLUTION_TYPE'

export function setEvolutionType(type) {
  return { type: SET_ACCOUNT_EVOLUTON_TYPE, payload: { type } }
}

export function clearEvolutionType() {
  return { type: CLEAR_ACCOUNT_EVOLUTION_TYPE, payload: {} }
}
