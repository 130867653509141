import {
  overSome,
  isEmpty,
  reverse,
  filter,
  flow,
  map,
  sortBy,
  getOr,
} from 'lodash/fp'

const filterCustomCategories = filter(c => !c.isMarket)
const filterMarketCategories = filter(c => c.isMarket)

const trxToKeywordData = map(c => ({
  id: c.id,
  forceHover: true,
  data: {
    ...extractBooleanKeywords(c.keywords),
    errors: c.errors,
    ...c,
  },
}))

const setSourceTag = map(cat => ({
  ...cat,
  sourceArray: cat.source ? [cat.source] : [],
}))

const parseToCustomCategories = flow(
  filterCustomCategories,
  sortBy(c => c.id),
  trxToKeywordData,
  reverse,
)

const parseToMarketCategories = flow(
  filterMarketCategories,
  setSourceTag,
  sortBy(c => c.name.toLowerCase()),
  trxToKeywordData,
)

const extractBooleanKeywords = (keywords = []) => {
  // classify and group types of keywords
  const booleanKeywords = {
    orKeywords: [],
    andKeywords: [],
    notKeywords: [],
  }

  keywords.forEach(keyword => {
    if (!keyword.operator || !keyword.words) {
      return
    }

    // Trick to handle 'or' & 'and' in the FE sepparately
    if (
      keyword.operator.toLowerCase() === 'or' &&
      booleanKeywords.orKeywords.length > 0
    ) {
      booleanKeywords.andKeywords = keyword.words
    } else {
      booleanKeywords[`${keyword.operator.toLowerCase()}Keywords`] =
        keyword.words
    }
  })

  return booleanKeywords
}

const extractTags = data => {
  const keywords = []

  if (!isEmpty(getOr([], 'orKeywords', data))) {
    keywords.push({
      operator: 'OR',
      words: data.orKeywords,
    })
  }

  if (!isEmpty(getOr([], 'andKeywords', data))) {
    keywords.push({
      operator: 'OR',
      words: data.andKeywords,
    })
  }

  if (!isEmpty(getOr([], 'notKeywords', data))) {
    keywords.push({
      operator: 'NOT',
      words: data.notKeywords,
    })
  }

  return keywords
}

const manualCategoriesCount = cats => {
  return cats && cats.filter(c => !c.isMarket).length
}

const hasOrKeywords = data => getOr([], 'data.orKeywords', data).length > 0
const hasAndKeywords = data => getOr([], 'data.andKeywords', data).length > 0
const hasNotKeywords = data => getOr([], 'data.notKeywords', data).length > 0

const shouldShowResetIcon = overSome([
  hasOrKeywords,
  hasAndKeywords,
  hasNotKeywords,
])

export {
  manualCategoriesCount,
  shouldShowResetIcon,
  extractTags,
  extractBooleanKeywords,
  parseToCustomCategories,
  parseToMarketCategories,
  filterCustomCategories,
  filterMarketCategories,
}
