import { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import type { Props } from './types'
import * as commomStyles from '../styles'
import * as componentStyles from './styles'

const S = {
  ...commomStyles,
  ...componentStyles,
}

const TagsInputController: FC<Props> = ({
  control,
  hasError,
  name,
  onChange,
  transformValues,
  ...rest
}) => {
  return (
    <S.Container>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <S.TagsInput
              id={name}
              error={error?.message || hasError}
              {...rest}
              {...field}
              tags={field.value}
              onChange={selected => {
                const values = transformValues
                  ? transformValues(selected)
                  : selected

                field.onChange(values)

                if (onChange) {
                  onChange()
                }
              }}
            />
          </>
        )}
      />
    </S.Container>
  )
}

export default memo(TagsInputController)
