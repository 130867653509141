import { FC, memo } from 'react'
import type { Props } from '../types'

const Download: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 12"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.375 3.75h-2.5V0h-3.75v3.75h-2.5L5 8.125 9.375 3.75zM0 10.09v1.428h10v-1.429H0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default memo(Download)
