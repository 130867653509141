import { IRemoveMsItem } from '../reducers/magicSearch.reducer'
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createAction,
} from '@reduxjs/toolkit'
import { MentionsFilters } from '@views/Config/View/View.types'
import type { ViewFiltersInput } from 'apollo/generated/globalTypes'

export interface IMagicSearchActions {
  /** Holds current values applied to magic search */
  storeMagicSearch: ActionCreatorWithPayload<MentionsFilters, string>
  /** Removes individual items from magic search */
  removeItem: ActionCreatorWithPayload<IRemoveMsItem, string>
  /** Sets magicSearch with just filters applied in view */
  restore: ActionCreatorWithPayload<any, string>
  /** Special action performed from No Filters view, considering query filters*/
  restoreNullView: ActionCreatorWithPayload<any, string>
  /** Empty all the properties in magicSearch */
  clearAll: ActionCreatorWithoutPayload<string>
  /** Cleans ActiveFilters and filters in one single step */
  clearMagicSearch: ActionCreatorWithoutPayload<string>
  /** Holds filters in view + Magic search */
  storeActiveFilters: ActionCreatorWithPayload<ViewFiltersInput, string>
  /** Cleans active filters */
  clearActiveFilters: ActionCreatorWithoutPayload<string>
  /** Cleans active filters */
  changesNotSaved: ActionCreatorWithPayload<boolean | undefined, string>
}

export const magicSearchActions: IMagicSearchActions = {
  storeMagicSearch: createAction<MentionsFilters>(`MS/ADD`),
  removeItem: createAction<IRemoveMsItem>(`MS/REMOVE_ITEM`),
  restore: createAction(`MS_RESTORE`),
  restoreNullView: createAction(`MS_RESTORE_NULL-VIEW`),
  clearMagicSearch: createAction<undefined>(`MS_CLEAR_RAW`),
  clearAll: createAction<undefined>(`MS_CLEAR_ALL`),
  storeActiveFilters: createAction<MentionsFilters>(`MS_STORE_ACTIVE_FILTERS`),
  clearActiveFilters: createAction<undefined>(`MS_CLEAR_ACTIVE_FILTERS`),
  changesNotSaved: createAction<boolean | undefined>(`MS/CHANGES_NOT_SAVED`),
}
