import socketIO from 'socket.io-client'
import socketWildcard from 'socketio-wildcard'

import { getAPIUrl } from './utils/url'

const patch = socketWildcard(socketIO.Manager)
let socket

export default function getSocketConnection() {
  if (!socket || socket.disconnected) {
    socket = socketIO.connect(getAPIUrl(), {
      transports: ['websocket'],
    })

    socket.on('connect_error', () => {
      console.log('Socket connection failed')
      window.localStorage.setItem('WS_connect_error', 'websocket-error')
      if (socket) {
        socket.close()
        socket = undefined
      }
    })

    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      //console-log-checker-ignore
      console.log('Socket connection established')

      // The socket should always exists at this point but better to prevent
      // than break in front of the user because it is happening
      if (socket) {
        window.localStorage.removeItem('WS_connect_error')
        socket.on('disconnect', () => {
          socket.close()
          socket = undefined
        })
      }
    })

    patch(socket)
  }

  return socket
}
