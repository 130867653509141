/* Apollo */
import {
  ApolloClient,
  InMemoryCache,
  ServerError,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { getAPIUrl } from '@utils/url'
import { store } from 'store/store'
import { logout } from 'store/actions/sessionDataActions'

const cleanAndLogout = () => {
  store.dispatch(logout())
  window.localStorage.clear()
  window.location.href = '/login'
}

const logoutLink = onError(({ networkError }) => {
  if (networkError && (networkError as ServerError)?.statusCode === 401) {
    cleanAndLogout()
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes('UNAUTHORIZED')) {
        cleanAndLogout()
      }
    })
  }
})

const link = logoutLink.concat(
  createHttpLink({
    uri: getAPIUrl('/graphql'),
    credentials: 'include',
  }),
)

const cache = new InMemoryCache({
  possibleTypes: {
    Mention: [
      'TwitterMention',
      'FacebookMention',
      'InstagramMention',
      'YoutubeMention',
      'MediaMention',
      'ReviewMention',
      'TiktokMention',
      'LinkedinMention',
    ],
    MentionGroup: [
      'FeedGroup',
      'CategoryGroup',
      'AspectGroup',
      'ThemeGroup',
      'SentimentGroup',
      'SourceGroup',
      'TimeGroup',
      'TypeGroup',
    ],
  },
})

const client = new ApolloClient({
  link: from([errorLink, link]),
  cache,
})

export default client
