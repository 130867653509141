import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components/macro'
import Color from 'color'
import Icon from 'components/Icon/Icon'

import './Tag.css'

const Operator = styled.div`
  position: relative;
  margin-right: -5px;
  left: -10px;
  padding: 0 5px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: ${props => Color(props.color).darken(0.1).rgb().string()};
`

const propTypes = {
  // Content of the tag
  children: PropTypes.node.isRequired,

  title: PropTypes.string,

  // Background color of the tag
  color: PropTypes.string,

  // Compact layout
  compact: PropTypes.bool,

  // Adds rounded corners
  roundedCorners: PropTypes.bool,

  // Renders a "x" button on the tag
  showButton: PropTypes.bool,

  // Renders an icon on the left
  icon: PropTypes.string,

  // Fired when clicking the button on the tag
  onClickButton: PropTypes.func,

  // Used to show the queries operators
  operator: PropTypes.string,
}

const defaultProps = {
  color: '#4d6680',
  showButton: false,
  compact: false,
  icon: '',
  roundedCorners: true,
  operator: null,
  onClickButton() {},
  title: '',
}

function Tag(props) {
  const {
    children,
    icon,
    color,
    compact,
    showButton,
    onClickButton,
    roundedCorners,
    operator,
    title,
    separator,
  } = props

  const className = classNames('c-Tag', {
    'is-compact': compact,
    'is-separator': separator,
    'has-rounded-corners': roundedCorners,
    'has-button': showButton,
    'has-icon': icon,
  })

  let button
  if (showButton) {
    button = (
      <button
        data-cy="tag-remove-button"
        tabIndex="-1"
        className="c-Tag__btn"
        onClick={onClickButton}
      >
        x
      </button>
    )
  }

  let iconBlock
  if (icon) {
    iconBlock = (
      <div className="c-Tag__icon">
        <Icon name={icon} />
      </div>
    )
  }

  let $operator
  if (operator) {
    $operator = <Operator color={color}>{operator}</Operator>
  }

  const style = {
    backgroundColor: color,
  }

  return (
    <div
      title={title}
      className={className}
      style={style}
      data-cy="tag-element"
    >
      {iconBlock}
      {$operator}
      {children}
      {button}
    </div>
  )
}

Tag.propTypes = propTypes
Tag.defaultProps = defaultProps

export default Tag
