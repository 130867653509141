import { round } from 'lodash/fp'

const roundAt = round.convert({ fixed: false })

/**
 * Returns true when the passed value is a finite number
 */
export function isNumber(val) {
  const num = Number(val)
  return !Number.isNaN(num) && Number.isFinite(num)
}

/**
 * Formats a number:
 *
 * 0.0023   => 0,002
 * 13.34    => 13,3
 * 20300    => 20.300
 * 20300    => 20,3k (if shorten is true)
 *
 * @param {number} number
 * @param {Object} [options]
 *
 * @param {boolean} [options.shorten]  - Retuns a shrotened number (1.2k)
 * @param {number}  [options.decimals] - Number of decimals to include. When not
 *                                       specified is picked automatically
 */
export function formatNumber(number, options = {}) {
  // First ensure that we actually have a number
  const n = Number(number)
  if (Number.isNaN(n)) {
    // eslint-disable-next-line
    if (typeof number === 'string') {
      return number
    } else {
      console.warn('formatNumber was called with a non-number:', n)
      return n
    }
  }

  const opt = Object.assign(
    {
      shorten: false,
      decimals: undefined,
    },
    options,
  )
  // Shorten the number
  if (n >= 1000 && opt.shorten) {
    return ['k', 'M', 'B'].reduceRight((acc, key, index) => {
      // When the accumulator is astring, we are done
      if (typeof acc === 'string') return acc

      const target = 1000 ** (index + 1)

      // The number is too small for this key, move to alower one
      if (acc < target) return acc

      // Nailed it
      const res = acc / target
      return `${roundAt(res, 1)}${key}`
    }, n)
  }

  let { decimals } = opt

  // Deice the decimals based on the number when not specified
  if (!decimals) {
    if (n < 1) {
      decimals = 3
    } else if (n < 10) {
      decimals = 1
    } else {
      decimals = 0
    }
  }

  // Format using the standard library
  return Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(n)
}

/**
 * @deprecated Use fromatNumber instead
 */
export function shortenLargeNumber(value) {
  return formatNumber(value, { shorten: true })
}

/**
 * @deprecated Use formatNumber instead
 */
export function formatThousand(value, isPlusIconDisplayed) {
  const res = formatNumber(value)

  if (isPlusIconDisplayed) return `+${res}`
  return res
}

/**
 * Trims a text and adds "..." at the end of the string
 *
 * @param {string} text - The text to be trimmed
 * @param {number} length - Number of character where the trim starts on
 *
 * @return {string} Trimmed text with "..." at the end
 */
export function shortenText(text, maxLength) {
  let trimmedText = text

  if (trimmedText && trimmedText.length > maxLength) {
    trimmedText = `${trimmedText.substr(0, maxLength - 1)}\u2026`
  }

  return trimmedText
}

/**
 * Calculates the percentage of n agains total
 *
 * @param {numner} n          - Value
 * @param {numner} total      - Total amount (100%)
 * @param {number} [digits=1] - Significant digits to include in the result
 *
 * @return {number} Percentage
 */
export function getPercentage(n, tot, digits = 0) {
  if (tot === 0) return 0

  const perc = (n * 100) / tot
  return roundAt(perc, digits)
}

/**
 * Converts a group list array (GraphQL response) to a object with keys as group identifiers
 *
 * @param {Group[]} genders - Group array
 * @return {object}
 */
export function toGroupObject(groups, field = 'key') {
  return groups.reduce(
    (acc, group) => ({
      ...acc,
      [group[field]]: group.count,
    }),
    {},
  )
}
