import { createGlobalStyle } from 'styled-components'

/*
 * This file should be used to add global declarations that depend on the
 * styled-components theme. If a declaration does not depend on the theme, it
 * should be moved to `src/index.css`.
 */
export default createGlobalStyle`
  html {
  --color-cloudy: #F2F4F7;
  --color-gray_fog: #f7f8fa;
  --barchart-margin: 7px 0 20px 33px;
  --color-background_color: --color-cloudy;
  --color-blueocean: #3f75cc;
  --color-dark_gray: #4A637C;
  --color-deep_blue: #1F2B59;
  --color-deep_gray: #192541;
  --color-error_dark: #C32927;
  --color-error: #E6312E;
  --color-gray: #7A8D9F;
  --color-light_gray: #A4AFBB;
  --color-mermaid: #10D4AB;
  --color-mermaid-disabled: #88ead6;
  --color-royal_blue: #0A569F;
  --color-sentiment_negative: #E6312E;
  --color-sentiment_objective: #FFAE00;
  --color-sentiment_positive: #68BF39;
  --color-sky_blue: #4C87D9;
  --color-smoky: #CCD1D8;
  --color-unknown: var(---color-light_gray);
  --color-violet: #8b6ec8;
  --fade-in: cubic-bezier(0, 0, 0.2, 1);
  --fade-out: cubic-bezier(0.4, 0, 1, 1);
  --line-gray:#e6e8ec;
  --linechart-margin: 7px 20px 20px 45px;
  --color-gray_plain: #7e8c9c;
  --color-link: #4985dc;
}
`
