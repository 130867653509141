import { FC, memo } from 'react'
import type { Props } from '../../types'

const Integrations: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0608 2.74928C11.0772 1.2324 8.30327 1.36658 6.8428 3.20774C6.63356 3.47152 6.24615 3.51882 5.97749 3.31337C5.70883 3.10793 5.66067 2.72755 5.86991 2.46376C7.8308 -0.00826782 11.4093 -0.0483672 13.8186 1.79404C16.2837 3.67912 16.7256 7.16939 14.8057 9.58977C12.8858 12.0101 9.33102 12.4441 6.86591 10.559C5.82376 9.76207 5.14253 8.89309 4.85823 7.63756C4.78432 7.31118 4.99388 6.98777 5.32629 6.91521C5.6587 6.84265 5.98809 7.0484 6.06199 7.37478C6.26835 8.28608 6.74286 8.93023 7.62363 9.60376C9.55143 11.078 12.3314 10.7386 13.8328 8.84579C15.3343 6.95298 14.9886 4.22347 13.0608 2.74928Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.87838 13.1714C4.8372 14.7502 7.54614 14.6672 9.05478 12.8627C9.27084 12.6043 9.65936 12.5668 9.92256 12.7789C10.1858 12.9911 10.224 13.3725 10.0079 13.631C7.98756 16.0475 4.47866 16.0277 2.09595 14.1073C-0.333586 12.1491 -0.709207 8.63911 1.27498 6.26586C3.25916 3.89261 6.83866 3.57047 9.2682 5.52867C10.3103 6.36857 10.8713 7.1895 11.1269 8.44087C11.1939 8.76869 10.9775 9.08775 10.6437 9.1535C10.3098 9.21926 9.98483 9.00681 9.91786 8.679C9.72675 7.74355 9.34414 7.15637 8.48577 6.46452C6.57377 4.92345 3.77293 5.18637 2.22812 7.03409C0.683305 8.88182 0.966373 11.6304 2.87838 13.1714Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Integrations)
