import { set, flow } from 'lodash/fp'

import {
  ONBOARDING_INIT,
  ONBOARDING_END,
  ONBOARDING_SET_STEP,
} from '../actions/onboardingActions'

const initialState = {
  // Step 0 is the introduction.
  step: 0,

  // Total number of steps
  total: 0,

  // Copies of the slide
  title: '',
  body: '',

  // Cotnains the ID of the element to focus
  focusedElement: undefined,

  // When true the oboarding screens are visible
  visible: false,
}

export default function onboarding(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ONBOARDING_INIT:
      return flow(
        set('total', payload.total),
        set('visible', true),
        set('step', 0),
        set('title', payload.title),
        set('body', payload.body),
        set('focusedElement', payload.focusedElement),
      )(initialState)

    case ONBOARDING_SET_STEP:
      return flow(
        set('step', payload.step),
        set('title', payload.title),
        set('body', payload.body),
        set('focusedElement', payload.focusedElement),
      )(state)

    case ONBOARDING_END:
      return set('visible', false, state)

    default:
      return state
  }
}
