import { FC } from 'react'
import * as S from './styles'
import type { Props } from './types'

const Button: FC<Props> = ({
  actived,
  children,
  className,
  disabled,
  loading,
  fullWidth,
  changeStartIconHoverColor,
  disableRipple,
  isDanger = false,
  onClick,
  startIcon,
  endIcon,
  type,
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  round,
  id,
}) => {
  return (
    <S.Button
      $hasIcon={!!startIcon}
      $color={color}
      $variant={variant}
      $isDanger={isDanger}
      $size={size}
      $round={round}
      $changeStartIconHoverColor={changeStartIconHoverColor}
      $actived={actived}
      className={className}
      disabled={disabled || loading}
      disableElevation={true}
      disableRipple={disableRipple}
      focusRipple={true}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={loading ? <S.Spinner color="secondary" /> : startIcon}
      endIcon={endIcon}
      type={type}
      variant={variant}
      size={size}
      id={id}
    >
      {children}
    </S.Button>
  )
}

export default Button
