import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const withAuthRoute = Component => {
  const WithAuthRoute = ({ isLoggedIn, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => {
          if (isLoggedIn) {
            return <Component {...props} />
          }
          return <Redirect to="/login" />
        }}
      ></Route>
    )
  }

  withAuthRoute.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
  }

  const withAuthRouteName =
    Component.displayName || Component.name || 'Component'
  WithAuthRoute.displayName = `withAuthRoute(${withAuthRouteName})`

  return WithAuthRoute
}
export default withAuthRoute
