import { FC, memo } from 'react'
import type { Props } from '../../types'

const ChevronUp: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5957 5.41211L0.595703 5.41211L5.0957 0.912109L9.5957 5.41211Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ChevronUp)
