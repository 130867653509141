import { mapKeys, lowerCase, toUpper, isArray } from 'lodash/fp'
import { Sentiment } from 'apollo/generated/globalTypes'

/**
 * Converts form a SentimentGroup array (GraphQL response) to a Sentiment object
 *
 * @param {SentimentGroup[]} sentiments - SentimentGroup array
 * @return {object}
 */
export function toSentimentObject(groups) {
  return groups.reduce(
    (acc, group) => ({
      ...acc,
      [group.sentiment]: group.count,
    }),
    {},
  )
}

/**
 * Get the available sentiments
 *
 * @return {string[]} Array of sentiments
 */
export function getSentiments() {
  return ['NEGATIVE', 'OBJECTIVE', 'POSITIVE', 'UNKNOWN']
}

/**
 * Get the sentiments as percentages. Returns a normalized object with lowecased sentiment keys
 * unless `uppercaseKeys` is set to true
 *
 * @param {Object} sentiments             - Object of sentiments
 * @param {number} total                  - Total mentions
 * @param {bool}   [uppercaseKeys=false]  - Return the sentiment keys in uppercase
 */
export function getPercentages(_sentiments, total, uppercaseKeys) {
  let sentiments
  if (isArray(_sentiments)) {
    sentiments = toSentimentObject(_sentiments)
  } else {
    sentiments = _sentiments || {}
  }

  const result = {}

  Object.keys(sentiments).forEach(k => {
    const mentions = sentiments[k] || 0
    const key = uppercaseKeys ? k.toUpperCase() : k.toLowerCase()

    let val = 0
    if (total > 0) {
      val = Math.round((mentions * 100) / total)
    }

    result[key] = val
  })

  return result
}

/**
 * Get the sentiment ratio as a number
 *
 * @param {Object} sentiments - Object of sentiments
 * @return {Number} sentiment
 */
export function getSentimentRatio(rawSentiment) {
  let sentiment = rawSentiment
  if (isArray(sentiment)) {
    sentiment = toSentimentObject(sentiment)
  }

  // Uppercase the sentiment keys
  sentiment = mapKeys(toUpper, sentiment)

  let ratio = 0
  if (sentiment && sentiment.NEGATIVE && sentiment.POSITIVE) {
    ratio = sentiment.POSITIVE / sentiment.NEGATIVE
  } else if (sentiment && sentiment.POSITIVE && !sentiment.NEGATIVE) {
    ratio = sentiment.POSITIVE
  } else if (sentiment && sentiment.NEGATIVE && !sentiment.POSITIVE) {
    ratio = 0
  } else if (sentiment && !sentiment.NEGATIVE && !sentiment.POSITIVE) {
    ratio = 0
  }

  ratio = Number.parseFloat(ratio)

  // Returns 0 instead of 0.000
  if (!ratio) {
    return 0
  }

  return ratio
}

/**
 * Get the sentiment ratio as a number from the sentiment aggregation group
 *
 * @param {Array} sentimentList - list of sentiments as the group returns them
 * @return {Number} sentiment
 */
export function getGroupSentimentRatio(sentimentList = []) {
  const sentiments = toSentimentObject(sentimentList)
  return getSentimentRatio(sentiments)
}

/**
 * Get the sentiment of a tag after analyzing the number of mentions
 * from each sentiment type
 *
 * @param {object} rawSentiment - Sentiment object
 * @return {string} Sentiment: 'positive', 'negative' or 'objective'
 */
export function getTagSentiment(rawSentiment) {
  // Lowercase the sentiment keys
  const sentiment = mapKeys(lowerCase, rawSentiment)
  const { negative = 0, positive = 0, objective = 0 } = sentiment
  const negAnPos = negative + positive

  // Returns a sentiment depending the number of ratio
  const getTagSentimentByRatio = () => {
    if (!positive) {
      return Sentiment.NEGATIVE
    }

    if (!negative) {
      return Sentiment.POSITIVE
    }

    const ratio = positive / negative
    if (ratio >= 0.9 && ratio <= 1.1) {
      return Sentiment.OBJECTIVE
    }

    if (ratio > 1.1) {
      return Sentiment.POSITIVE
    }

    return Sentiment.NEGATIVE
  }

  // Step 0 (objective): no negative or positive mentions
  if (!negAnPos) {
    return Sentiment.OBJECTIVE
  }

  // Step 1 (ratio): not enough objectives mentions
  if (objective <= negAnPos * 20) {
    return getTagSentimentByRatio()
  }

  // Step 2 (objective): not enough positive and negative mentions
  if (negAnPos <= 20) {
    return Sentiment.OBJECTIVE
  }

  // Step 3 (objective): way more objective mentions than negative and positive
  if (objective >= negAnPos * 30) {
    return Sentiment.OBJECTIVE
  }

  return getTagSentimentByRatio()
}
