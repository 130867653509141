import { getOr, set, curry, always } from 'lodash/fp'

const lens = (getter, setter) => {
  return transformFn => {
    return target => {
      const value = getter(target)

      return transformFn(value)(setter)(target)
    }
  }
}

// Lens, focused on specified object path, with fallback-value support
export const lensPathOr = curry((defaultValue, path) => {
  return lens(getOr(defaultValue, path), set(path))
})

export const lensPath = lensPathOr(undefined)

// Returns value at focus-point of given lens
export const view = curry((lensInstance, target) => {
  return lensInstance(value => () => always(value))(target)
})

// Assigns new value at focus-point and returns modified target
export const assoc = curry((lensInstance, value, target) => {
  return lensInstance(() => setter => setter(value))(target)
})

export const over = curry((lensInstance, fn, target) => {
  return lensInstance(value => setter => setter(fn(value)))(target)
})
