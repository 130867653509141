import { FC, memo } from 'react'
import type { Props } from '../../types'

const Review: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.3662 12.8127L19.6803 9.08526C20.2906 8.55592 19.9749 7.51929 19.1752 7.47518L13.6196 7.12229C13.2829 7.10023 12.9883 6.87967 12.862 6.54883L10.8207 1.07897C10.5261 0.307011 9.49494 0.307011 9.20033 1.07897L7.15905 6.52678C7.03278 6.85761 6.73817 7.07817 6.40146 7.10023L0.824773 7.45312C0.0250975 7.49724 -0.290564 8.53386 0.319715 9.06321L4.63376 12.7686C4.88628 12.9892 5.01255 13.3641 4.92837 13.6949L3.51842 19.3633C3.32902 20.1573 4.14974 20.7969 4.82315 20.3558L9.51599 17.2018C9.8106 17.0033 10.1684 17.0033 10.4419 17.2018L15.1558 20.3558C15.8292 20.7969 16.6499 20.1573 16.4605 19.3633L15.0506 13.717C14.9875 13.3862 15.0927 13.0333 15.3662 12.8127Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Review)
