import { FC, memo } from 'react'
import type { Props } from '../../types'

const Google: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.06298 5.99214C2.70176 2.73672 6.06794 0.5 9.96565 0.5C12.6563 0.5 14.9042 1.4855 16.6315 3.09107L13.819 5.83718C13.0992 5.16168 11.8369 4.35336 9.96565 4.35336C7.33031 4.35336 5.09359 6.09183 4.28519 8.49466C4.08588 9.11473 3.96412 9.77908 3.96412 10.4656C3.96412 11.1521 4.08588 11.8166 4.29626 12.4366C5.09359 14.8395 7.33031 16.5779 9.96565 16.5779C11.4494 16.5779 12.5789 16.1682 13.4204 15.5813C14.7492 14.6512 15.3471 13.2671 15.4578 12.348H9.96565V8.64969H19.3223C19.4662 9.26977 19.5327 9.86771 19.5327 10.6871C19.5327 13.7211 18.4475 16.2789 16.5651 18.0174C14.9152 19.5454 12.6563 20.4313 9.96565 20.4313C6.06794 20.4313 2.70176 18.1945 1.06298 14.9391C0.387557 13.5882 0 12.0712 0 10.4656C0 8.86008 0.387557 7.34305 1.06298 5.99214Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Google)
