import { FC, memo } from 'react'
import type { Props } from '../../types'

const KeyConcepts: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70812 0.562306C4.87353 0.872401 4.75624 1.25787 4.44615 1.42328C2.23845 2.60091 0.976272 5.04999 1.33254 7.56648C1.71085 10.2387 3.82064 12.3283 6.48831 12.6766C9.04301 13.0102 11.5111 11.6688 12.6362 9.37159C12.7908 9.05596 13.1719 8.9254 13.4876 9.07999C13.8032 9.23458 13.9338 9.61576 13.7792 9.93139C12.4156 12.7155 9.42349 14.3434 6.32351 13.9386C3.08691 13.516 0.530607 10.9816 0.0723778 7.74488C-0.359152 4.69675 1.16929 1.72875 3.84714 0.300332C4.15724 0.134922 4.54271 0.252212 4.70812 0.562306Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.55004 0.186386C6.66938 0.0670451 6.83124 0 7.00001 0C8.85652 0 10.637 0.737496 11.9497 2.05025C13.2625 3.363 14 5.14347 14 6.99998C14 7.35143 13.7151 7.63634 13.3636 7.63634H7.00001C6.64856 7.63634 6.36365 7.35143 6.36365 6.99998V0.636362C6.36365 0.467588 6.4307 0.305727 6.55004 0.186386ZM7.63638 1.30817V6.36362H12.6918C12.5482 5.07879 11.9728 3.8732 11.0498 2.9502C10.1268 2.0272 8.9212 1.45178 7.63638 1.30817Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(KeyConcepts)
