import { FC, memo } from 'react'
import type { Props } from '../types'

const Analysis: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.49811 1.37734C4.49811 0.63176 5.10252 0.0273438 5.84811 0.0273438H10.0719C10.8175 0.0273438 11.4219 0.631759 11.4219 1.37734V1.39954H12.2529C13.3985 1.39954 14.2839 2.36131 14.2839 3.49308V13.9479C14.2839 15.0797 13.3985 16.0414 12.2529 16.0414H3.66718C2.52157 16.0414 1.63623 15.0797 1.63623 13.9479V3.49308C1.63623 2.36131 2.52157 1.39954 3.66718 1.39954H4.49811V1.37734ZM5.69811 2.00551C5.69813 2.00352 5.69814 2.00153 5.69814 1.99954C5.69814 1.99754 5.69813 1.99555 5.69811 1.99357V1.37734C5.69811 1.2945 5.76527 1.22734 5.84811 1.22734H10.0719C10.1548 1.22734 10.2219 1.2945 10.2219 1.37734V2.7228C10.2219 2.80564 10.1548 2.8728 10.0719 2.8728H5.84811C5.76527 2.8728 5.69811 2.80565 5.69811 2.7228V2.00551ZM4.49811 2.59954H3.66718C3.23221 2.59954 2.83623 2.97512 2.83623 3.49308V13.9479C2.83623 14.4658 3.23221 14.8414 3.66718 14.8414H12.2529C12.6879 14.8414 13.0839 14.4658 13.0839 13.9479V3.49308C13.0839 2.97512 12.6879 2.59954 12.2529 2.59954H11.4219V2.7228C11.4219 3.46839 10.8175 4.0728 10.0719 4.0728H5.84811C5.10252 4.0728 4.49811 3.46839 4.49811 2.7228V2.59954ZM6.95971 5.39954C7.22758 5.39954 7.463 5.57709 7.53661 5.83464L9.02611 11.0459L9.48242 9.44945C9.55603 9.1919 9.79145 9.01434 10.0593 9.01434H11.4369C11.7683 9.01434 12.0369 9.28297 12.0369 9.61434C12.0369 9.94571 11.7683 10.2143 11.4369 10.2143H10.5118L9.60301 13.394C9.5294 13.6516 9.29398 13.8292 9.02611 13.8292C8.75825 13.8292 8.52283 13.6516 8.44922 13.394L6.95971 8.18279L6.50341 9.77923C6.4298 10.0368 6.19438 10.2143 5.92651 10.2143H4.54891C4.21754 10.2143 3.94891 9.94571 3.94891 9.61434C3.94891 9.28297 4.21754 9.01434 4.54891 9.01434H5.47398L6.38282 5.83464C6.45643 5.57709 6.69185 5.39954 6.95971 5.39954Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Analysis)
