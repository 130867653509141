import {
  allPass,
  anyPass,
  at,
  complement,
  cond,
  curry,
  defaultTo,
  find,
  flow,
  getOr,
  head,
  identity,
  intersection,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNull,
  isString,
  isUndefined,
  map,
  omitBy,
  overEvery,
  overSome,
  reject,
  split,
  trim,
  T,
} from 'lodash/fp'
import * as format from './format'
import * as sentiment from './sentiment'
import * as sources from './sources'
import * as url from './url'
import { isNumber } from './format'

/**
 * Returns the type of the current maps feed. Used to display lists of users when on a community.
 *
 * @return {string} FeedType
 */
// FIXME: Remove this when the router is updated and replace it with the router props
export function getMapsFeedType() {
  if (window.location.href.indexOf('maps') === -1) {
    return ''
  }

  if (window.location.href.indexOf('territory') !== -1) {
    return 'territory'
  }

  return window.location.href.match(/maps([^/])*\/listen\/communities\//)
    ? 'communities'
    : 'topics'
}

/**
 * Deperecated methods that are aliased
 */

export function shortenLargeNumber() {
  return format.shortenLargeNumber.apply(this, arguments)
}

export function shortenText() {
  return format.shortenText.apply(this, arguments)
}

export function composePath() {
  return url.composePath.apply(this, arguments)
}

export function getSentiments() {
  return sentiment.getSentiments.apply(this, arguments)
}

export function generateSlug() {
  return url.generateSlug.apply(this, arguments)
}

export function encodeQueryObject() {
  return url.encodeQueryObject.apply(this, arguments)
}

export function parseQueryObject() {
  return url.parseQueryObject.apply(this, arguments)
}

export function getGlobalSources() {
  return sources.getGlobalSources.apply(this, arguments)
}

// isArrayContainsArray :: Array -> Array -> Boolean
export const isArrayContainsArray = curry((filter, data) =>
  flow(intersection(filter), i => i.length > 0)(defaultTo([], data)),
)

export const isEmptyArray = overEvery([isEmpty, isArray])

export const isStringArray = allPass([isArray, flow([head, isString])])

export const isNullOrUndefined = anyPass([isNull, isUndefined])

export const sanitizeEmailArray = flow(split(','), reject(isEmpty), map(trim))

export const isDevelopmentEnv = process.env.REACT_APP_ENV === 'development'

// getByName :: [String] => [Obj]
export const getByName = names => at([names])

export const notEmpty = complement(isEmpty)

/**
 * Checks if key contains an Array with values
 * @param {String} key
 * @returns true when array is not empty
 */
export const collectionHasValues = key => flow(getOr([], key), notEmpty)

/**
 * Looks for an array in a given path
 * @param {String} id
 * @param {String} path
 * @returns
 */
export const getCollectionInPathById = (id, path) =>
  flow(getOr([], path), find({ id }))

/**
 * Looks for collection in object by ID, Path, Key
 * @param {String} id
 * @param {String} path
 * @param {String} key
 * @returns true when collection has items
 */
export const keyHasItems = (id, path, key) =>
  flow(getCollectionInPathById(id, path), collectionHasValues(key))

export const isCustomEmpty = input =>
  isNull(input) ||
  isNil(input) ||
  (typeof input === 'number' && isNaN(input)) ||
  (typeof input === 'object' && Object.keys(input).length === 0) ||
  (typeof input === 'string' && input.trim().length === 0)

export const notCustomEmpty = complement(isCustomEmpty)

/**
 *  return true if input type is boolean or number or string
 */
export const isPrimitive = overSome([isBoolean, isNumber, isString])
/**
 *  A ->  Boolean | A
 */
export const discardNonPrimitiveEmptyValues = cond([
  [isPrimitive, identity],
  [T, omitBy(isCustomEmpty)],
])
