import useModal from 'apollo/hooks/useModal'
import { ModalType } from 'apollo/reactive/modal'

const useLogic = () => {
  const { connectIntegrationPayload, modalType, onCloseModal } = useModal()

  const { title, desc, submit: handleSubmit } = connectIntegrationPayload || {}

  return {
    title,
    desc,
    isOpen: modalType === ModalType.CONNECT_INTEGRATION,
    handleSubmit,
    onCloseModal,
  }
}

export default useLogic
