import styled from 'styled-components/macro'

export const Container = styled.div`
  text-align: center;
`

export const Title = styled.h1`
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1em;
  color: ${({ theme }) => theme.colours.gray[190]};
`

export const Message = styled.div`
  padding: 1.2em 0 1em;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.colours.gray[210]};
  & a {
    color: inherit;
    text-decoration: underline;
  }
`
