import { useCallback } from 'react'
import useModal from 'apollo/hooks/useModal'
import useSnackbar from 'apollo/hooks/useSnackbar'
import { ModalType } from 'apollo/reactive/modal'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { trackEvent } from '@utils/metrics'
import useMentionActions from 'apollo/hooks/mention/useMentionActions'
import { initialValues, ShareMentionValidationSchema } from './constants'

const useLogic = () => {
  const { shareMentionByEmail } = useMentionActions()
  const { shareMentionPayload, modalType, onCloseModal } = useModal()
  const { t } = useTranslation('mentions')
  const { openSnackbar } = useSnackbar()

  const shareMentionForm = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(ShareMentionValidationSchema),
    defaultValues: initialValues,
  })

  const handleCloseModal = useCallback(() => {
    onCloseModal()
    shareMentionForm.reset()
  }, [onCloseModal, shareMentionForm])

  const handleOnSubmit = useCallback(
    async (values: { emails: string[]; comments: string }) => {
      try {
        const validEmailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        const translatedErrorPrefix = t(
          'shareMentions.form.emails.invalid',
          'Correo electrónico no válido',
        )
        // @ts-ignore // TODO validate options types
        const emails = values?.emails.map(option => option.value.trim())
        for (const email of emails) {
          if (!validEmailRegex.test(email)) {
            shareMentionForm.setError('emails', {
              type: 'custom',
              message: `${translatedErrorPrefix}: ${email}`,
            })
            return
          }
        }

        await shareMentionByEmail({
          mention: shareMentionPayload.mention,
          comments: values.comments,
          emails: [...new Set(emails)], // remove duplicated
        })

        handleCloseModal()
        openSnackbar({
          title: t(
            'shareMentions.form.confirmMessage',
            'Se ha compartido la mención',
          ),
          horizontal: 'right',
        })

        trackEvent('User shares a post by email')
      } catch {
        openSnackbar({
          title: t(
            'shareMentions.form.errorMessage',
            'Ha ocurrido un problema al compartir la mención. Contacta con soporte',
          ),
          horizontal: 'right',
          type: 'error',
        })
      }
    },
    [
      handleCloseModal,
      openSnackbar,
      shareMentionByEmail,
      shareMentionForm,
      shareMentionPayload,
      t,
    ],
  )

  return {
    handleOnSubmit,
    isOpen: modalType === ModalType.SHARE_MENTION,
    handleCloseModal,
    shareMentionForm,
    t,
  }
}

export default useLogic
