import { FC, memo } from 'react'
import type { Props } from '../../types'

const Amazon: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8479 10.1305C11.815 10.0833 11.7819 10.0371 11.7489 9.99122C11.481 9.61778 11.2257 9.26178 11.2257 8.54834V5.88858C11.2257 5.7961 11.2262 5.70426 11.2268 5.61314C11.2326 4.59338 11.2379 3.66279 10.5025 2.95091C9.87174 2.32199 8.82406 2.1001 8.02286 2.1001C6.45582 2.1001 4.70766 2.70619 4.3411 4.71458C4.3023 4.92794 4.45166 5.03954 4.58718 5.07098L6.18278 5.25002C6.33222 5.24218 6.44014 5.08986 6.46902 4.9365C6.6059 4.24442 7.16478 3.91194 7.79278 3.91194C8.13142 3.91194 8.51606 4.04034 8.71646 4.35538C8.92318 4.66946 8.9199 5.08882 8.91694 5.46386C8.91662 5.50754 8.9163 5.55058 8.9163 5.59282V5.81578C8.76822 5.83298 8.6131 5.84922 8.45334 5.86594C7.5831 5.95722 6.57478 6.0629 5.81966 6.40618C4.7887 6.86922 4.0647 7.81138 4.0647 9.19618C4.0647 10.9709 5.14198 11.8581 6.52902 11.8581C7.6995 11.8581 8.34006 11.5716 9.2431 10.6171C9.28822 10.6848 9.3287 10.7473 9.36694 10.8062C9.5823 11.1383 9.72294 11.3551 10.1869 11.7554C10.3094 11.8231 10.4715 11.8206 10.5801 11.7186C10.9089 11.4154 11.5059 10.8775 11.8425 10.585C11.9769 10.4704 11.9531 10.2853 11.8479 10.1305ZM8.6083 9.36346C8.34694 9.84506 7.93062 10.1397 7.46806 10.1397C6.83734 10.1397 6.46702 9.64034 6.46702 8.90154C6.46702 7.44714 7.72574 7.18314 8.91598 7.18314C8.91598 7.27018 8.91678 7.3577 8.9175 7.44538C8.92334 8.10378 8.92934 8.77522 8.6083 9.36346Z"
      fill="currentColor"
    />
    <path
      d="M13.816 13.0329C12.2398 14.2517 9.95405 14.9001 7.98549 14.9001C5.22765 14.9001 2.74346 13.8317 0.863712 12.0531C0.716272 11.9133 0.847872 11.7223 1.02502 11.8309C3.05338 13.0679 5.56133 13.8125 8.15149 13.8125C9.89909 13.8125 11.8197 13.4328 13.5869 12.6467C13.8535 12.5285 14.0769 12.8313 13.816 13.0329Z"
      fill="currentColor"
    />
    <path
      d="M12.6314 12.1838C13.1388 12.1196 14.2704 11.9774 14.4719 12.2476C14.6736 12.5176 14.25 13.628 14.059 14.1284L14.0576 14.132C14.0002 14.283 14.1237 14.3432 14.2539 14.2288C15.0997 13.4877 15.3183 11.9343 15.1449 11.7092C14.973 11.4872 13.4943 11.2949 12.592 11.9592C12.4532 12.0622 12.477 12.2026 12.6314 12.1838Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Amazon)
