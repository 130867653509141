import * as yup from 'yup'

export const initialValues = {
  emails: [],
  comments: '',
}

// TODO add translations to validations
export const ShareMentionValidationSchema = yup.object({
  emails: yup
    .array()
    .min(1, 'Indica un correo electrónico para compartir la mención')
    .required('Indica un correo electrónico para compartir la mención'),
  comments: yup.string(),
})
