import { gql } from '@apollo/client'

export const FEED_FRAGMENT = gql`
  fragment FeedData on Feed {
    id
    name
    slug
    type
    activeSources
    avatarUrl
    monitorEngine
    rules {
      parameter
      klass
      operator
      src
    }
    profiles {
      gmb {
        accounts {
          id
          name
        }
      }
    }
    reviewPlatforms
  }
`

export const FEED_RECOVER_FRAGMENT = gql`
  fragment FeedRecoverData on FeedRecover {
    id
    createdAt
    optionalToken
    processedAt
    since
    src
    status
    text
    until
    updatedAt
    username
  }
`
