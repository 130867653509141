import { set } from 'lodash/fp'

import { SET_COUNTRY, SET_REGION } from '../actions/communityLocationActions'
import {
  SET_FILTER,
  OVERWRITE_FILTERS,
  REMOVE_FILTER,
  ADD_FILTER,
} from './filters'
import { SET_DATE_RANGE, SET_VIEW } from '../actions/globalFiltersActions'

const initialState = {
  country: undefined,
  region: undefined,
}

export default function communityLocation(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_COUNTRY:
      return set('country', payload.country, state)

    case SET_REGION:
      return set('region', payload.region, state)

    // When changing filters, it's possible that there is no data for the
    // currently selected country/region, so it's important to reset them
    // This prevents bugs like INAC-4638 from happening.
    case SET_FILTER:
    case OVERWRITE_FILTERS:
    case REMOVE_FILTER:
    case ADD_FILTER:
    /* @deprecated */ case SET_DATE_RANGE:
    /* @deprecated */ case SET_VIEW:
      return initialState

    default:
      return state
  }
}
