import Moment from 'moment'
import invariant from 'invariant'
import { extendMoment } from 'moment-range'
import { get, mergeWith, uniqWith, isEqual } from 'lodash/fp'

import requestSocket from '../../requestSocket'

const moment = extendMoment(Moment)

// Create deep uniquity check
const deepUniq = uniqWith(isEqual)

// Merge the objects by joining arrays, only keeping unique values
const combine = mergeWith((objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return deepUniq(objValue.concat(srcValue))
  }

  // Returning udefined uses the default merging logic
  return undefined
})

export const DATA_REQ = 'DATA_REQ'
export const DATA_RES = 'DATA_RES'

const setDataRequest = ({ path }) => ({
  type: DATA_REQ,
  meta: { path },
})

const setDataResponse = ({ path, res, err }) => ({
  type: DATA_RES,
  payload: res,
  error: err,
  meta: { path },
})

function maybeFetchDataFactory(previousPeriod = false) {
  return (eventName, ...extras) =>
    (dispatch, getState) => {
      const state = getState()
      const { product, feed, view, source, dateRange } = state.globalFilters
      const dr = previousPeriod
        ? moment.range(
            dateRange.start.valueOf() - dateRange.valueOf(),
            dateRange.start,
          )
        : dateRange

      const path = extras.reduce(
        (p, extra) => {
          if (!extra) return [...p, 'default']
          invariant(
            extra.id,
            'maybeFetchData: "extra" must proved an id or be null',
          )
          return [...p, extra.id]
        },
        [
          product,
          feed || 'default',
          view || 'default',
          source,
          dr.toString(),
          eventName,
        ],
      )

      if (get(path, state.data)) {
        return null
      }

      dispatch(setDataRequest({ path }))

      let query = {
        product_id: product,
        start_date: dr.start.valueOf(),
        end_date: dr.end.valueOf(),
        view_id: view,
        filters: {},
      }

      if (feed) {
        query.filters.feeds = [feed]
      }

      extras.forEach(extra => {
        if (!extra || !extra.query) return
        query = combine(query, extra.query)
      })

      requestSocket(eventName, query)
        .then(res => dispatch(setDataResponse({ path, res })))
        .catch(err => dispatch(setDataResponse({ path, err })))

      return null
    }
}

export const maybeFetchData = maybeFetchDataFactory()
export const maybeFetchPreviousData = maybeFetchDataFactory(true)
