import { FC, memo } from 'react'
import type { Props } from '../../../types'

const EyeOpen: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8C1 8 3.90909 2 9 2C14.0909 2 17 8 17 8C17 8 14.0909 14 9 14C3.90909 14 1 8 1 8Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="9.00018"
      cy="8"
      rx="2.18182"
      ry="2.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default memo(EyeOpen)
