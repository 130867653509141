// TODO: NOW BRAND REPRESENTS A PROJECT BUT WE ARE CHANGING THE NAMING
import type { CreateProject_createProject } from 'apollo/generated/CreateProject'
import type { UpdateProject_updateProject } from 'apollo/generated/UpdateProject'
import type { GetUserData_user_client_brands } from 'apollo/generated/GetUserData'
import type { UserDataQuery_user_client_brands } from 'apollo/generated/UserDataQuery'
import { normalizeProduct } from './Product'

export type ProjectFormFields = {
  name: string
}

type Input =
  | CreateProject_createProject
  | UpdateProject_updateProject
  | UserDataQuery_user_client_brands

export const normalizeProject = (input: Input) => {
  const inputFromUser = input as GetUserData_user_client_brands

  let products = inputFromUser?.products
    ? inputFromUser.products.map(product => normalizeProduct(product))
    : []

  return {
    id: input?.id || '',
    name: input?.name || '',
    slug: input?.slug || '',
    logoUrl: input?.logoUrl || '',
    headerLogoUrl: input?.headerLogoUrl || '',
    availableMonitors: input?.availableMonitors || [],
    networks: {
      fb: {
        ex: input?.networks?.fb?.ex || 0,
        tk: input?.networks?.fb?.tk || '',
        tk_error: input?.networks?.fb?.tk_error || false,
      },
      instagram: {
        id: input?.networks?.instagram?.id || '',
        ex: input?.networks?.instagram?.ex || 0,
        p: input?.networks?.instagram?.p || '',
        sn: input?.networks?.instagram?.sn || '',
        tk: input?.networks?.instagram?.tk || '',
        tk_error: input?.networks?.instagram?.tk_error || false,
      },
      tw: {
        atk: input?.networks?.tw?.atk || '',
        ats: input?.networks?.tw?.ats || '',
        id: input?.networks?.tw?.id || '',
        sn: input?.networks?.tw?.sn || '',
      },
      yt: {
        tk: input?.networks?.yt?.tk || '',
      },
      gmb: {
        tk: input?.networks?.gmb?.tk || '',
        rtk: input?.networks?.gmb?.rtk || '',
        ex: input?.networks?.gmb?.ex || 0,
      },
      bw: {
        tk: input?.networks?.bw?.tk || '',
        ex: input?.networks?.bw?.ex || 0,
      },
      tiktok: {
        id: input?.networks?.tiktok?.id || '',
        tk: input?.networks?.tiktok?.tk || '',
        sn: input?.networks?.tiktok?.sn || '',
      },
    },
    products,
  }
}
