import { FC, memo } from 'react'
import type { Props } from '../../types'

const Media: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width="36" height="36" rx="18" fill="#C00000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5435 13.3768C20.5435 13.6846 20.7927 13.9342 21.1002 13.9342H24V25.0563C24 25.576 23.5869 25.9974 23.0771 25.9975L12.9233 26C12.4134 26.0001 12 25.5787 12 25.0588V10.9412C12 10.4214 12.4133 10 12.9231 10H20.5435V13.3768ZM13.7538 18.6119C13.4989 18.6119 13.2923 18.8226 13.2923 19.0825V22.659C13.2923 22.9189 13.4989 23.1296 13.7538 23.1296H22.2462C22.5011 23.1296 22.7077 22.9189 22.7077 22.659V19.0825C22.7077 18.8226 22.5011 18.6119 22.2462 18.6119H13.7538ZM13.2923 16.1648C13.2923 15.9309 13.4783 15.7413 13.7077 15.7413H17.8615C18.0909 15.7413 18.2769 15.9309 18.2769 16.1648C18.2769 16.3987 18.0909 16.5883 17.8615 16.5883H13.7077C13.4783 16.5883 13.2923 16.3987 13.2923 16.1648Z"
      fill="white"
    />
    <path d="M21.6923 12.8236V10L24 12.8236H21.6923Z" fill="white" />
  </svg>
)

export default memo(Media)
