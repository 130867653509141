import { flow, set, find, isEqual, identity } from 'lodash/fp'

import { SET_SOURCE as GLOBAL_SET_SOURCE } from '../actions/globalFiltersActions'
import {
  SELECT_MENTION,
  SELECT_ALL_MENTIONS,
  CLEAR_SELECTED_MENTIONS,
  SET_TOTAL_MENTIONS,
  REFRESH_MENTIONS,
  SET_MENTIONS_PAGE,
  SET_MENTIONS_ORDER,
  SET_PENDING_MENTIONS,
  SET_RESULTS_PER_PAGE,
  MENTIONS_REHYDRATE,
} from '../actions/mentionsActions'
import {
  SET_DATE_RANGE,
  SET_FILTER,
  ADD_FILTER,
  OVERWRITE_FILTERS,
  REALTIME_UPDATE,
} from './filters'

/**
 * Mentions reducer holds the shared state of the mentions
 */

const DEFAULT_SORT = 'DATE'
const DEFAULT_ORDER = 'DESCENDING'

const resetSort = flow(set('order', DEFAULT_ORDER), set('sort', DEFAULT_SORT))

const initialState = {
  selected: [],
  excluded: [],
  isSelectAll: false,
  shouldUpdate: false,
  total: 0,
  page: 0,
  order: DEFAULT_ORDER,
  sort: DEFAULT_SORT,
  resultsPerPage: 20,
  // Used during real-time
  peding: 0,
}

export default function mentions(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SELECT_MENTION: {
      // Exclude the mention if all the mentions are selected
      if (state.isSelectAll) {
        // If we already have the mention, remove it
        if (find(isEqual(payload.mention), state.excluded)) {
          const nextExcluded = state.excluded.filter(
            m => m.id !== payload.mention.id,
          )
          return set('excluded', nextExcluded, state)
        }

        return set('excluded', [payload.mention, ...state.excluded], state)
      }

      // If we already have the mention, remove it
      if (find(isEqual(payload.mention), state.selected)) {
        const nextSelected = state.selected.filter(
          m => m.id !== payload.mention.id,
        )
        return set('selected', nextSelected, state)
      }

      return set('selected', [payload.mention, ...state.selected], state)
    }

    case SELECT_ALL_MENTIONS:
      return Object.assign({}, state, {
        isSelectAll: true,
        selected: [],
        excluded: [],
      })

    case CLEAR_SELECTED_MENTIONS:
      return Object.assign({}, state, {
        isSelectAll: false,
        selected: [],
        excluded: [],
      })

    // When setting the total mentions, also resen the validity of the state
    case SET_TOTAL_MENTIONS:
      return Object.assign({}, state, {
        total: payload.total,
        shouldUpdate: false,
      })

    case SET_MENTIONS_PAGE:
      return Object.assign({}, state, { page: payload.page })

    case SET_MENTIONS_ORDER:
      return Object.assign({}, state, {
        order: payload.order,
        sort: payload.sort,
      })

    case REFRESH_MENTIONS:
      return set('shouldUpdate', true, state)

    case SET_PENDING_MENTIONS:
      return set('pending', payload.pending, state)

    // When the filter change, also return to page 0.
    case SET_FILTER:
    case SET_DATE_RANGE:
    case ADD_FILTER:
    case OVERWRITE_FILTERS: {
      let shouldResetSort = false
      if (
        (type === OVERWRITE_FILTERS && payload.filters.source) ||
        ((type === SET_FILTER || type === ADD_FILTER) &&
          payload.key === 'source')
      ) {
        shouldResetSort = true
      }

      return flow(
        set('page', 0),
        set('pending', 0),
        shouldResetSort ? resetSort : identity,
      )(state)
    }

    // When the realtime mentions is updated, reset the valiue of
    // the pending mesages
    case REALTIME_UPDATE: {
      return flow(set('pending', 0), set('page', 0))(state)
    }

    case GLOBAL_SET_SOURCE:
      return resetSort(state)

    case SET_RESULTS_PER_PAGE:
      return { ...state, resultsPerPage: payload }

    case MENTIONS_REHYDRATE: {
      return { ...state, resultsPerPage: initialState.resultsPerPage }
    }

    default:
      return state
  }
}
