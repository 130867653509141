import { gql } from '@apollo/client'

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryData on Category {
    id
    name
    description
    source
    type
    isExtended
    isMarket
    keywords {
      words
      operator
    }
    riskLevel
    originalCategoryName
  }
`
