import { useTranslation } from 'react-i18next'
import Button from 'components/Buttons/Button/Button'
import useLogic from './logic'
import * as S from './styles'

const ConnectIntegration = () => {
  const { title, desc, isOpen, handleSubmit, onCloseModal } = useLogic()
  const { t } = useTranslation('integrations')

  return (
    <S.Container open={isOpen} onClose={onCloseModal}>
      <S.Title>{title}</S.Title>
      <S.SubTitle>{desc}</S.SubTitle>

      <S.Buttons>
        <Button color="secondary" onClick={onCloseModal}>
          {t('cancel', 'Cancelar')}
        </Button>
        <Button onClick={handleSubmit}>{t('connect', 'Conectar')}</Button>
      </S.Buttons>
    </S.Container>
  )
}

export default ConnectIntegration
