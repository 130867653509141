import Promise from 'bluebird'

import requestSocket from '../../requestSocket'
import logError from '@utils/logError'
import { GET_VIEWS } from 'apollo/queries/Views'
import { flow, map, orderBy, getOr, isEmpty } from 'lodash/fp'
import { generateSlug } from '@utils/url'

/**
 * QUERIES
 */
const GET_VIEWS_QUERY = GET_VIEWS

/**
 * Action names
 */
export const TRANSFORM_DATE_RANGE = 'TRANSFORM_GLOBALFILTERS_DATE_RANGE'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const SET_DEFAULT_DATE = 'SET_DEFAULT_DATE'
export const SET_SOURCE = 'SET_SOURCE'
export const SET_ALLOWED_SOURCES = 'SET_ALLOWED_SOURCES'
export const RESET_ALLOWED_SOURCES = 'RESET_ALLOWED_SOURCES'
export const SET_USER_FILTER = 'SET_USER_FILTER'
export const SET_IS_REVIEWS = 'SET_IS_REVIEWS'

// Used for the user filter
export const TOGGLE_USER_FILTER = 'TOGGLE_USER_FILTER'
export const REQ_USER_LIST = 'REQ_USER_LIST'
export const RES_USER_LIST = 'RES_USER_LIST'

// Views events
export const SET_VIEW = 'SET_VIEW'
export const SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW'
export const REQ_VIEWS_LIST = 'REQ_VIEWS_LIST'
export const RES_VIEWS_LIST = 'RES_VIEWS_LIST'
export const CLEAR_VIEWS = 'CLEAR_VIEWS'

// real time
export const ENABLE_REAL_TIME = 'ENABLE_REAL_TIME'
export const DISABLE_REAL_TIME = 'DISABLE_REAL_TIME'

// feed and product, route specific
export const SET_FEED = 'FILTERS_SET_FEED'
export const RESET_FEED = 'FILTERS_RESET_FEED'
export const SET_PRODUCT = 'FILTERS_SET_PRODUCT'

// redux persist filter specific
export const FILTERS_REHYDRATE = 'persist/REHYDRATE'

// Custom action related with no Filters view
export const UPDATE_REHYDRATED = 'UPDATE_REHYDRATED'

// views
export const UPDATE_VIEWS_LIST = 'UPDATE_VIEWS_LIST'

export const SET_FILTERS_FROM_ALERT = 'SET_FILTERS_FROM_ALERT'
export const CLEAR_ALERT_ID = 'CLEAR_ALERT_ID'

/**
 * Action dispatchers
 */

export function transformDateRange(dateRange) {
  return {
    type: TRANSFORM_DATE_RANGE,
    payload: { dateRange },
  }
}

export function setDateRange(dateRange) {
  // When the end of the range is rounded to the start of that day, modify it
  // to be the END of that day instead.
  if (dateRange.end.clone().startOf('day').isSame(dateRange.end)) {
    dateRange.end.endOf('day')
  }

  return {
    type: SET_DATE_RANGE,
    payload: { dateRange },
  }
}

export function setSource(source) {
  return {
    type: SET_SOURCE,
    payload: { source },
  }
}

export function setIsReviews(product) {
  return {
    type: SET_IS_REVIEWS,
    payload: { product },
  }
}

export function setRehydrated(payload) {
  return {
    type: UPDATE_REHYDRATED,
    payload,
  }
}

export function setAllowedSources(sources) {
  return {
    type: SET_ALLOWED_SOURCES,
    payload: { sources },
  }
}

export function resetAllowedSources() {
  return { type: RESET_ALLOWED_SOURCES }
}

export function allowOnlySource(source) {
  return setAllowedSources([source])
}

export function disableSources(sources) {
  const allSources = [
    'all',
    'twitter',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'media',
    'review',
    'linkedin',
  ]
  return setAllowedSources(
    allSources.filter(source => !sources.includes(source)),
  )
}
/**
 * User filters
 */

export function setUserFilter(_user) {
  const user = _user === 'all' ? null : _user
  return {
    type: SET_USER_FILTER,
    payload: { user },
  }
}

function toggleUserFilter() {
  return { type: TOGGLE_USER_FILTER, payload: {} }
}

export function showUserFilter() {
  return (dispatch, getState) => {
    const { globalFilters } = getState()

    if (!globalFilters.meta.showUserFilter) {
      dispatch(toggleUserFilter())
    }
  }
}

export function hideUserFilter() {
  return (dispatch, getState) => {
    const { globalFilters } = getState()

    if (globalFilters.meta.showUserFilter) {
      dispatch(toggleUserFilter())
    }
  }
}

function userListRes(users) {
  return { type: RES_USER_LIST, payload: { users } }
}

function userListReq() {
  return { type: REQ_USER_LIST, payload: {} }
}

export function setUsersList(users) {
  return dispatch => {
    if (!users) {
      return dispatch(userListReq())
    }
    return dispatch(userListRes(users))
  }
}

export function setDefaultRange() {
  return { type: SET_DEFAULT_DATE, payload: {} }
}

/**
 * Views
 */

export function setView(viewId) {
  return {
    type: SET_VIEW,
    payload: { view: viewId },
  }
}

export function setDefaultView(viewId) {
  return {
    type: SET_DEFAULT_VIEW,
    payload: { view: viewId },
  }
}

function viewsListReq() {
  return { type: REQ_VIEWS_LIST, payload: {} }
}

function viewsListRes(views, productId) {
  return {
    type: RES_VIEWS_LIST,
    payload: { views },
    meta: { productId },
  }
}

export function updateViewsList(view) {
  return {
    type: UPDATE_VIEWS_LIST,
    payload: view.data.createView,
  }
}

/**
 * Fetch the list of views
 * @deprecated prefer graphQl queries use getViews instead
 * @param {Object}
 * @param {Object} product - Currently active product
 * @param {Boolean} [setDefault=true] - Sets the default view as active
 */
export function fetchViews({ product, setDefault = true }) {
  return dispatch => {
    dispatch(viewsListReq())

    const query = {
      product_id: product.id,
    }

    return requestSocket('getViews', query)
      .then(_views => {
        const views = _views.map(view => ({
          id: view.id,
          name: view.name,
          slug: view.slug,
          productId: product.id,
          default: view.default || false,
          themes: view.themes,
        }))

        dispatch(viewsListRes(views, product.id))

        if (setDefault) {
          const defaultView = _views.find(v => v.default)
          if (defaultView) dispatch(setView(defaultView.id))
        }

        return views
      })
      .catch(err => {
        logError(err, {
          info: 'Error while loading the list of views',
        })
      })
  }
}

/**
 * Sort views, sticky first by slug name
 * @returns list of views sorted by default view first
 */
function orderViewsByDefault(product) {
  return flow(
    getOr([], 'views'),
    map(view => ({
      id: view.id,
      name: view.name,
      default: !!view.default,
      productId: product.id,
      slug: generateSlug(view.name),
    })),
    orderBy(['default'], ['desc']),
  )(product)
}

/**
 * Get views with Apollo query
 * @param {String} productId
 * @param {ApolloClient} apolloClient
 */
export function getViews(productId, apolloClient, setDefault = true) {
  return async dispatch => {
    dispatch(viewsListReq())
    const {
      data: {
        user: { product },
      },
    } = await apolloClient.query({
      query: GET_VIEWS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        productId,
      },
    })

    const views = orderViewsByDefault(product)

    dispatch(viewsListRes(views, product.id))

    if (setDefault) {
      const defaultView = !isEmpty(views) && views[0].id
      if (defaultView) dispatch(setView(defaultView))
    }
  }
}

export function setViewFromSlug({ product, slug }) {
  return dispatch => {
    if (slug === 'false') return dispatch(setView(''))

    return dispatch(fetchViews({ product, setDefault: false })).then(views => {
      const view = views.find(v => v.slug === slug)
      const viewId = view ? view.id : null

      dispatch(setView(viewId))
    })
  }
}

/**
 * Fetch the views if we don't have it yet
 */
export function maybeFetchViews({ product, options = { setDefault: false } }) {
  return (dispatch, getState) => {
    const { isFetchingViews, lastFetchProductId, views } =
      getState().globalFilters.meta

    // Already fetching
    if (isFetchingViews) {
      // Call again the method in some time to check again if the request
      // was fulfilled
      return Promise.delay(300).then(() =>
        maybeFetchViews({ product, options }),
      )
    }

    // When the last time we fetched was a different product, fetch the view
    if (lastFetchProductId !== product.id) {
      // Whenever we change product, reset the value of the selected view
      dispatch(setView(null))

      return dispatch(fetchViews({ product, setDefault: options.setDefault }))
    }

    return Promise.resolve(views)
  }
}

/**
 * Clears the list of views
 */
export function clearViews() {
  return { type: CLEAR_VIEWS, payload: {} }
}

/**
 * Real time mode
 * @param {Number} interval - Interval in minutes
 */
export function enableRealTime(interval) {
  return { type: ENABLE_REAL_TIME, payload: { interval } }
}

export function disableRealTime() {
  return { type: DISABLE_REAL_TIME, payload: {} }
}

export function setFeed(feedId) {
  return { type: SET_FEED, payload: { feedId } }
}

export function setProduct(productId) {
  return { type: SET_PRODUCT, payload: { productId } }
}

export function setFiltersFromAlert(payload) {
  return { type: SET_FILTERS_FROM_ALERT, payload }
}

export function clearAlertId() {
  return { type: CLEAR_ALERT_ID, payload: {} }
}
