import { FC, memo } from 'react'
import Button from 'components/Buttons/Button/Button'
import TagsInputController from 'components/FormControllers/TagsInputController/TagsInputController'
import TextAreaController from 'components/FormControllers/TextAreaController/TextAreaController'
import useLogic from './logic'
import * as S from './styles'

const ShareMention: FC = () => {
  const { handleCloseModal, isOpen, shareMentionForm, handleOnSubmit, t } =
    useLogic()
  const {
    handleSubmit: handleFormSubmit,
    control: formControl,
    formState,
  } = shareMentionForm

  return (
    <S.Container open={isOpen} onClose={handleCloseModal}>
      <S.Title>{t('shareMentions.title', 'Compartir mención')}</S.Title>
      <S.SubTitle>
        {t(
          'shareMentions.description',
          'Escribe la dirección de correo electrónico de uno o más destinatarios para compartir la mención.',
        )}
      </S.SubTitle>

      <S.Form>
        <TagsInputController
          name="emails"
          control={formControl}
          label={t('shareMentions.form.emails.label', 'Destinatarios')}
          placeholder={t(
            'shareMentions.form.emails.placeholder',
            'Dirección de correo electrónico',
          )}
        />
        <TextAreaController
          name="comments"
          control={formControl}
          label={t('shareMentions.form.comments.label', 'Comentarios')}
          placeholder={t(
            'shareMentions.form.comments.placeholder',
            'Escriba aquí...',
          )}
          minRows={5.5}
          isOptional
        />
        <S.Buttons>
          <Button color="secondary" onClick={handleCloseModal}>
            {t('shareMentions.form.cancel', 'Cancelar')}
          </Button>
          <Button
            onClick={handleFormSubmit(handleOnSubmit)}
            disabled={!formState.isValid}
          >
            {t('shareMentions.form.share', 'Compartir')}
          </Button>
        </S.Buttons>
      </S.Form>
    </S.Container>
  )
}

export default memo(ShareMention)
