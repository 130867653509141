import { gql } from '@apollo/client'

export const VIEW_CATEGORIES_QUERY = gql`
  query GetViewCategoriesQuery($productId: ID!, $viewId: ID!) {
    user {
      id
      product(id: $productId) {
        id
        categories {
          id
          name
        }
        view(id: $viewId) {
          id
          name
          categories {
            id
            name
            description
            isExtended
            isMarket
            keywords {
              words
              operator
            }
            riskLevel
          }
          themes {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_VIEWS = gql`
  query GetViewsQuery($productId: ID!) {
    user {
      product(id: $productId) {
        id
        views {
          id
          name
          default
        }
      }
    }
  }
`
