import { SHOW_TOAST, HIDE_TOAST } from '../actions/toastActions'

const initialState = {
  isVisible: false,
  timeoutId: null,
  toastId: null,
  options: {},
}

export default function toast(state = initialState, action) {
  const { type, payload, meta } = action

  switch (type) {
    case SHOW_TOAST:
      return {
        isVisible: true,
        timeoutId: meta.timeoutId,
        toastId: meta.toastId,
        options: payload,
      }

    case HIDE_TOAST: {
      // When a toastId is specified, only hide the toast the it's the same
      if (meta.toastId && state.toastId !== meta.toastId) return state

      return {
        isVisible: false,
        timeoutId: null,
        options: {},
      }
    }

    default:
      return state
  }
}
