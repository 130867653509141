import { FC, memo } from 'react'
import type { Props } from '../../types'

const TvMode: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6.50195 2.84055C6.83332 2.84055 7.10195 3.10918 7.10195 3.44055C7.10195 3.77192 6.83332 4.04055 6.50195 4.04055H2.88135C2.43952 4.04055 2.08135 4.39872 2.08135 4.84055V11.5739C2.08135 12.0157 2.43952 12.3739 2.88135 12.3739H8.47505C8.47718 12.3739 8.47931 12.3739 8.48145 12.3739C8.48358 12.3739 8.48571 12.3739 8.48784 12.3739H14.0813C14.5232 12.3739 14.8813 12.0157 14.8813 11.5739V7.73244C14.8813 7.40107 15.15 7.13244 15.4813 7.13244C15.8127 7.13244 16.0813 7.40107 16.0813 7.73244V11.5739C16.0813 12.6785 15.1859 13.5739 14.0813 13.5739H9.08145V15.4072H11.1064C11.4378 15.4072 11.7064 15.6758 11.7064 16.0072C11.7064 16.3385 11.4378 16.6072 11.1064 16.6072H5.85645C5.52507 16.6072 5.25645 16.3385 5.25645 16.0072C5.25645 15.6758 5.52507 15.4072 5.85645 15.4072H7.88145V13.5739H2.88135C1.77678 13.5739 0.881348 12.6785 0.881348 11.5739V4.84055C0.881348 3.73598 1.77678 2.84055 2.88135 2.84055H6.50195Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7314 1.27153C11.423 1.27153 10.054 1.96756 8.96935 3.48297C8.77649 3.75244 8.4017 3.81453 8.13224 3.62167C7.86277 3.42881 7.80068 3.05402 7.99354 2.78455C9.25864 1.01699 10.9648 0.0715332 12.7314 0.0715332C14.4981 0.0715332 16.2043 1.01699 17.4694 2.78455C17.6622 3.05402 17.6001 3.42881 17.3307 3.62167C17.0612 3.81453 16.6864 3.75244 16.4935 3.48297C15.4089 1.96756 14.0399 1.27153 12.7314 1.27153ZM12.7315 3.66356C12.0274 3.66356 11.2626 4.03702 10.6398 4.90729C10.4469 5.17676 10.0721 5.23885 9.80264 5.04599C9.53318 4.85313 9.47108 4.47834 9.66394 4.20887C10.4673 3.08645 11.5691 2.46356 12.7315 2.46356C13.8939 2.46356 14.9957 3.08645 15.7991 4.20887C15.992 4.47834 15.9299 4.85313 15.6604 5.04599C15.3909 5.23885 15.0161 5.17676 14.8233 4.90729C14.2004 4.03702 13.4357 3.66356 12.7315 3.66356ZM12.7314 6.34857C12.6964 6.34857 12.6008 6.36484 12.4893 6.52074C12.2964 6.79021 11.9216 6.8523 11.6521 6.65944C11.3827 6.46658 11.3206 6.09179 11.5135 5.82232C11.8055 5.41427 12.2382 5.14857 12.7314 5.14857C13.2247 5.14857 13.6573 5.41427 13.9494 5.82232C14.1423 6.09179 14.0802 6.46658 13.8107 6.65944C13.5412 6.8523 13.1665 6.79021 12.9736 6.52074C12.862 6.36484 12.7664 6.34857 12.7314 6.34857Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(TvMode)
