import { FC, memo } from 'react'
import type { Props } from '../../types'

const Media: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.85714 15.1194C4.43519 15.1194 5.71429 16.3997 5.71429 17.9762C5.71429 19.5546 4.43519 20.832 2.85714 20.832C1.27873 20.832 0 19.5546 0 17.9762C0 16.3997 1.27873 15.1194 2.85714 15.1194Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.7112C2.43382 11.7112 4.71996 12.659 6.44041 14.3775C8.16152 16.0992 9.10873 18.3916 9.10873 20.832H12.8571C12.8571 13.745 7.08998 7.97863 0 7.97863V11.7112Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.6699C8.90495 4.6699 16.1515 11.9214 16.1515 20.832H20C20 9.80692 11.0288 0.837891 0 0.837891V4.6699Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Media)
