import { FC, ReactElement, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { ReactComponent as OnlyDesktopIllustration } from 'assets/img/only-desktop.svg'
import IllustratedMessage from 'components/IllustratedMessage/IllustratedMessage'
import * as S from './styles'

const OnlyDesktop: FC<{ children: ReactElement }> = ({ children }) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))
  const { t } = useTranslation('onlyDesktop')

  return matches ? (
    <> {children} </>
  ) : (
    <S.Container>
      <S.LogoContainer>
        <S.SentisisLogo />
      </S.LogoContainer>
      <S.Content>
        <IllustratedMessage
          illustration={<OnlyDesktopIllustration />}
          title={t('title', '¡Oh, vaya! ¿Estás utilizando tu móvil?')}
        >
          <S.Text>
            {t(
              'desc1',
              'Los productos de Séntisis están diseñados para ofrecer la mejor experiencia de usuario desde dispositivos de sobremesa.',
            )}
          </S.Text>
          <S.Text>
            {t(
              'desc2',
              'En estos momentos, no podemos ofrecerte una versión responsive para dispositivos móviles.',
            )}
          </S.Text>
          <S.Text>
            {t('desc3', 'Lamentamos las molestias que pueda ocasionarte.')}
          </S.Text>
        </IllustratedMessage>
      </S.Content>
    </S.Container>
  )
}

export default memo(OnlyDesktop)
