import { FC, memo } from 'react'
import type { Props } from '../../types'

const Trends: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.77778 0C6.03034 0 6.25515 0.159797 6.33777 0.398047L10.2222 11.6L11.8845 6.8065C11.9671 6.56825 12.1919 6.40845 12.4444 6.40845H15.4074C15.7347 6.40845 16 6.6733 16 7C16 7.3267 15.7347 7.59155 15.4074 7.59155H12.8664L10.7822 13.602C10.6996 13.8402 10.4748 14 10.2222 14C9.96966 14 9.74485 13.8402 9.66223 13.602L5.77778 2.39996L4.11555 7.1935C4.03293 7.43175 3.80812 7.59155 3.55556 7.59155H0.592593C0.265313 7.59155 0 7.3267 0 7C0 6.6733 0.265313 6.40845 0.592593 6.40845H3.13359L5.21779 0.398047C5.3004 0.159797 5.52521 0 5.77778 0Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Trends)
