import { FC, memo } from 'react'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import useLogic from './logic'
import Button from 'components/Buttons/Button/Button'

const RemoveDialogModal: FC = () => {
  const {
    handleOnCloseModal,
    isOpen,
    handleOnRemove,
    removeDialogPayload,
    confirmData,
    checkIsValid,
    canSubmit,
  } = useLogic()
  const { t } = useTranslation('modals')

  return (
    <S.Container open={isOpen} onClose={handleOnCloseModal}>
      <S.Title>{removeDialogPayload?.title || 'Vas a eliminar'}</S.Title>
      <S.SubTitle>
        {removeDialogPayload?.description || '¿Seguro que quieres eliminar?'}
      </S.SubTitle>

      {confirmData && (
        <S.FormContainer>
          <S.Input
            onChange={e => checkIsValid(e.target.value)}
            label={confirmData?.label}
            placeholder={confirmData?.placeholder}
            fullWidth
          />
        </S.FormContainer>
      )}

      <S.Buttons>
        <Button color="secondary" onClick={handleOnCloseModal}>
          {removeDialogPayload?.cancelButtonText || t('cancel', 'Cancelar')}
        </Button>
        <Button
          color="error"
          onClick={handleOnRemove}
          disabled={confirmData ? !canSubmit : false}
        >
          {removeDialogPayload?.acceptButtonText || t('eliminate', 'Eliminar')}
        </Button>
      </S.Buttons>
    </S.Container>
  )
}

export default memo(RemoveDialogModal)
