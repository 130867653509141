import { FC } from 'react'
import {
  Container,
  Content,
  Title,
  SubTitle,
  InnerContent,
  Buttons,
} from './styles'
import type { Props } from './types'
import useLogic from './logic'

const Modal: FC<Props> = ({
  children,
  className,
  disableBackdropClick,
  disableEscapeKeyDown,
  disableFullScreen,
  disableRestoreFocus,
  onClose,
  open,
  setMaxWidth,
}) => {
  const { container, handleClose } = useLogic({
    disableBackdropClick,
    disableEscapeKeyDown,
    onClose,
  })

  return (
    <Container
      container={container}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableRestoreFocus={disableRestoreFocus}
      className={className}
      $disableFullScreen={!!disableFullScreen}
      $setMaxWidth={!!setMaxWidth}
    >
      <Content>{children}</Content>
    </Container>
  )
}

export default Modal

export { Content, Title, SubTitle, InnerContent, Buttons }
