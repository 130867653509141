import styled from 'styled-components'
import { ReactComponent as SentisisLogoSVG } from 'assets/img/sentisis-logo.svg'

export const Container = styled.div`
  background-color: white;
  min-height: 100%;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LogoContainer = styled.div`
  text-align: center;
  padding: 1.25em 0 2.5em;
`

export const SentisisLogo = styled(SentisisLogoSVG)`
  width: 206px;
  height: 64px;
`

export const Text = styled.p`
  color: #88888a;
  font-size: 16px;
  margin-bottom: 1em;
`

export const Content = styled.div`
  max-width: 27em;
`
