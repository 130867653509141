import { gql } from '@apollo/client'

export const CUSTOM_RULE_FRAGMENT = gql`
  fragment CustomRuleData on CustomRule {
    id
    name
    description
    conditions {
      type
      operation
      sentiments
      categories
      authors
      sources
      feeds
    }
    results {
      type
      action
      sentiment
      categories
    }
    extendTo {
      retweets
      commentsAndReplies
    }
  }
`
