import { FC, memo } from 'react'
import type { Props } from '../types'

const Plus: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6.36461 1.10609V5.89391L1.57679 5.89391C1.24206 5.89391 0.970703 6.16527 0.970703 6.5C0.970703 6.83474 1.24206 7.10609 1.5768 7.10609L6.36461 7.10609L6.36461 11.8939C6.36461 12.2286 6.63597 12.5 6.9707 12.5C7.30544 12.5 7.57679 12.2286 7.57679 11.8939L7.5768 7.10609L12.3646 7.10609C12.6993 7.10609 12.9707 6.83474 12.9707 6.5C12.9707 6.16527 12.6993 5.89391 12.3646 5.89391L7.5768 5.89391V1.10609C7.5768 0.771355 7.30544 0.5 6.9707 0.5C6.63597 0.5 6.36461 0.771356 6.36461 1.10609Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Plus)
