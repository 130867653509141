import { FC, memo } from 'react'
import type { Props } from '../../types'

const Explore: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width="36" height="36" rx="18" fill="#FFAE00" />
    <g clipPath="url(#clip0_1107_40792)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0541 15.3964C18.3006 12.1399 22.184 10.2561 25.5358 10.0012C25.7814 9.98201 25.993 10.1943 25.9739 10.4407C25.7197 13.8039 23.8429 17.6993 20.5964 20.9569C20.0275 21.5265 19.4352 22.0406 18.8334 22.5196V22.5206C18.7004 22.6284 18.542 25.7974 18.542 25.7974C18.509 25.9915 18.2719 26.0683 18.1326 25.9286L10.0957 17.8657C9.95742 17.727 10.0329 17.4892 10.2254 17.455C10.2254 17.455 13.3783 17.2876 13.4846 17.1553C13.9653 16.5537 14.4874 15.9649 15.0541 15.3964ZM18.5718 15.3985C18.5718 16.5174 19.4767 17.4252 20.5922 17.4252C21.7077 17.4252 22.6126 16.5174 22.6126 15.3985C22.6126 14.2796 21.7077 13.3719 20.5922 13.3719C19.4767 13.3719 18.5718 14.2796 18.5718 15.3985Z"
        fill="white"
      />
      <path
        d="M10.4149 22.2234C10.4245 21.848 10.8775 21.6677 11.1412 21.9322L14.0814 24.8815C14.3451 25.1461 14.1643 25.5994 13.7911 25.609L10.7733 25.6869C10.5308 25.6933 10.3309 25.4938 10.3373 25.2495L10.4149 22.2234Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1107_40792">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Explore)
