import { FC, memo } from 'react'
import type { Props } from '../../types'

const Tiktok: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_2222_7811)">
      <path
        d="M11.1875 0.837891C12.25 0.837891 13.375 0.837891 14.4375 0.837891C14.5 2.08789 14.9375 3.40039 15.875 4.33789C16.8125 5.27539 18.125 5.71289 19.4375 5.83789V9.21289C18.25 9.15039 17 8.90039 15.9375 8.40039C15.4375 8.21289 15 7.90039 14.5625 7.65039C14.5625 10.0879 14.5625 12.5254 14.5625 14.9629C14.5 16.1504 14.125 17.2754 13.4375 18.2754C12.375 19.9004 10.4375 20.9004 8.5 20.9629C7.3125 21.0254 6.125 20.7129 5.125 20.0879C3.4375 18.9629 2.25 17.1504 2.0625 15.2129C2.0625 14.7754 2.0625 14.4004 2.0625 13.9629C2.1875 12.4004 3 10.8379 4.1875 9.83789C5.5625 8.65039 7.5 8.08789 9.3125 8.40039C9.3125 9.65039 9.25 10.8379 9.25 12.0879C8.4375 11.8379 7.4375 11.9004 6.75 12.4004C6.25 12.7129 5.8125 13.2754 5.625 13.8379C5.4375 14.2754 5.5 14.7129 5.5 15.1504C5.6875 16.5254 7 17.6504 8.4375 17.5254C9.375 17.5254 10.25 16.9629 10.75 16.2129C10.9375 15.9629 11.0625 15.6504 11.0625 15.3379C11.125 13.8379 11.125 12.3379 11.125 10.9004C11.1875 7.52539 11.1875 4.21289 11.1875 0.837891Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2222_7811">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.75 0.837891)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Tiktok)
