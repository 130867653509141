import styled, { css } from 'styled-components/macro'
import { Button as MaterialButton } from '@material-ui/core'
import type { Props } from './types'
import CircularProgress from '@material-ui/core/CircularProgress'

export const Button = styled(MaterialButton)<{
  $hasIcon?: boolean
  $variant: Props['variant']
  $isDanger?: boolean
  $changeStartIconHoverColor?: boolean
  $actived?: boolean
}>`
  && {
    font-size: 14px;
    border-radius: 4px;

    .MuiButton-label {
      font-weight: 600;
    }

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.colours.gray[65]};
      color: ${({ theme }) => theme.colours.gray[55]};
    }

    &:hover:not(.Mui-disabled) {
      color: ${({ theme }) => theme.colors.white};
      box-shadow: none;
    }

    ${({ $round }) => {
      return $round
        ? css`
            min-width: 40px;
            border-radius: 30px;
            padding: 0 10px;
            svg {
              vertical-align: middle;
            }
          `
        : null
    }}

    ${({ theme, $color, $variant, $changeStartIconHoverColor }) => {
      const colors = {
        primary: {
          contained: {
            color: theme.colours.mermaid[30],
            hover: theme.colours.mermaid[40],
          },
          outlined: {
            color: theme.colours.mermaid[30],
            hover: theme.colours.mermaid[30] + '0A',
          },
          text: {
            color: theme.colours.mermaid[30],
            hover: theme.colours.mermaid[30] + '0A',
          },
        },
        secondary: {
          contained: {
            color: theme.colours.gray[5],
            hover: theme.colours.gray[130],
          },
          outlined: {
            color: theme.colours.gray[55],
            hover: theme.colours.gray[55] + '0A',
          },
          text: {
            color: theme.colours.gray[5],
            hover: theme.colours.gray[5] + '0A',
          },
          'text-with-hover-color': {
            color: theme.colours.gray[5],
            hover: theme.colours.mermaid[30],
          },
        },
        info: {
          contained: {
            color: theme.colours.blue[30],
            hover: theme.colours.blue[40],
          },
          outlined: {
            color: theme.colours.blue[30],
            hover: theme.colours.blue[30] + '0A',
          },
          text: {
            color: theme.colours.blue[30],
            hover: theme.colours.blue[30] + '0A',
          },
        },
        error: {
          contained: {
            color: theme.colours.red[30],
            hover: theme.colours.red[20],
          },
          outlined: {
            color: theme.colours.red[30],
            hover: theme.colours.red[10] + '0A',
          },
          text: {
            color: theme.colours.red[30],
            hover: theme.colours.red[10] + '0A',
          },
        },
      }

      switch ($variant) {
        case 'contained':
          return css`
            background-color: ${colors[$color][$variant].color};
            color: ${theme.colors.white};

            &:hover:not(.Mui-disabled) {
              background-color: ${colors[$color][$variant].hover};

              .MuiButton-startIcon {
                svg {
                  color: ${$changeStartIconHoverColor
                    ? colors[$color][$variant].color
                    : null};
                }
              }
            }

            ${({ $actived }) =>
              $actived
                ? css`
                    background-color: ${colors[$color][$variant].hover};
                  `
                : null}
          `

        case 'outlined':
          return css`
            background-color: transparent;
            border: 1px solid ${colors[$color][$variant].color};
            color: ${colors[$color][$variant].color};
            box-shadow: none;

            &:hover:not(.Mui-disabled) {
              background-color: ${colors[$color][$variant].hover};
              border: 1px solid ${colors[$color][$variant].color};
              color: ${colors[$color][$variant].color};
              box-shadow: none;

              .MuiButton-startIcon {
                svg {
                  color: ${$changeStartIconHoverColor
                    ? colors[$color][$variant].color
                    : null};
                }
              }
            }
          `

        case 'text':
          return css`
            background-color: transparent;
            color: ${colors[$color][$variant].color};
            border: none;
            box-shadow: none;

            &.Mui-disabled {
              background-color: transparent;
              color: ${theme.colours.black}12;
            }

            &:hover:not(.Mui-disabled) {
              background-color: ${colors[$color][$variant].hover};
              color: ${colors[$color][$variant].color};
              border: none;
              box-shadow: none;

              .MuiButton-startIcon {
                svg {
                  color: ${$changeStartIconHoverColor
                    ? colors[$color][$variant].color
                    : null};
                }
              }
            }
          `

        case 'text-with-hover-color':
          return css`
            background-color: transparent;
            color: ${colors[$color][$variant].color};
            border: none;
            box-shadow: none;

            &.Mui-disabled {
              background-color: transparent;
              color: ${theme.colours.black}12;
            }

            &:hover:not(.Mui-disabled) {
              background-color: transparent;
              color: ${colors[$color][$variant].hover};
              border: none;
              box-shadow: none;

              .MuiButton-startIcon {
                svg {
                  color: ${$changeStartIconHoverColor
                    ? colors[$color][$variant].hover
                    : null};
                }
              }
            }
          `
      }
    }}
  }

  ${({ _, $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
        `

      case 'medium':
        return css`
          height: 40px;
        `

      case 'large':
        return css`
          height: 48px;
        `
    }
  }}
`

export const Spinner = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.colours.gray[55]};
    width: 15px !important;
    height: 15px !important;
  }
`
