import { set } from 'lodash/fp'

import { UPDATE_AVAILABLE } from '../actions/updateActions'

const initialState = {
  update: false,
}

export default function updateReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AVAILABLE:
      return set('update', true, state)

    default:
      return state
  }
}
