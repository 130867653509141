import styled from 'styled-components'
import {
  InputLabel as DefaultInputLabel,
  TextareaAutosize,
} from '@material-ui/core'

export const TextArea = styled(TextareaAutosize)`
  &&& {
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.colours.gray[75]};
    color: ${({ theme }) => theme.colours.gray[50]};
    outline: none;
    padding: 13px 16px;
    resize: none;
    width: 100%;

    &::placeholder {
      color: ${({ theme }) => theme.colours.gray[130]};
      font-size: 14px;
      font-weight: 400;
    }
  }
`

export const LabelContent = styled.div`
  align-items: center;
  display: flex;
`

export const Optional = styled.span`
  color: ${({ theme }) => theme.colours.gray[130]};
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 4px;
`

export const Label = styled(DefaultInputLabel)`
  && {
    color: ${({ theme }) => theme.colours.gray[50]};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 4px;
  }
`
