import { FC, memo } from 'react'
import type { Props } from '../../types'

const ArrowBack: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M6.42465 1.92465C6.65897 1.69034 6.65897 1.31044 6.42465 1.07613C6.19034 0.841812 5.81044 0.841812 5.57613 1.07613L1.07629 5.57597C0.959128 5.69313 0.900391 5.84684 0.900391 6.00039C0.900391 6.08174 0.916582 6.15932 0.945918 6.23006C0.975196 6.30082 1.0186 6.36712 1.07612 6.42465C1.07606 6.42459 1.07617 6.4247 1.07612 6.42465L5.57613 10.9247C5.81044 11.159 6.19034 11.159 6.42465 10.9247C6.65897 10.6903 6.65897 10.3104 6.42465 10.0761L2.94892 6.60039H12.5004C12.8318 6.60039 13.1004 6.33176 13.1004 6.00039C13.1004 5.66902 12.8318 5.40039 12.5004 5.40039H2.94892L6.42465 1.92465Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(ArrowBack)
