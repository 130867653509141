import { Colors } from 'sentisis-components'
/**
 * Returns an array of valid sources to iterate from / to be used as oneOf
 * props validation. The returned source are in the expected display order.
 */
export function getGlobalSources() {
  return [
    'twitter',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'media',
    'review',
    'gmb',
    'webzioReviews',
    'linkedin',
    'amazon',
  ]
}

// Color for charts
export const getChartSourceColor = source => {
  return (
    {
      twitter: '#70B7E8',
      facebook: '#0052A3',
      instagram: '#F1418D',
      youtube: '#C00000',
      tiktok: '#000000',
      media: '#FF9A00',
      linkedin: '#0288D1',
      all: '#4683DF',
      review: Colors.yellow_mustard,
    }[source] || '#ffff00'
  )
}

// Color for icons
export const getIconSourceColor = source => {
  return (
    {
      twitter: '#000000',
      facebook: '#0052A3',
      instagram: '#F1418D',
      youtube: '#C00000',
      tiktok: '#000000',
      media: '#FF9A00',
      linkedin: '#0288D1',
      all: '#4683DF',
      review: Colors.yellow_mustard,
    }[source] || '#ffff00'
  )
}

/**
 * Get the color for the passed source
 */
export function getSourceColor(source) {
  return (
    {
      twitter: '#000',
      facebook: '#3D5C99',
      instagram: '#00264D',
      youtube: '#BE0000',
      tiktok: '#333', // TODO: define TikTok color
      media: '#FF9900',
      linkedin: '#0288D1',
      all: '#4683DF',
      review: Colors.yellow_mustard,
    }[source] || '#ffff00'
  ) // bright red as default so the problem is noticeable
}

/**
 * Returns the source name
 */
export function getSourceName(source, t) {
  return {
    twitter: 'Twitter',
    facebook: 'Facebook',
    youtube: 'YouTube',
    tiktok: 'TikTok',
    instagram: 'Instagram',
    media: t('sources:Media', 'Medios'),
    all: t('sources:all:sources', 'Todas las fuentes'),
    review: t('sources:reviews', 'Reseñas'),
    webzioReviews: t('sources:otherPlatforms', 'Otras plataformas'),
    linkedin: 'LinkedIn',
  }[source]
}
