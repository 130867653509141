import styled from 'styled-components/macro'
import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
  SubTitle as ModalSubTitle,
  Buttons as ModalButtons,
} from 'components/ModalV2/Modal'
import DefaultInput from 'components/Inputs/Input/Input'

export const Container = styled(DefaultModal)`
  ${ModalContent} {
    padding: 40px;
    max-width: 586px;
    width: 586px;
  }
`

export const Title = styled(ModalTitle)`
  font-size: 20px;
`

export const SubTitle = styled(ModalSubTitle)`
  color: ${({ theme }) => theme.colours.gray[130]};
  font-size: 16px;
`

export const Form = styled.div`
  margin-top: 16px;
`

export const Buttons = styled(ModalButtons)``

export const FormContainer = styled.div`
  margin-top: 16px;
`

export const Input = styled(DefaultInput)``
