import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import CreateReviewHeader from 'assets/img/create-review-header.svg'
import CreateReviewBenchmarkHeader from 'assets/img/create-review-benchmark-header.svg'
import Card from './Card/Card'
import useLogic from './logic'
import * as S from './styles'

const CreateReviewProductOptions = () => {
  const {
    isOpen,
    onCloseModal,
    handleCreateReview,
    handleCreateReviewBenchmark,
  } = useLogic()

  const { t } = useTranslation('projectNewListening')

  return (
    <S.Container open={isOpen} onClose={onCloseModal}>
      <S.Title>
        {t('reviewModal.title', '¿Qué reseñas quieres analizar?')}{' '}
        <S.CloseIcon name="close" size={14} onClick={onCloseModal} />
      </S.Title>
      <S.SubTitle>
        {t(
          'reviewModal.desc',
          'Dependiendo de la opción que elijas podrás visualizar distintos dashboards. No podrás cambiar la selección una vez que el producto ha sido creado.',
        )}
      </S.SubTitle>

      <S.CardsContainer>
        <S.CardContainer>
          <Card
            img={CreateReviewHeader}
            title={t('reviewModal.single.title', 'Mis reseñas')}
            desc={t(
              'reviewModal.single.desc',
              'Descubre cómo varía el sentimiento de tus consumidores, tu rating y qué categorías generan más reseñas negativas o positivas.',
            )}
            actionIcon="plus"
            action={t('reviewModal.new', 'Nueva escucha')}
            onClick={handleCreateReview}
          />
        </S.CardContainer>
        <S.CardContainer>
          <Card
            img={CreateReviewBenchmarkHeader}
            title={t('reviewModal.multiple.title', 'Reseñas del sector')}
            desc={t(
              'reviewModal.multiple.desc',
              'Analiza el rating del sector y descubre qué marcas reciben mejores o peores reseñas y acaparan más la conversación',
            )}
            actionIcon="plus"
            action={t('reviewModal.new', 'Nueva escucha')}
            onClick={handleCreateReviewBenchmark}
          />
        </S.CardContainer>
      </S.CardsContainer>
    </S.Container>
  )
}

export default memo(CreateReviewProductOptions)
