import { FC, memo } from 'react'
import type { Props } from '../types'

const Config: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07916 10.5265C6.45128 10.5265 5.13163 9.20683 5.13163 7.57895C5.13163 5.95107 6.45128 4.63142 8.07916 4.63142C9.70704 4.63142 11.0267 5.95107 11.0267 7.57895C11.0267 9.20683 9.70704 10.5265 8.07916 10.5265ZM14.6269 6.3393L14.0795 6.23824C13.1809 6.07318 12.7514 5.03649 13.2702 4.28361L13.5851 3.82548C14.3793 2.67341 12.9847 1.27881 11.8326 2.07296L11.3745 2.38792C10.6216 2.90669 9.58493 2.47719 9.41986 1.57861L9.31881 1.03206C9.06532 -0.344019 7.093 -0.344019 6.83951 1.03206L6.73845 1.57861C6.57339 2.47719 5.5367 2.90669 4.78382 2.38792L4.32569 2.07296C3.17362 1.27881 1.77902 2.67341 2.57317 3.82548L2.88813 4.28361C3.4069 5.03649 2.9774 6.07318 2.07882 6.23824L1.53143 6.3393C0.156191 6.59279 0.156191 8.56511 1.53143 8.8186L2.07882 8.91965C2.9774 9.08472 3.4069 10.1214 2.88813 10.8743L2.57317 11.3324C1.77902 12.4845 3.17362 13.8791 4.32569 13.0849L4.78466 12.77C5.5367 12.2512 6.57339 12.6807 6.73845 13.5793L6.83951 14.1258C7.093 15.5019 9.06532 15.5019 9.31881 14.1258L9.41986 13.5793C9.58493 12.6807 10.6216 12.2512 11.3737 12.77L11.8326 13.0849C12.9847 13.8791 14.3793 12.4845 13.5851 11.3324L13.2702 10.8743C12.7514 10.1214 13.1809 9.08472 14.0795 8.91965L14.6269 8.8186C16.0021 8.56511 16.0021 6.59279 14.6269 6.3393Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Config)
