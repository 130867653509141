import React from 'react'
import PropType from 'prop-types'
import { useTranslation } from 'react-i18next'
import { set } from 'lodash/fp'
import moment from 'moment'

import 'moment/locale/es'

const TranslationStateContext = React.createContext({
  currentLanguage: '',
  isTReady: false,
  localeList: [],
  loadNameSpaces: () => {},
  changeLanguage: lang => {},
  hasNameSpaceLoaded: () => {},
  setEntityPreferredLocale: () => {},
})
const TranslationDispatchContext = React.createContext()

const translationReducer = (state, action) => {
  const { payload } = action

  switch (action.type) {
    case 'set_language': {
      return set('currentLanguage', payload, state)
    }
    case 'set_translation_ready': {
      return set('isTReady', payload, state)
    }
    default:
      throw new Error(' opción incorrecta ')
  }
}

const TranslationServiceProvider = ({ children }) => {
  const { i18n, ready } = useTranslation()

  const [state, dispatch] = React.useReducer(translationReducer, {
    currentLanguage: '',
    isTReady: ready,
    localeList: [
      { id: 1, name: 'English', locale: 'en' },
      { id: 2, name: 'Spanish', locale: 'es' },
      { id: 3, name: 'Deutsch', locale: 'de' },
      { id: 4, name: 'Português', locale: 'pt' },
    ],
  })

  React.useEffect(() => {
    const lang =
      i18n && i18n.language && i18n.language.toLocaleLowerCase().slice(0.2)
    i18n.on('languageChanged', () => {
      moment.locale(lang)
      dispatch({ type: 'set_translation_ready', payload: false })
    })
    dispatch({
      type: 'set_language',
      payload: lang,
    })
  }, [i18n])

  const loadNameSpaces = ns => {
    dispatch({ type: 'set_translation_ready', payload: false })
    i18n.loadNamespaces(ns).then(() => {
      i18n.setDefaultNamespace(ns)
      dispatch({ type: 'set_translation_ready', payload: true })
    })
  }

  const changeLanguage = lang => {
    dispatch({ type: 'set_translation_ready', payload: false })
    return i18n.changeLanguage(lang).then(() => {
      dispatch({ type: 'set_translation_ready', payload: true })
      if (lang) {
        moment.locale(lang)
        dispatch({
          type: 'set_language',
          payload: lang.toLocaleLowerCase().slice(0, 2),
        })
      }
    })
  }

  const hasNameSpaceLoaded = ns => {
    return i18n.options.ns.indexOf(ns) > -1
  }

  const setEntityPreferredLocale = locale => {
    changeLanguage(locale)
  }
  const contextValues = {
    ...state,
    changeLanguage,
    loadNameSpaces,
    setEntityPreferredLocale,
    hasNameSpaceLoaded,
  }
  return (
    <TranslationStateContext.Provider value={{ ...contextValues }}>
      <TranslationDispatchContext.Provider value={dispatch}>
        {children}
      </TranslationDispatchContext.Provider>
    </TranslationStateContext.Provider>
  )
}
const useTranslationState = () => {
  const context = React.useContext(TranslationStateContext)
  if (context === undefined) {
    throw new Error(
      'useTranslationState must be used within a TranslationProvider',
    )
  }
  return context
}
const useTranslationDispatch = () => {
  const context = React.useContext(TranslationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useTranslationDispatch must be used within a TranslationProvider',
    )
  }
  return context
}
const useTranslationService = () => [
  useTranslationState(),
  useTranslationDispatch(),
]

export {
  useTranslationService,
  TranslationStateContext,
  TranslationServiceProvider,
  useTranslationState,
  useTranslationDispatch,
}

TranslationServiceProvider.propTypes = {
  children: PropType.node.isRequired,
}
