import { useCallback } from 'react'
import { Props } from './types'

const useLogic = ({ onClick }: Partial<Props>) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  return {
    handleOnClick,
  }
}

export default useLogic
