export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_REGION = 'SET_REGION'

export function setCountry(country) {
  return { type: SET_COUNTRY, payload: { country } }
}

export function setRegion(region) {
  return { type: SET_REGION, payload: { region } }
}
