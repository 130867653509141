export const TOPICS_SET = 'TOPICS_SET'
export const TOPICS_CLEAR = 'TOPICS_CLEAR'

/**
 * Actions
 */

export function setTopic({ filter, row }) {
  return {
    type: TOPICS_SET,
    payload: {
      filter,
      row,
    },
  }
}

export function clearTopic() {
  return { type: TOPICS_CLEAR, payload: {} }
}
