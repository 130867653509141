import { FeedMonitor } from 'apollo/generated/globalTypes'
import { FeedValues } from './FeedConfig/types'
import { TwitterValues } from './FeedConfig/Twitter/types'
import { FacebookValues } from './FeedConfig/Facebook/types'
import { InstagramValues } from './FeedConfig/Instagram/types'
import { YoutubeValues } from './FeedConfig/Youtube/types'
import { TiktokValues } from './FeedConfig/Tiktok/types'
import { MediaValues } from './FeedConfig/Media/types'
import { ReviewValues } from './FeedConfig/Review/types'

export type Props = {
  productId?: string
  isEdit?: boolean
}

export enum Steps {
  LISTENING_CONFIG = 'listening-config',
  BRAND_CONFIG = 'brand-config',
}

export enum ListeningContent {
  SOCIAL_NETWORKS,
  REVIEWS,
}

export type ProductConfig = {
  isMultiple: boolean
  hasLogo: boolean
  defaultNumberOfSources: number
  texts: Record<string, any>
  contentType: ListeningContent
}

export enum ErrorType {
  PROFILE_LIST = 'PROFILE_LIST',
  TAGS = 'TAGS',
  SIMPLE = 'SIMPLE',
}

export type SocialNetworkApiError = {
  type: ErrorType
  hasError: (error) => boolean
  i18n: string
  message: string
  formField: {
    front: {
      name: string
      fieldValue: string
    }
  }
}

export enum ProductType {
  DRIVE = 'DRIVE',
  EXPLORE = 'EXPLORE',
  MAPS = 'MAPS',
  RACE = 'RACE',
  REVIEW = 'REVIEW',
  REVIEW_BENCHMARK = 'REVIEW_BENCHMARK',
}

type SourceValues =
  | TwitterValues
  | FacebookValues
  | InstagramValues
  | YoutubeValues
  | TiktokValues
  | MediaValues
  | ReviewValues

type ApiErrorTreatmentFn = ({
  sourceError,
  sourceErrorParsed,
  error,
  source,
  sourceErrors,
  errorObject,
}: {
  sourceError: string
  sourceErrorParsed
  error: SocialNetworkApiError
  source: SourceValues
  sourceErrors: FeedMonitor
  errorObject: Partial<FeedValues>
}) => { hasError: boolean }

export type ApiErrorTreatment = Record<ErrorType, ApiErrorTreatmentFn>
