import { FC, memo } from 'react'
import type { Props } from '../../types'

const Youtube: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2647 13.8989V6.77269C20.2647 6.77269 20.2647 3.33789 16.8586 3.33789H3.9049C3.9049 3.33789 0.5 3.33789 0.5 6.77269V13.8989C0.5 13.8989 0.5 17.3337 3.9049 17.3337H16.8586C16.8586 17.3337 20.2647 17.3337 20.2647 13.8989ZM14.5 10.3364L7.91177 14.0406V6.63103L14.5 10.3364Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Youtube)
