import { isNil } from 'lodash/fp'

/**
 * Sort array Comparing object keys
 *
 * @param arr Values to sort
 * @param key Object key that used to compare
 * @param order Return order, ASC or DESC defaults to ASC
 * @returns Ordered array
 */
export const sortStringsValues = (
  arr: any[],
  key: string,
  order: 'ASC' | 'DESC' = 'ASC',
) => {
  return arr.sort((a, b) => {
    const valA = a[key]
    const valB = b[key]
    if (isNil(valA) || isNil(valB)) {
      return 0
    }

    if (order === 'ASC') {
      return valA.localeCompare(valB)
    }
    return valB.localeCompare(valA)
  })
}

export const getRandomId = (chars: number = 4) => {
  return Math.random().toString(36).substr(2, chars)
}
