import Raven from 'raven-js'

/**
 * Gracfully handle the errors and report them
 * to sentry
 *
 * @prop {Error}  err     - Error to report
 * @prop {Object} context - Additional informations
 */
export default function logError(err, context) {
  Raven.captureException(err, {
    extra: context,
  })

  /* eslint-disable no-console */
  console.error(err)
  // console.log(`Error context: ${JSON.stringify(context, null, 2)}`)
  /* eslint-enable no-console */
}
