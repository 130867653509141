import RavenMiddleware from 'redux-raven-middleware'
import thunk from 'redux-thunk'

import { createTransform, persistStore, persistReducer } from 'redux-persist'
import { createStore, applyMiddleware, compose } from 'redux'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'

const moment = extendMoment(Moment)
// Adds transform to manipulate date strings in store
const isDateString = value =>
  typeof value === 'string' &&
  value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)

const handleDateStrings =
  // Rehydrates date string values to moment objects.
  createTransform(JSON.stringify, toRehydrate =>
    JSON.parse(toRehydrate, (key, value) => {
      return isDateString(value) ? moment(new Date(value)) : value
    }),
  )

// key version will regenerate cached objects in local storage
// redux + translations. Use i18n.js, to update translations only.
const persistConfig = {
  key: 'rootv2.583',
  storage,
  whitelist: [
    'globalFilters',
    'filters',
    'sessionData',
    'appConfiguration',
    'magicSearch',
  ],
  transforms: [handleDateStrings],
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const SENTRY_DNS =
  'https://5cb8ab4ff44242c3a56ec9a0e888b0df@app.getsentry.com/64804'

export const getStore = preloadedState => {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(
      applyMiddleware(RavenMiddleware(SENTRY_DNS), thunk),

      // Enable the Redux Devtools, make sure to download them: for awesome debugging
      // https://github.com/zalmoxisus/redux-devtools-extension

      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
        : f => f,
    ),
  )
  const persistor = persistStore(store)
  return { store, persistor }
}
export default function configureStore(preloadedState) {
  return getStore(preloadedState)
}
