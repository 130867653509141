import styled from 'styled-components'

export const Container = styled.div<{ $disabled: boolean }>`
  padding: 24px 32px;
  border: 1px solid ${({ theme }) => theme.colours.gray[170]};
  border-radius: 4px;

  :hover {
    cursor: ${({ $disabled }) => ($disabled ? '' : 'pointer')};
    background-color: ${({ theme, $disabled }) =>
      $disabled ? '' : theme.colours.gray[230]};
  }
`

export const Img = styled.img`
  width: 100%;
`

export const Content = styled.div`
  padding-top: 24px;
  text-align: center;
`

export const Title = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const Desc = styled.div`
  padding-bottom: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const Action = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: ${({ theme }) => theme.colours.blue[50]};
`

export const ActionText = styled.span`
  display: inline-block;
  margin-left: 8px;
`
