import styled from 'styled-components/macro'
import DefaultModal, {
  Content as ModalContent,
  Title as ModalTitle,
  SubTitle as ModalSubTitle,
} from 'components/ModalV2/Modal'
import Icon from 'components/IconV2'

export const Container = styled(DefaultModal)`
  .MuiPaper-root {
    max-width: 800px;
    width: 800px;
  }
  ${ModalContent} {
    padding: 32px;
    max-width: 800px;
    width: 800px;
  }
`

export const Title = styled(ModalTitle)`
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SubTitle = styled(ModalSubTitle)`
  color: ${({ theme }) => theme.colours.gray[130]};
  font-size: 16px;
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 24px;
  padding-top: 24px;
`

export const CardContainer = styled.div`
  flex-basis: calc(100% - 12px);
`

export const CloseIcon = styled(Icon)`
  color: ${({ theme }) => theme.colours.black};
  opacity: 0.56;
  cursor: pointer;
`
