const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

/* Component definition */
const ImportRetry = async (importFn, retries = 5, interval = 3000) => {
  try {
    return await importFn()
  } catch (err) {
    if (retries) {
      await wait(interval)
      return ImportRetry(importFn, retries - 1, interval)
    } else {
      throw new Error(err)
    }
  }
}

export default ImportRetry
