import { FC, memo } from 'react'
import MUITooltip from '@material-ui/core/Tooltip'
import { Props } from './types'
import * as S from './styles'

const Tooltip: FC<Props> = ({
  title,
  placement = 'bottom',
  children,
  className = '',
}) => {
  return (
    <MUITooltip title={title} placement={placement} className={className}>
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </MUITooltip>
  )
}

export default memo(Tooltip)
