import { set } from 'lodash/fp'

import {
  SET_ACCOUNT_EVOLUTON_TYPE,
  CLEAR_ACCOUNT_EVOLUTION_TYPE,
} from '../actions/accountsActions'

const initialState = {
  evolutionType: 'followers',
}

export default function accounts(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_ACCOUNT_EVOLUTON_TYPE:
      return set('evolutionType', payload.type, state)

    case CLEAR_ACCOUNT_EVOLUTION_TYPE:
      return set('evolutionType', 'followers', state)

    default:
      return state
  }
}
