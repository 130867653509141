import { FC, memo } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import type { Props } from './types'
import * as S from './styles'

const TextAreaController: FC<Props> = ({
  control,
  hasError,
  name,
  label,
  isOptional = false,
  ...rest
}) => {
  const { t } = useTranslation('input')

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <S.LabelContent>
            <S.Label htmlFor={name} $error={error}>
              {label}
            </S.Label>
            {isOptional && <S.Optional>{t('optional', 'Opcional')}</S.Optional>}
          </S.LabelContent>

          <S.TextArea id={name} {...rest} {...field} />
          {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
        </>
      )}
    />
  )
}

export default memo(TextAreaController)
