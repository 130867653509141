import PropTypes from 'prop-types'

type CustomRuleCondition = {
  __typename?: string
  type: string
  operation: string
  categories: string[]
}

type CustomRuleResult = {
  __typename?: string
  type: string
  action: string
  sentiment?: string
  categories?: [string] | null
}

export type CustomRule = {
  id: string
  name: string
  description?: string
  conditions: CustomRuleCondition[]
  results: CustomRuleResult[]
  __typename?: string
}

// Shapes
const customRuleConditionShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
})

const customRuleResultShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  sentiment: PropTypes.string,
})

export const customRuleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  conditions: PropTypes.arrayOf(customRuleConditionShape).isRequired,
  results: PropTypes.arrayOf(customRuleResultShape).isRequired,
})
