import { set, getOr } from 'lodash/fp'

import {
  INIT_ENTITY,
  REMOVE_ENTITY,
  REQ_ENTITY,
  RES_ENTITY,
} from '../actions/entitiesActions'

const initialEntityState = {
  isFetching: false,
  error: null,
  response: null,
  version: 0,
}

export default function entities(state = {}, action) {
  const { type, payload, error, meta } = action

  switch (type) {
    case INIT_ENTITY:
      return set([payload.eventName, payload.id], initialEntityState, state)

    case REMOVE_ENTITY:
      return set([payload.eventName, payload.id], undefined, state)

    case REQ_ENTITY: {
      const path = [payload.eventName, payload.id]
      const entity = getOr(initialEntityState, path, state)

      return set(
        path,
        {
          ...entity,
          isFetching: true,
          error: null,
        },
        state,
      )
    }

    case RES_ENTITY: {
      const path = [meta.eventName, meta.id]
      const entity = getOr(initialEntityState, path, state)

      if (error) return set(path, { ...entity, error }, state)

      return set(
        path,
        {
          isFetching: false,
          response: payload,
          error: null,
          version: entity.version + 1,
        },
        state,
      )
    }

    default:
      return state
  }
}
