export const SET_ACCOUNT_MENTIONS_SORT = 'SET_ACCOUNT_MENTIONS_SORT'
export const SET_ACCOUNT_MENTIONS_ORDER = 'SET_ACCOUNT_MENTIONS_ORDER'
export const SET_ACCOUNT_MENTIONS_DEFAULT = 'SET_ACCOUNT_MENTIONS_DEFAULT'
export const SET_ACCOUNT_MENTIONS_PAGE = 'SET_ACCOUNT_MENTIONS_PAGE'
export const SET_ACCOUNT_MENTIONS_TOTAL = 'SET_ACCOUNT_MENTIONS_TOTAL'

export function setOrder(order) {
  return { type: SET_ACCOUNT_MENTIONS_ORDER, payload: { order } }
}

export function setSort(sort) {
  return { type: SET_ACCOUNT_MENTIONS_SORT, payload: { sort } }
}

export function setDefault() {
  return { type: SET_ACCOUNT_MENTIONS_DEFAULT, payload: {} }
}

export function setTotal(total) {
  return { type: SET_ACCOUNT_MENTIONS_TOTAL, payload: { total } }
}

export function setPage(page) {
  return { type: SET_ACCOUNT_MENTIONS_PAGE, payload: { page } }
}
