import { uniqueId } from 'lodash/fp'

export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

function showToast(options, toastId, timeoutId) {
  return {
    type: SHOW_TOAST,
    payload: options,
    meta: { toastId, timeoutId },
  }
}

function hideToast(toastId) {
  return {
    type: HIDE_TOAST,
    payload: true,
    meta: { toastId },
  }
}

export function createToast(label, opt = {}) {
  // Assign defaults to the otpions
  const options = Object.assign(
    {},
    {
      label,
      delay: 5000,
    },
    opt,
  )

  // Used so the timeout event only closes the related toast and not a possibly newly generated one
  const toastId = uniqueId('toast_')

  return (dispatch, getState) => {
    const { toast } = getState()

    if (toast.timeoutId) window.clearTimeout(toast.timeoutId)

    const timeoutId = window.setTimeout(
      () => dispatch(hideToast(toastId)),
      options.delay,
    )

    return dispatch(showToast(options, toastId, timeoutId))
  }
}

export function closeToast() {
  return (dispatch, getState) => {
    const { toast } = getState()

    if (toast.timeoutId) window.clearTimeout(toast.timeoutId)

    dispatch(hideToast())
  }
}
