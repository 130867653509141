import { appConfigurationActions } from '../actions/appConfigurationActions'
import { createReducer } from '@reduxjs/toolkit'
import { set } from 'lodash/fp'
import { View } from 'models/View'

export interface IAppConfiguration {
  view: View
  sentimentRounding: boolean
}

const initialState: IAppConfiguration = {
  view: null,
  sentimentRounding: false,
}

const clearStateKey = (key: string) => (state: IAppConfiguration) =>
  set(key, null, state) as unknown as IAppConfiguration

const {
  setCurrentView,
  storeViewFilters,
  clearViewFilters,
  updateCurrentView,
  setSentimentRounding,
} = appConfigurationActions

const appConfigurationReducer = createReducer(initialState, builder => {
  builder
    .addCase(setCurrentView, (state, action) => {
      const view = action.payload

      return set('view', view, state)
    })
    .addCase(updateCurrentView, (state, action) => {
      const updates = action.payload

      return set('view.filters', updates, state)
    })
    .addCase(storeViewFilters, (state, action) =>
      set('view.filters', action.payload, state),
    )
    .addCase(setSentimentRounding, (state, action) => {
      return set('sentimentRounding', action.payload, state)
    })
    .addCase(clearViewFilters, clearStateKey('view.filters'))
    .addDefaultCase(state => state)
})

export default appConfigurationReducer
