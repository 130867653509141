import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { SHARE_MENTION } from 'apollo/mutations/mention'
import type { SendMentionEmailsInput } from 'apollo/generated/globalTypes'
import type {
  ShareMentionByEmail,
  ShareMentionByEmailVariables,
} from 'apollo/generated/ShareMentionByEmail'

const useMentionActions = () => {
  const [shareMentionMutation, { loading: shareMentionIsLoading }] =
    useMutation<ShareMentionByEmail, ShareMentionByEmailVariables>(
      SHARE_MENTION,
    )
  const shareMentionByEmail = useCallback(
    async ({ emails, comments, mention }: SendMentionEmailsInput) => {
      let username = mention.username
      let name = mention.name

      if (!username && name) {
        username = name
      }

      if (!name && username) {
        name = username
      }

      await shareMentionMutation({
        variables: {
          input: {
            emails,
            comments,
            mention: { ...mention, username, name },
          },
        },
      })
    },
    [shareMentionMutation],
  )

  return {
    shareMentionByEmail,
    mentionActionsLoading: shareMentionIsLoading,
  }
}

export default useMentionActions
