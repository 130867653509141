import React from 'react'
import classNames from 'classnames'
import { isString } from 'lodash/fp'
import Icon from 'components/Icon/Icon'

import './Toast.css'

type ToastPropTypes = {
  label: string | React.ReactNode
  isWarning?: boolean
  isDanger?: boolean
  onClickClose?: React.MouseEventHandler
}
export default function Toast({
  isWarning = false,
  isDanger = false,
  ...delegated
}: ToastPropTypes) {
  const { label, onClickClose } = delegated

  const className = classNames('c-Toast', {
    'c-Toast--is-visible': label,
    'c-Toast--is-error': isString(label) ? label.includes('error') : null,
    'c-Toast--is-warning': isWarning,
    'c-Toast--is-danger': isDanger,
  })

  return (
    <div className={className}>
      <span className="c-Toast__label">{label}</span>
      <button
        title="button"
        type="button"
        className="c-Toast__close"
        onClick={onClickClose}
      >
        <Icon name="close" />
      </button>
    </div>
  )
}
