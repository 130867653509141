import { FC, memo } from 'react'
import type { Props } from '../../types'

const Summary: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0632 0.890869C12.4702 0.890869 12.8 1.2086 12.8 1.60055V14.1812C12.8 14.5731 12.4702 14.8909 12.0632 14.8909C11.6563 14.8909 11.3264 14.5731 11.3264 14.1812V1.60055C11.3264 1.2086 11.6563 0.890869 12.0632 0.890869ZM6.80005 5.36092C7.207 5.36092 7.53689 5.67865 7.53689 6.07059V14.1812C7.53689 14.5731 7.207 14.8909 6.80005 14.8909C6.3931 14.8909 6.06321 14.5731 6.06321 14.1812V6.07059C6.06321 5.67865 6.3931 5.36092 6.80005 5.36092ZM1.53689 9.41621C1.94384 9.41621 2.27373 9.73395 2.27373 10.1259V14.1812C2.27373 14.5731 1.94384 14.8909 1.53689 14.8909C1.12994 14.8909 0.800049 14.5731 0.800049 14.1812V10.1259C0.800049 9.73395 1.12994 9.41621 1.53689 9.41621Z"
      fill="currentColor"
    />
  </svg>
)

export default memo(Summary)
