import { gql } from '@apollo/client'

export const PROJECT_FRAGMENT = gql`
  fragment ProjectData on Project {
    id
    slug
    name
    logoUrl
    headerLogoUrl
    availableMonitors
    networks {
      fb {
        ex
        tk
        tk_error
      }
      instagram {
        id
        ex
        p
        sn
        tk
        tk_error
      }
      tw {
        atk
        ats
        id
        sn
      }
      yt {
        tk
      }
      gmb {
        tk
        rtk
        ex
      }
      bw {
        tk
        ex
      }
      tiktok {
        id
        tk
        sn
      }
    }
  }
`

export const BRAND_FRAGMENT = gql`
  fragment BrandData on Brand {
    id
    slug
    name
    logoUrl
    headerLogoUrl
    availableMonitors
    networks {
      fb {
        ex
        tk
        tk_error
      }
      instagram {
        id
        ex
        p
        sn
        tk
        tk_error
      }
      tw {
        atk
        ats
        id
        sn
      }
      yt {
        tk
      }
      gmb {
        tk
        rtk
        ex
      }
      bw {
        tk
        ex
      }
      tiktok {
        id
        tk
        sn
      }
    }
  }
`
