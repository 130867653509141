import { FC, memo } from 'react'
import type { Props } from '../types'

const Trash: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <g
      id="Mentions-v2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Dropdown-acciones" transform="translate(-12.000000, -81.000000)">
        <g id="dropdown-Acción-" transform="translate(0.500000, 0.500000)">
          <path
            d="M0,1.99165296 C0,0.891693403 0.890874643,0 2.00221793,0 L152.997782,0 C154.103576,0 155,0.887626648 155,1.99165296 L155,137.008347 C155,138.108307 154.109125,139 152.997782,139 L2.00221793,139 C0.896423503,139 0,138.112373 0,137.008347 L0,1.99165296 Z"
            id="all-border-grey"
            stroke="#E6E8EC"
          ></path>
          <path
            d="M0.5,34 L154.5,34 L154.5,137.499238 C154.5,138.051943 154.058228,138.5 153.496329,138.5 L1.50367082,138.5 C0.949358733,138.5 0.5,138.056802 0.5,137.499238 L0.5,34 Z"
            id="drop-backgrown-blanco"
            fill="none"
          ></path>
          <g
            id="icon/trash/active"
            transform="translate(12.000000, 81.000000)"
            fill="currentColor"
            fillRule="nonzero"
          >
            <g id="trash">
              <path
                d="M2.46558038,1 C2.46558038,0.672086162 2.72730873,0.432432432 3.06267515,0.432432432 L5.51724219,0.432432432 C5.85250068,0.432432432 6.11422903,0.672086162 6.11422903,1 L6.11422903,1.62162162 L6.55648947,1.62162162 L6.55648947,1 C6.55648947,0.433699324 6.09641337,0 5.51724219,0 L3.06267515,0 C2.483504,0 2.02331994,0.433699324 2.02331994,1 L2.02331994,1.62162162 L2.46558038,1.62162162 L2.46558038,1 Z"
                id="Path"
              />
              <path
                d="M1.6751046,11 L6.90481274,11 C7.40905013,11 7.80034696,10.5663007 7.80034696,10.027027 L7.80034696,3.35135135 L0.779462442,3.35135135 L0.779462442,10.027027 C0.779462442,10.5663007 1.17075928,11 1.6751046,11 Z M5.69960986,4.38376268 C5.69960986,4.26435811 5.79862178,4.16754646 5.92074008,4.16754646 C6.04285839,4.16754646 6.1418703,4.26435811 6.1418703,4.38376268 L6.1418703,9.49187078 C6.1418703,9.61127535 6.04285839,9.708087 5.92074008,9.708087 C5.79862178,9.708087 5.69960986,9.61127535 5.69960986,9.49187078 L5.69960986,4.38376268 Z M4.06877448,4.38376268 C4.06877448,4.26435811 4.16778639,4.16754646 4.2899047,4.16754646 C4.41202301,4.16754646 4.51103492,4.26435811 4.51103492,4.38376268 L4.51103492,9.49187078 C4.51103492,9.61127535 4.41202301,9.708087 4.2899047,9.708087 C4.16778639,9.708087 4.06877448,9.61127535 4.06877448,9.49187078 L4.06877448,4.38376268 Z M2.4379391,4.38376268 C2.4379391,4.26435811 2.53695101,4.16754646 2.65906932,4.16754646 C2.78118763,4.16754646 2.88019954,4.26435811 2.88019954,4.38376268 L2.88019954,9.49187078 C2.88019954,9.61127535 2.78118763,9.708087 2.65906932,9.708087 C2.53695101,9.708087 2.4379391,9.61127535 2.4379391,9.49187078 L2.4379391,4.38376268 Z"
                id="Shape"
              />
              <path
                d="M0.552825553,2.91891892 L8.02698385,2.91891892 C8.33233356,2.91891892 8.5798094,2.67694257 8.5798094,2.37837838 C8.5798094,2.07981419 8.33233356,1.83783784 8.02698385,1.83783784 L0.552825553,1.83783784 C0.247475814,1.83783784 0,2.07981419 0,2.37837838 C0,2.67694257 0.247475814,2.91891892 0.552825553,2.91891892 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default memo(Trash)
