import {
  includes,
  isObject,
  mapValues,
  startsWith,
  flow,
  pickBy,
  deburr,
  kebabCase,
} from 'lodash/fp'

// A tilda is not reserved so it still shows as a tilda, making the url
// human-readeable
const URL_ARRAY_SEPARATOR = '~'

const TILDA_URI = '!tilda'
/**
 * Composes an absolute path by joining together the passed path segments
 *
 * @param {string} ...paths Paths to join
 * @return {string} Absolute path
 */
export function composePath(...paths) {
  if (!paths.length) return '/'

  let head = paths.shift()

  // Decide if we need to prepend a slash to the path
  const absolutePathReg = /(^https?:\/{2})|^\//
  if (!absolutePathReg.test(head)) {
    head = `/${head}`
  }

  // Ensure the head doesn't have a tailing slash
  head = head.replace(/\/$/, '')

  // Compose the path by ensuring each segment ensuring they don't have tailing or leading
  // slashes
  return paths.reduce((path, segment) => {
    if (!segment) return path

    const cleanSegment = segment.replace(/^\/?(.+)\/?$/, '$1')
    return `${path}/${cleanSegment}`
  }, head)
}

/**
 * Returns the url absolute to the API
 */
export function getAPIUrl(uri) {
  const nodeEnv = process.env.REACT_APP_ENV
  const apiEnv = process.env.REACT_APP_API_ENV
  let api

  if (apiEnv === 'production' || nodeEnv === 'production') {
    api = 'https://api.sentisis.io'
  } else if (apiEnv === 'qa') {
    api = 'https://qa-api.sentisis.io'
  } else {
    api = 'http://localhost:3000'
  }

  return composePath(api, uri)
}

/**
 * Generate a slug form a string
 *
 * @param {string} str - Value to transform into a slug
 * @return {string} Slug
 */
export const generateSlug = flow(deburr, kebabCase)

/**
 * Encode an object with a Google-inspired quety string:
 *
 *     <key>:<value>,<key>:<valiue>,...
 *
 * @param {Object} objj   - The object to encode
 * @return {string} Encoded URL-friemdly string
 */
export function encodeQueryObject(obj) {
  const encodeValue = val => {
    if (!val) return val

    if (typeof val === 'boolean') return String(val)

    if (Array.isArray(val)) {
      const escapedVal = val.map(s => s.replace('~', TILDA_URI))
      return escapedVal.join(URL_ARRAY_SEPARATOR)
    }

    if (isObject(val)) return `!json${JSON.stringify(val)}`

    return val.replace('~', TILDA_URI)
  }

  const parse = flow(mapValues(encodeValue), pickBy(Boolean))

  return parse(obj)
}

/**
 * Parse a query object that was encoded with `encodeQueryObject`
 */
export function parseQueryObject(str) {
  const decodeValue = val => {
    if (!val) return undefined

    const isJSON = startsWith('!json')
    const isArrayString = includes(URL_ARRAY_SEPARATOR)

    if (val === 'true') return true
    if (val === 'false') return false

    if (isJSON(val)) return JSON.parse(val.replace('!json', ''))

    if (isArrayString(val)) {
      return val.split(URL_ARRAY_SEPARATOR).map(s => s.replace(TILDA_URI, '~'))
    }

    return val.replace(TILDA_URI, '~')
  }

  const parse = flow(mapValues(decodeValue), pickBy(Boolean))

  return parse(str)
}
